ul.tabs {
	border: 0;
	display: table;
	margin: 20px auto 0;

	@include breakpoint($tab) {
		margin: 20px auto;
	}

	li {
		display: inline-block;
		float: none;
		width: 100%;
		position: relative;
		text-align: center;

		@include breakpoint($tab) {
			width: auto;
			margin: 0 5px;
		}

		a {
			border: 0;
			color: $black;
			display: inline-block;
			background: none;
			padding: 10px;
			position: relative;
			transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
			background: #fff;
			text-decoration: none;

			@include breakpoint($tab) {
				border: 1px solid $black;
			  padding: 10px 20px;
			}

			&:hover {
				@include breakpoint($tab) {
					font-style: normal;
					color: #fff;
					background: $black;
					// border: 1px solid $blue;
				}

				&:before {
					@include breakpoint($tab) {
						bottom: 0%;
						top: auto;
						height: 100%;
					}
				}
			}
		}

		&.active a {
			background: #fff;
			color: $main;

			@include breakpoint($tab) {
				background: $black;
				color: #fff;
				font-weight: normal;
			}
		}

		&.active:before {
			display: block;
			position: absolute;
			left: 0px;
			top: 50%;
			height: 1px;
			width: 100%;
			z-index: -1;
			content: '';
			background: $main;
			transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;

			@include breakpoint($tab) {
				display: none;
			}
		}
	}
}

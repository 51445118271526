table {
  width: 100%;
  border: 0;
  margin: 40px 0 0;
  border: 1px solid $border;

  tr.even, tr.odd {
    background: transparent;
  }

  th, td {
    height: 40px;
    border: 0;
    padding: 15px 0 15px 20px;
  }

  th {
    font-size: 16px;
    border: 0;
    color: $black;
    font-weight: 400;
    background: transparent;
    border-bottom: 1px solid $border;
    padding: 10px;
    text-align: center;
    border-left: 1px solid $border;
  }

  td {
    vertical-align: middle;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid $border;
    border-left: 1px solid $border;
    padding: 10px;

    a {
      font-size: 16px;
      color: $blue;

      &:hover {
        color: $black;
      }
    }
  }


  // tbody {
  //   tr:nth-child(2n+1) {
  //     background: $background;
  //   }
  // }
}

.inverse-table-simple {
  th, td {
    background: #fff;
    border: 0;
    border-bottom: 1px solid $border;
    color: #333333;
    font-size: 16px;
  }

  th {
    font-weight: 400;
  }

  td {
    font-weight: 400;
  }
}



.inverse-table {

  th {
    padding: 10px;
    background: $background;
    color: $blue;
    border-right: 2px solid $black;
    border-bottom: 0;
  }

  td {
    padding: 10px;
  }

  tr:nth-child(2n) th {
    background: #eaeaea;
  }

  tbody {
    tr:nth-child(2n) {
      background: $background;
    }
    tr:nth-child(2n+1) {
      background: #fff;
    }
  }
}


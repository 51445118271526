.product-teaser {
  text-align: center;
  padding: 0 10px;
  position: relative;
  .product-teaser-title-wrapper {
    height: 40px;
    margin: 20px 0 10px;
    color: #414141;
    overflow: hidden;
    position: relative;
    h2 {
      font-weight: 400;
      margin: 0;
    }
    a {
      font-size: 14px;
      line-height: 20px;
      color: #414141;
      display: inline-block;
    }
  }
  .styleno {
    font-size: 11px;
    color: #ccc;
    margin-bottom: 10px;
  }
  .prices-wrapper {
    line-height: 21px;
    font-size: 13px;
    @include breakpoint($tab) {
      font-size: 15px;
      min-height: 50px;
    }
    .profit-price {
      color: #00b300;
    }
    .final-price,
    .initial-price {
      display: inline-block; // margin: 0 4px;
      margin: 0 0 7px;
      color: $main;
      font-weight: 400;
      font-size: 16px;
    }
    .initial-price {
      @extend .thin-font;
      color: #828282;
      text-decoration-line: strikethrough;
      position: relative;
      margin-right: 10px;
      font-size: 14px;
      &:after {
        content: "";
        width: auto;
        border-top: 1px solid #888;
        position: absolute;
        top: 50%;
        right: 5px;
        left: 5px;
        padding: 0 3px;
        padding-left: 0;
      }
    }
  }
  .product-actions {
    @include pie-clearfix;
    .view-details {
      margin: 10px auto;
      display: block;
      padding: 0 4px;
      letter-spacing: .5px;
      height: 40px;
      font-size: 15px;
      line-height: 37px;
      background: $main;
      color: #fff;
      text-decoration: none;
      @include breakpoint($tab) {
        width: 150px;
      }
      &:hover {
        @include breakpoint($tab) {
          background: #00478e;
        }
      }
    }
    .product-teaser-quickview-wrapper a {
      color: $main;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: .5px;
      line-height: 18px;
      &:before {
        @extend .font-icon;
        @extend .mc-icon-quicklook;
        margin-right: 5px;
        font-size: 14px;
        display: inline-block;
        vertical-align: top;
        padding-top: 2px;
      }
    }
  }
  .image-wrapper.with-secondary {
    .secondary-teaser-image {
      display: none;
    }
    &:hover {
      .primary-teaser-image {
        display: none;
      }
      .secondary-teaser-image {
        display: block;
      }
    }
  }
}

.product-quicklook {
  @include pie-clearfix;
  @include breakpoint($tab) {
    margin: 15px auto 0;
  }
  .field__label {
    display: none;
  }
  .product-top-session {
    @include pie-clearfix;
  }
  .left-col {
    position: relative;
    @include breakpoint($tab) {
      width: 45%;
      padding: 0 30px 0 0;
      float: left;
    }
    .s7container {
      // height: 400px;
      .s7toolbarcontainer {
        .s7button {
          top: initial;
        }
      }
    }
  }
  .right-col {
    @include breakpoint($tab) {
      width: 55%;
      padding: 0 0 0 10px;
      float: right;
      position: relative;
    }
    .offer-product-tag,
    .new-product-tag {
      line-height: 21px;
      font-size: 15px;
      font-style: italic;
      font-weight: 500;
      padding-bottom: 5px;
    }
    .offer-product-tag {
      color: $red;
    }
    .new-product-tag {
      color: $green;
    }
    .title-wrapper {
      padding: 0;
      position: relative;
    }
    h1 {
      line-height: 14px;
      font-size: 18px;
      padding-bottom: 5px;
      text-align: left;
      margin-bottom: 15px;
      margin: 0;
      padding: 0;
      margin-bottom: 10px;
      @include breakpoint($tab) {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }
    .user-lists {
      @include pie-clearfix;
      width: 100%;
      @include breakpoint($tab) {
        width: 83%;
      }
    }
    .product-wishlist,
    .product-giftlist {
      // width: 50%;
      float: left;
      display: inline-block;
      margin-top: 15px;
      a {
        padding-left: 32px;
        font-size: 15px;
        color: $main;
        text-align: left;
        border-radius: 6px;
        line-height: 23px;
        text-decoration: underline;
      }
      .flag {
        position: relative;
        display: block;
        &:before {
          @extend .font-icon;
          @extend .mc-icon-heart;
          color: $main;
          font-size: 21px;
          display: inline-block;
          -webkit-border-vertical-spacing: 0;
          position: absolute;
          top: 50%;
          transform: translatey(-50%);
          left: 0;
        }
        &.unflag-action {
          &:before {
            @extend .font-icon;
            @extend .mc-icon-heart;
            font-size: 15px;
            display: block;
          }
        }
      }
      .visit-wishlist {
        display: none;
      }
    }
    .product-giftlist {
      float: right;
      a.flag:before {
        @extend .mc-icon-gift;
        font-size: 21px;
      }
      a.unflag-action:before {
        @extend .mc-icon-gift;
        font-size: 21px;
      }
    }
    .prices-wrapper {
      @include pie-clearfix;
      padding: 0;
      .final-price,
      .initial-price {
        display: inline-block;
      }
      .final-price {
        float: left;
        display: inline-block;
        line-height: 27px;
        font-size: 25px;
        color: $main;
        font-weight: 400;
        margin-right: 10px;
        .field--label-inline .field__label {
          display: none;
        }
        span.final-price-text {
          @extend .thin-font;
          line-height: 20px;
          font-size: 20px;
        }
        .commerce-product-field-commerce-price {
          padding-left: 5px;
          display: inline-block;
        }
      }
      .initial-price {
        color: #f6323e;
        line-height: 21px;
        font-size: 15px;
        font-weight: 400;
        @include breakpoint($tab) {
          line-height: 20px;
          font-size: 17px;
          margin-bottom: 10px;
        }
        span.initial-price-text {
          @extend .thin-font;
          display: inline-block;
          float: left;
          font-weight: 400;
          margin-right: 5px;
        }
        span.initial-price-value {
          background: linear-gradient(to bottom right, transparent 0, transparent calc(50% - 0.8px), #f6323e 50%, transparent calc(50% + .8px), transparent);
        }
        .commerce-product-field-commerce-price {
          padding-left: 5px;
          display: inline-block;
        }
        &.saving {
          // background: linear-gradient(to bottom right, transparent 0, transparent calc(50% - 0.8px), #f6323e 50%, transparent calc(50% + .8px), transparent);
        }
      }
      .profit-price {
        color: #00b300;
        font-weight: 400;
        display: inline-block;
        margin-bottom: 10px;
        margin-left: 20px;
        line-height: 20px;
        font-size: 17px;
        .profit-price-text {
          @extend .thin-font;
          margin-right: 5px;
          font-weight: 400;
        }
      }
    }
    .age {
      margin-top: 25px;
    }
    .delivery-option-wrapper {
      @include pie-clearfix;
      margin-top: 25px; // margin-bottom: 30px;
      .delivery-option {
        display: block;
        color: $main;
        font-size: 14px;
        font-weight: 300;
        float: left;
      }
      .delivery-option-more {
        font-size: 14px;
        font-weight: 300;
        float: right;
        text-decoration: underline;
      }
    }
    .stock {
      font-size: 14px;
      margin-top: 15px;
      .in-stock {
        color: #00b300;
      }
      .preorder {
        color: #ff6501;
      }
      .out-of-stock {
        color: #f6323e;
      }
    }
    .product-cart {
      border-top: 1px solid #979797;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
      position: relative;
      .size-guide-toggle {
        top: -35px;
      }
      .form-item-quantity {
        display: none;
      }
      .form-item {
        margin-bottom: 10px;
        &.form-type-radios {
          @include pie-clearfix;
          padding-bottom: 5px;
        }
      }
      .sizes-wrapper {
        border-bottom: 1px solid #979797;
        margin-bottom: 30px;
        .form-radios {
          display: flex;
          flex-wrap: wrap;
          & > .form-item {
            display: flex;
            align-items: stretch;
          }
        }
      }
      @include breakpoint($tab) {
        position: relative;
        z-index: 1;
        width: auto;
        .form-item-quantity {
          display: none;
        }
      }
      .form-submit {
        display: block;
        border-radius: 6px;
        line-height: normal;
        height: auto;
        font-weight: 500;
        font-size: 20px;
        padding: 10px 0;
        position: relative;
        background: #ff6501;
        border: 1px solid #ff6501;
        background-position: 10px;
        background-repeat: no-repeat;
        width: 100%;
        @include breakpoint($desk) {
          width: 83%;
        }
        &:hover {
          background: #fff;
          color: #ff6501;
        }
      }
    }
    #edit-product-id {
      @include pie-clearfix; // margin: 0 -10px;
      // padding-bottom: 15px;
      // border-bottom: 1px solid #979797;
    }
    .form-type-radios.form-item-product-id {
      & > label {
        line-height: 18px;
        font-size: 14px;
        text-align: left;
        display: block;
        font-weight: 400;
        margin: 0 0 15px 0;
      }
      .form-item {
        float: left;
        display: inline-block;
        width: 50%;
        padding: 0 4px;
        @include breakpoint($tab) {
          width: 33.333%;
        }
        &:nth-child(2n) {
          padding-right: 0;
          @include breakpoint($tab) {
            padding-right: 4px;
          }
        }
        &:nth-child(2n+1),
        &:first-child {
          padding-left: 0;
          @include breakpoint($tab) {
            padding-left: 4px;
          }
        }
        &:nth-child(3n) {
          @include breakpoint($tab) {
            padding-right: 0;
            padding-left: 8px;
          }
        }
        &:nth-child(3n+1),
        &:first-child {
          @include breakpoint($tab) {
            padding-left: 0;
            padding-right: 8px;
            clear: both;
          }
        }
        .form-radio {
          display: none;
        }
        label {
          cursor: pointer;
          background: #fff;
          color: $main;
          cursor: pointer;
          border: 1px solid $main;
          border-radius: 6px;
          margin-bottom: 12px;
          padding: 8px 10px;
          position: relative;
          width: 100%;
          text-align: center;
          font-size: 13px;
          margin: 0;
          letter-spacing: -1px;
          &:hover {
            background: $grey_icon;
          }
          &:before {
            display: none;
          }
        }
        &.form-disabled label,
        &.form-disabled label:hover {
          color: $grey_icon;
          border: 1px solid $grey_icon;
          background: linear-gradient(to bottom right, transparent 0, transparent calc(50% - .8px), $grey_icon 50%, transparent calc(50% + .8px), transparent);
        }
        input[type="radio"]:checked + label,
        label:hover {
          background: $main;
          color: #fff;
          &:after {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            font-size: 10px;
            line-height: 14px;
            text-align: center;
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border: 2px solid #fff;
            border-radius: 6px;
            background: transparent;
            transform: none;
          }
        }
      }
    }
    .form-item-quantity {
      ::-webkit-input-placeholder {
        visibility: hidden;
      }
      ::-moz-placeholder {
        visibility: hidden;
      }
      :-ms-input-placeholder {
        visibility: hidden;
      }
      :-moz-placeholder {
        visibility: hidden;
      }
      input[type="text"] {
        padding: 9px;
        line-height: 20px;
        width: 12%;
      }
    }
    .availability {
      float: right;
      .field__item {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 5px;
        position: relative;
        &:before {
          @extend .font-icon; // @extend .icon-tick;
          color: $black;
          font-size: 13px;
          padding-right: 5px;
        }
      }
    }
    .product-wrapper {
      .form-item-quantity {
        margin: 0;
        label {
          margin: 0 5px 5px 0;
          display: inline-block;
        }
        input {
          display: block;
          width: 85px;
          border-width: 2px;
        }
      }
      .form-submit {
        margin-top: 20px;
      }
    }
  }
  .product_full_details {
    font-weight: 400;
    text-align: center;
    padding-top: 20px;
    width: 100%;
    @include breakpoint($tab) {
      width: 82%;
    }
    a {
      font-size: 12px;
      font-weight: 400;
      text-decoration: underline;
      color: $main;
    }
  }
}

.node--product--full {
  @include pie-clearfix;
  @include breakpoint($tab) {
    margin: 15px auto 40px;
  }
  .field__label {
    display: none;
  }
  .product-top-session {
    @include pie-clearfix;
    margin: 20px 0;
    @include breakpoint($tab) {
      margin: 25px 0;
    }
  }
  .left-col {
    position: relative;
    @include breakpoint($tab) {
      width: 50%;
      padding: 0 30px 0 0;
      float: left;
    }
    #agelos_scene_7_demo {
      position: relative;
      z-index: 0;
      clear: both;
    }
    .s7container {
      // height: 400px;
      .s7toolbarcontainer {
        .s7button {
          top: initial;
        }
      }
    }
    .field--name-field-product-image {
      text-align: center;
      margin-bottom: 15px;
      @include pie-clearfix;
      @include breakpoint($tab) {
        padding: 0;
        margin-bottom: 0;
      }
      @include breakpoint($desk) {
        padding-right: 40px;
        padding-left: 100px;
      }
      .field-item {
        padding: 0;
      }
      img {
        height: auto;
        width: 100%;
        @include breakpoint($tab) {
          max-width: 490px;
        }
      }
      .slide-button {
        display: none;
      }
    }
    .gallery-slides {
      width: 100% !important;
      max-width: 687px;
      @include breakpoint($tab) {
        display: inline-block;
        float: right;
        width: 85% !important;
        @include breakpoint($desk) {
          width: 100% !important;
        }
      }
      .gallery-slide {
        width: 100%;
        height: 100%;
        display: block;
        @include breakpoint($tab) {
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
      .gallery-frame {
        width: 100%;
        height: 100%;
      }
    }
    .gallery-thumbs {
      clear: both;
      width: 100% !important;
      margin-top: 20px;
      @include breakpoint($tab) {
        position: absolute;
        left: 0;
        display: table;
        float: left;
        margin-top: 0;
        width: auto !important;
      }
      ul {
        display: table;
        width: 100%;
        @include breakpoint($tab) {
          width: auto !important;
        }
      }
      .arrow {
        display: none;
      }
      li {
        float: none;
        border: 0;
        display: inline-block;
        width: 9px !important;
        margin: 0 3px;
        @include breakpoint($tab) {
          clear: both;
          padding: 0 4px 7px 0;
          margin: 20px;
          display: block;
          width: auto !important;
        }
        &.cloned,
        &.empty {
          display: none;
        }
        &.active {
          @include breakpoint($tab) {}
          a {
            position: relative;
            display: block;
            &:after {
              content: "";
              width: 9px;
              height: 9px;
              display: block;
              background: $grey-icon;
              border: 1px solid $grey-icon;
              border-radius: 50%;
              @include breakpoint($tab) {
                background: rgba(0, 0, 0, 0.1);
                border-radius: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
            img {
              visibility: hidden;
              height: 6px;
              position: absolute;
              left: 0;
              @include breakpoint($tab) {
                position: relative;
                height: auto;
                visibility: visible;
              }
            }
          }
        }
        a {
          position: relative;
          display: block;
          &:after {
            content: "";
            width: 9px;
            height: 9px;
            display: block;
            border: 1px solid $grey-icon;
            background: transparent;
            border-radius: 50%;
            @include breakpoint($tab) {
              display: none;
            }
          }
          img {
            visibility: hidden;
            height: 6px;
            position: absolute;
            left: 0;
            @include breakpoint($tab) {
              position: relative;
              height: auto;
              visibility: visible;
            }
          }
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    .flex-control-nav {
      @include pie-clearfix;
      display: block;
      width: 100%;
      position: absolute; //bottom: -25px;
      text-align: center;
      padding: 0;
      @include breakpoint($tab) {
        display: none;
      }
      a {
        font-size: 0;
      }
    }
  }
  .right-col {
    @include breakpoint($tab) {
      width: 50%;
      padding: 0 0 0 10px;
      float: right;
      position: relative;
    }
    .offer-product-tag,
    .new-product-tag {
      line-height: 21px;
      font-size: 15px;
      font-style: italic;
      font-weight: 500;
      padding-bottom: 5px;
    }
    .offer-product-tag {
      color: $red;
    }
    .new-product-tag {
      color: $green;
    }
    .title-wrapper {
      padding: 0;
      position: relative;
    }
    h1 {
      line-height: 14px;
      font-size: 18px;
      text-align: left;
      margin: 15px 0 15px;
      @include breakpoint($tab) {
        font-size: 20px;
        line-height: 20px;
        padding: 0;
        padding-right: 100px;
        margin: 0 0 15px;
      }
    }
    .user-lists {
      @include pie-clearfix;
      @include breakpoint($desk) {
        width: 63%;
      }
    }
    .product-wishlist,
    .product-giftlist {
      // width: 50%;
      float: left;
      display: inline-block;
      margin-top: 15px;
      a {
        padding-left: 32px;
        font-size: 15px;
        color: $main;
        text-align: left;
        border-radius: 6px;
        line-height: 23px;
        text-decoration: underline;
      }
      .flag {
        position: relative;
        display: block;
        &:before {
          @extend .font-icon;
          @extend .mc-icon-heart;
          color: $main;
          font-size: 21px;
          display: inline-block;
          -webkit-border-vertical-spacing: 0;
          position: absolute;
          top: 50%;
          transform: translatey(-50%);
          left: 0;
        }
        &.unflag-action {
          &:before {
            @extend .font-icon;
            @extend .mc-icon-heart;
            font-size: 21px;
            display: block;
          }
        }
      }
      .visit-wishlist {
        display: none;
      }
    }
    .product-giftlist {
      float: right;
      a.flag:before {
        @extend .mc-icon-gift;
        font-size: 21px;
      }
      a.unflag-action:before {
        @extend .mc-icon-gift;
        font-size: 21px;
      }
    }
    .prices-wrapper {
      @include pie-clearfix;
      padding: 0;
      .final-price,
      .initial-price {
        display: inline-block;
      }
      .final-price {
        float: left;
        display: inline-block;
        line-height: 27px;
        font-size: 25px;
        color: $main;
        font-weight: 400;
        margin-right: 10px;
        .field--label-inline .field__label {
          display: none;
        }
        span.final-price-text {
          @extend .thin-font;
          line-height: 20px;
          font-size: 20px;
        }
        .commerce-product-field-commerce-price {
          padding-left: 5px;
          display: inline-block;
        }
      }
      .initial-price {
        color: #f6323e;
        line-height: 21px;
        font-size: 15px;
        font-weight: 400;
        @include breakpoint($tab) {
          line-height: 20px;
          font-size: 17px;
          margin-bottom: 10px;
        }
        span.initial-price-text {
          @extend .thin-font;
          display: inline-block;
          float: left;
          font-weight: 400;
          margin-right: 5px;
        }
        span.initial-price-value {
          background: linear-gradient(to bottom right, transparent 0, transparent calc(50% - 0.8px), #f6323e 50%, transparent calc(50% + .8px), transparent);
        }
        .commerce-product-field-commerce-price {
          padding-left: 5px;
          display: inline-block;
        }
        &.saving {
          // background: linear-gradient(to bottom right, transparent 0, transparent calc(50% - 0.8px), #f6323e 50%, transparent calc(50% + .8px), transparent);
        }
      }
      .profit-price {
        color: #00b300;
        font-weight: 400;
        display: inline-block;
        margin-bottom: 10px;
        margin-left: 20px;
        line-height: 20px;
        font-size: 17px;
        .profit-price-text {
          @extend .thin-font;
          margin-right: 5px;
          font-weight: 400;
        }
      }
    }
    .age {
      margin-top: 25px;
    }
    .delivery-option-wrapper {
      @include pie-clearfix;
      margin-top: 25px; // margin-bottom: 30px;
      .delivery-option {
        display: block;
        color: $main;
        font-size: 14px;
        font-weight: 300;
        float: left;
      }
      .delivery-option-more {
        font-size: 14px;
        font-weight: 300;
        float: right;
        text-decoration: underline;
      }
    }
    .stock {
      font-size: 14px;
      margin-top: 15px;
      .in-stock {
        color: #00b300;
      }
      .preorder {
        color: #ff6501;
      }
      .out-of-stock {
        color: #f6323e;
      }
    }
    .product-cart {
      margin-top: 25px;
      padding-top: 30px;
      border-top: 1px solid #979797;
      position: relative;
      .form-item-quantity {
        display: none;
      }
      .form-item {
        margin-bottom: 10px;
        &.form-type-radios {
          @include pie-clearfix;
          padding-bottom: 5px;
        }
      }
      .sizes-wrapper {
        border-bottom: 1px solid #979797;
        margin-bottom: 30px;
      }
      @include breakpoint($tab) {
        position: relative; // z-index: 1;
        width: auto;
        .form-item-quantity {
          display: none;
        }
      }
      .form-submit {
        float: left;
        display: block;
        border-radius: 6px;
        line-height: normal;
        height: auto;
        font-weight: 500;
        font-size: 20px;
        padding: 10px 0;
        position: relative;
        background: #ff6501;
        border: 1px solid #ff6501;
        clear: both;
        background-position: 10px;
        background-repeat: no-repeat;
        width: 100%;
        @include breakpoint($desk) {
          width: 63%;
        }
        &:hover {
          background: #fff;
          color: #ff6501;
        }
      }
      .out-of-stock-submit-wrapper .form-submit {
        border-color: #dbdbdb;
        background-color: #dbdbdb;
        &:hover {
          border-color: #dbdbdb;
          background-color: #dbdbdb;
          color: #fff;
        }
      }
    }
    .form-type-radios.form-item-product-id {
      .form-radios {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint($tab) {
          display: block;
        }
      }

      & > label {
        line-height: 18px;
        font-size: 14px;
        text-align: left;
        display: block;
        font-weight: 400;
        margin: 0 0 15px 0;
      }
      .form-item {
        float: left;
        display: inline-block;
        display: inline-flex;
        align-items: stretch;
        justify-content: center;
        width: 50%;
        padding: 0 4px;
        @include breakpoint($tab) {
          width: 33.333%;
          display: inline-block;
        }
        &:nth-child(2n) {
          padding-right: 0;
          @include breakpoint($tab) {
            padding-right: 4px;
          }
        }
        &:nth-child(2n+1),
        &:first-child {
          padding-left: 0;
          @include breakpoint($tab) {
            padding-left: 4px;
          }
        }
        &:nth-child(3n) {
          @include breakpoint($tab) {
            padding-right: 0;
            padding-left: 8px;
          }
        }
        &:nth-child(3n+1),
        &:first-child {
          @include breakpoint($tab) {
            padding-left: 0;
            padding-right: 8px;
            clear: both;
          }
        }
        .form-radio {
          display: none;
        }
        label {
          cursor: pointer;
          background: #fff;
          color: $main;
          cursor: pointer;
          border: 1px solid $main;
          border-radius: 6px;
          margin-bottom: 12px;
          padding: 8px 10px;
          font-size: 13px;
          position: relative;
          width: 100%;
          text-align: center;
          letter-spacing: -1px;
          display: flex;
          align-items: center;
          
          @include breakpoint($tab) {
            display: block;
          }

          &:hover {
            background: $grey_icon;
          }
          &:before {
            display: none;
          }
        }
        &.form-disabled label,
        &.form-disabled label:hover {
          color: $grey_icon;
          border: 1px solid $grey_icon;
          background: linear-gradient(to bottom right, transparent 0, transparent calc(50% - .8px), $grey_icon 50%, transparent calc(50% + .8px), transparent);
        }
        input[type="radio"]:checked + label,
        label:hover {
          background: $main;
          color: #fff;
          &:after {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            font-size: 10px;
            line-height: 14px;
            text-align: center;
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border: 2px solid #fff;
            border-radius: 6px;
            background: transparent;
            transform: none;
          }
        }
      }
    }
    .form-item-quantity {
      ::-webkit-input-placeholder {
        visibility: hidden;
      }
      ::-moz-placeholder {
        visibility: hidden;
      }
      :-ms-input-placeholder {
        visibility: hidden;
      }
      :-moz-placeholder {
        visibility: hidden;
      }
      input[type="text"] {
        padding: 9px;
        line-height: 20px;
        width: 12%;
      }
    }
    .availability {
      float: right;
      .field__item {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 5px;
        position: relative;
        &:before {
          @extend .font-icon; // @extend .icon-tick;
          color: $black;
          font-size: 13px;
          padding-right: 5px;
        }
      }
    }
    .product-wrapper {
      .form-item-quantity {
        margin: 0;
        label {
          margin: 0 5px 5px 0;
          display: inline-block;
        }
        input {
          display: block;
          width: 85px;
          border-width: 2px;
        }
      }
      .form-submit {
        margin-top: 20px;
      }
    }
  }
  .product-bottom-session {
    @include pie-clearfix;
    margin-top: 60px;
    .tab_drawer_heading {
      margin: 0;
      padding: 12px 30px;
      font-size: 14px;
      border-top: 1px solid #e7e7e7;
      cursor: pointer;
      color: $main;
      position: relative;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:before {
        @extend .font-icon;
        @extend .mc-icon-plus;
        position: absolute;
        -webkit-transform: none;
        transform: none;
        color: $main;
        right: 0;
        top: 13px;
        transition: all .4s linear;
        font-size: 15px;
      }
      .i-icon {
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 12px;
        color: $main;
      }
      &.product_information .i-icon:before {
        @extend .font-icon;
        @extend .mc-icon-info-bold;
      }
      &.specifications .i-icon:before {
        @extend .font-icon;
        @extend .mc-icon-specifications;
      }
      &.d_active {
        // border-bottom: 1px solid #e7e7e7;
        &:before {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      @include breakpoint($tab) {
        display: none;
      }
    }
    .tabs-wrapper {
      float: left;
      width: 90%;
      height: 495px;
    }
    .details-tabs {
      width: 10%;
      float: left;
      margin: 0 auto;
      text-align: center;
      display: none;
      @include breakpoint($tab) {
        display: table;
      }
      li {
        display: block;
        align-items: center;
        color: $main;
        border: 1px solid #d6d6d6;
        border-radius: 6px 0 0 6px;
        border-right: 0;
        height: 95px;
        width: 100%;
        margin-bottom: 5px;
        padding: 10px 5px;
        background-color: #f6f6f6;
        vertical-align: middle;
        display: flex;
        cursor: pointer;
        &.active {
          border: 1px solid $main;
          border-right: 0;
          box-shadow: -1px 2px 4px rgba(0, 0, 0, .5);
        }
        span {
          font-weight: 400;
          font-size: 12px;
          position: relative;
          text-align: center;
          width: 100%;
          display: inline-block;
          line-height: 16px;
          color: $main;
          text-decoration: none;
          &.product_information:before,
          &.specifications:before,
          &.questions_answers:before {
            font-size: 18px;
            display: block;
            margin-bottom: 10px;
            text-align: center;
          }
          &.product_information:before {
            @extend .font-icon;
            @extend .mc-icon-info-bold;
          }
          &.specifications:before {
            @extend .font-icon;
            @extend .mc-icon-specifications;
          }
          &.questions_answers:before {
            @extend .font-icon;
            @extend .mc-icon-question-bold;
          }
        }
      }
    }
    .tab_container {
      border-radius: 0 6px 6px 0;
      overflow: hidden;
      position: relative;
      overflow-y: auto;
      @include breakpoint($tab) {
        padding: 30px;
        border: 1px solid $main;
        min-height: 300px;
      }
      .tab_content {
        display: none;
      }
      & > * {
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
      }
    }
    .product-information-header {
      color: #888;
      font-weight: 300;
      font-size: 12px;
      margin: 10px 0 20px;
      @include pie-clearfix;
      .sku {
        float: left;
      }
      .brand {
        margin-right: 5px;
        float: right;
        a {
          margin-left: 5px;
          font-weight: 400;
          color: $main;
          text-decoration: underline;
        }
      }
    }
    #product_information {
      .product_info-wrap {
        margin-bottom: 30px;
      }
      h2 {
        margin: 0;
        margin-bottom: 10px;
        color: $main;
        font-size: 16px;
        font-weight: 400;
      }
      p {
        @extend .thin-font;
        margin-bottom: 15px;
        font-size: 14px;
        color: #414141;
      }
      strong {
        @extend .regular-font;
        color: $main;
        font-size: 16px;
      }
      ul {
        list-style: none;
        li {
          position: relative;
          margin-bottom: 12px;
          padding-left: 15px;
          font-size: 14px;
          @extend .thin-font;
          strong {
            font-size: 13px;
          }
          &:before {
            content: "";
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: #414141;
            position: absolute;
            top: 8px;
            left: 0;
          }
        }
      }
    }
    #specifications {
      & > div {
        padding: 0 15px;
      }
      h2 {
        color: $main;
        margin: 0;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 16px;
      }
      p {
        font-size: 14px;
        color: #414141;
        text-align: left;
      }
      table {
        margin: 0;
      }
      tbody tr td:nth-child(1) {
        text-align: left;
      }
      tbody tr td:nth-child(2) {
        text-align: left;
        @extend .thin-font;
        font-size: 14px;
      }
      tbody tr td {
        width: 50%;
      }
      ul {
        list-style: none;
        text-align: left;
        li {
          position: relative;
          margin-bottom: 12px;
          padding-left: 15px;
          font-size: 14px;
          strong {
            font-size: 13px;
          }
          &:before {
            content: "";
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: #414141;
            position: absolute;
            top: 8px;
            left: 0;
          }
        }
      }
    }
  }
}

.select-size-notice {
  display: none;

  &.show-notice {
    display: block;
    font-weight: bold;
    padding-top: 10px;
    font-size: 13px;
    clear: both;
    color: #ca2130;

    @include breakpoint($tab) {
      font-size: 16px;
    }
  }
}

.disabled-submit-wrapper {
  width: 100%;
  z-index: 98;
  position: relative;
  float: left;
  display: table;

  .node--product--full .right-col .product-cart & input {
    z-index: -1;
  }

  &:before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    z-index: 11;
  }
}

.size-guide-toggle {
  position: absolute;
  right: 0;
  text-decoration: underline;
  color: $main;
  cursor: pointer;
  top: -45px;
  &:before {
    @extend .font-icon;
    @extend .mc-icon-ruler;
    margin-right: 5px;
    display: inline-block;
  }
}

.size-guide {
  background: #fff;
  padding: 35px 1em;
  width: 95%;
  height: 95%;
  top: 10px;
  right: 0;
  left: 0;
  display: block;
  z-index: 101;
  position: fixed;
  overflow: auto;
  z-index: 499;
  margin: 0 auto;
  display: none;
  @include breakpoint($tab) {
    height: calc(100% - 130px);
    max-width: 920px;
    top: 100px;
  }
  &.open {
    display: block;
    overflow-x: hidden;
    .b-sizing_guide,
    table {
      max-width: 100%;
      overflow-x: auto;
      display: block;
    }
  }
  .ui-widget-content {
    border: 0;
  }
  .size-guide-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    &:before {
      @extend .font-icon;
      @extend .mc-icon-close;
      font-size: 15px;
      color: $main;
      top: 10px;
      right: 10px;
      position: absolute;
      &:hover {
        @include breakpoint($tab) {
          color: #0085ca;
        }
      }
    }
  }
  h3,
  h4 {
    margin: 0;
    margin-bottom: 15px;
    color: #414141;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: .5px;
    line-height: 26px;
  }
  p {
    color: #888;
  }
  table {
    color: #414141;
    margin-bottom: 20px;
    thead tr {
      background: #eaeaea;
      color: #424242;
    }
    th {
      border: 0;
      padding: 10px;
      text-align: center;
      border-bottom: 1px solid #d6d6d6;
    }
    td {
      vertical-align: middle;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid #d6d6d6;
      border-left: 1px solid #d6d6d6;
      padding: 10px;
      width: 100%;
      &:first-child {
        padding-left: 15px;
        font-weight: 400;
        text-align: left;
      }
    }
  }
}

.recommended-products {
  .block__title {
    text-align: center;
    margin: 0 0 40px;
    font-size: 24px;
  }
  .block__content {
    @extend .container;
  }
}

#block-rocket-path-wishlist-share-wishlist {
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #d6d6d6;
  .block__content {
    @include pie-clearfix;
    & > span {
      font-size: 20px;
      color: $main;
      float: left;
      padding: 12px 0;
      margin-right: 15px;
    }
    .solial-links {
      padding: 0;
    }
  }
}

.view-wishlist {
  .user-wishlist {
    margin-bottom: 20px;
    padding: 27px 20px;
    border: 1px solid #d6d6d6;
    h2 {
      margin: 0;
      color: $main;
    }
  }
  .view-content {
    margin-bottom: 20px;
    padding: 27px 20px;
    border: 1px solid #d6d6d6;
  }
}

.product-display-wishlist {
  @include pie-clearfix;
  text-align: center;
  padding: 0 10px;
  position: relative;
  border-bottom: 1px dashed #d6d6d6;
  margin-bottom: 30px;
  padding-bottom: 30px;
  .image-wrapper {
    max-width: 100px;
    margin: 0 10px 0 0;
    float: left;
  }
  .product-wishlist-info-wrapper {
    float: left;
    width: calc(100% - 120px);
    text-align: left;
  }
  .product-wishlist-title-wrapper {
    height: 40px;
    margin: 20px 0 10px;
    color: #414141;
    overflow: hidden;
    h2 {
      font-weight: 400;
      margin: 0;
    }
    a {
      font-size: 16px;
      line-height: 18px;
      color: #414141;
      display: inline-block;
      width: 90%;
    }
  }
  .info {
    float: left;
    width: 45%;
  }
  .product-cart {
    float: right;
    width: 55%;
    padding-top: 20px;
    .form-item-quantity {
      display: none;
    }
    .form-item {
      margin-bottom: 10px;
      &.form-type-radios {
        @include pie-clearfix;
        padding-bottom: 5px;
      }
    }
    .sizes-wrapper {
      border-bottom: 1px solid #979797;
      margin-bottom: 30px;
    }
    @include breakpoint($tab) {
      position: relative;
      .form-item-quantity {
        display: none;
      }
    }
    .form-submit {
      float: left;
      display: block; // border-radius: 6px;
      line-height: normal;
      height: auto;
      font-weight: 500;
      font-size: 15px;
      padding: 8px 0;
      position: relative;
      background: #ff6501;
      border: 1px solid #ff6501;
      clear: both;
      background-position: 10px;
      background-repeat: no-repeat;
      width: 100%;
      @include breakpoint($desk) {
        width: 63%;
      }
      &:hover {
        background: #fff;
        color: #ff6501;
      }
    }
    .out-of-stock-submit-wrapper .form-submit {
      border-color: #dbdbdb;
      background-color: #dbdbdb;
      &:hover {
        border-color: #dbdbdb;
        background-color: #dbdbdb;
        color: #fff;
      }
    }
  }
  .form-type-radios.form-item-product-id {
    & > label {
      line-height: 18px;
      font-size: 14px;
      text-align: left;
      display: block;
      font-weight: 400;
      margin: 0 0 15px 0;
    }
    .form-item {
      float: left;
      display: inline-block;
      width: 50%;
      padding: 0 4px;
      .form-radio {
        display: none;
      }
      label {
        cursor: pointer;
        background: #fff;
        color: $main;
        cursor: pointer;
        border: 1px solid $main;
        border-radius: 6px;
        padding: 5px 10px;
        font-size: 11px;
        position: relative;
        width: 100%;
        text-align: center;
        letter-spacing: -1px;
        &:hover {
          background: $grey_icon;
        }
        &:before {
          display: none;
        }
      }
      &.form-disabled label,
      &.form-disabled label:hover {
        color: $grey_icon;
        border: 1px solid $grey_icon;
        background: linear-gradient(to bottom right, transparent 0, transparent calc(50% - .8px), $grey_icon 50%, transparent calc(50% + .8px), transparent);
      }
      input[type="radio"]:checked + label,
      label:hover {
        background: $main;
        color: #fff;
        &:after {
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          font-size: 10px;
          line-height: 14px;
          text-align: center;
          display: block;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          border: 2px solid #fff;
          border-radius: 6px;
          background: transparent;
          transform: none;
        }
      }
    }
  }
  .form-item-quantity {
    ::-webkit-input-placeholder {
      visibility: hidden;
    }
    ::-moz-placeholder {
      visibility: hidden;
    }
    :-ms-input-placeholder {
      visibility: hidden;
    }
    :-moz-placeholder {
      visibility: hidden;
    }
    input[type="text"] {
      padding: 9px;
      line-height: 20px;
      width: 12%;
    }
  }
  .prices-wrapper {
    line-height: 21px;
    font-size: 13px;
    color: $main;
    @include breakpoint($tab) {
      font-size: 15px;
    }
  }
  .stock {
    font-size: 14px;
    margin-top: 15px;
    .in-stock {
      color: #00b300;
    }
    .preorder {
      color: #ff6501;
    }
    .out-of-stock {
      color: #f6323e;
    }
  }
  .product-actions {
    @include pie-clearfix;
    .view-details {
      margin: 10px auto;
      display: block;
      padding: 0 4px;
      letter-spacing: .5px;
      height: 40px;
      font-size: 15px;
      line-height: 37px;
      background: $main;
      color: #fff;
      text-decoration: none;
      @include breakpoint($tab) {
        width: 150px;
      }
      &:hover {
        @include breakpoint($tab) {
          background: #00478e;
        }
      }
    }
    .product-wishlist-quickview-wrapper a {
      color: $main;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: .5px;
      line-height: 18px;
      &:before {
        @extend .font-icon;
        @extend .mc-icon-quicklook;
        margin-right: 5px;
        font-size: 14px;
        display: inline-block;
        vertical-align: top;
        padding-top: 2px;
      }
    }
  }
}
.node--page--full {
  // margin-bottom: 70px;
  padding: 40px 20px 0;

  h1 {
    font-size: 32px;
    margin: 0 0 30px;
  }

  .field--name-body {
    line-height: 19px;
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
  }
}

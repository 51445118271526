.fieldset-wrapper {
  .messages {
    @extend .container;
    position: relative;
    padding: 20px 45px 20px 30px;
    opacity: 1;
    background: #fff;
    border: 0;
    margin-bottom: 20px;

    .close {
      cursor: pointer;
      opacity: 1;
      position: absolute;
      color: transparent;
      font-size: 1px;
      top: 17px;
      right: 15px;
      background-image: url('../svg/close.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 10px;
      height: 10px;
      display: table;
    }
  }

  .messages--status {
    border: 1px solid $green;
    color: $green;
  }

  .messages--warning {
    border: 1px solid rgba(255, 186, 0, 1);
    color: rgb(255, 186, 0);
  }

  .messages--error {
    border: 1px solid $red;
    color: $red;
  }
}

.messages.error {
  color: $red;
  border: 1px solid $red;
}

.l-messages {
  @extend .container;

  .messages {
    position: relative;
    padding: 20px 45px 20px 30px;
    opacity: 1;
    background: #fff;
    border: 0;
    margin: 40px auto;

    .close {
      cursor: pointer;
      opacity: 1;
      position: absolute;
      color: transparent;
      font-size: 1px;
      top: 17px;
      right: 15px;
      background-image: url('../svg/close.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 10px;
      height: 10px;
      display: table;
    }
  }

  .messages--status {
    border: 1px solid $green;
    color: $green;
  }

  .messages--warning {
    border: 1px solid rgba(255, 186, 0, 1);
    color: rgb(255, 186, 0);
  }

  .messages--error {
    border: 1px solid $red;
    color: $red;
  }
}
textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
  @extend .thin-font;
	border: 0;
	background: none;
	background: #fff;
	color: $black;
	font-weight: 300;
	padding: 9px 13px;
	font-size: 15px;
	line-height: 20px;
  border: 1px solid #d6d6d6;

	&:focus {
		outline: none;
	}
}

textarea {
	resize: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
	max-width: 570px;
	width: 100%;
}

input[type="email"] {
  &.error {
    border: 1px solid #ca2130 !important;
    border-right: 0;
    color: #ca2130 !important;
  }
}

input[type="submit"],
button {
	background: $main;
  @extend .regular-font;
	color: #fff;
	padding: 12px 38px 13px;
	border: 1px solid $main;
	font-size: 16px;
	line-height: 20px;
	cursor: pointer;
	border-radius: 0;

	&:focus {
		outline: none;
	}

	&:hover {
		border: 1px solid $blue;
		background: $blue;
	}
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
	background-color: #fff !important;
}

.form-disabled input[type="text"] {
  background: #ccc;
}

.selector {
  background: #fff;
  font-size: 14px;
  padding: 10.5px 40px 10.5px 15px;
  width: 100%;
  resize: none;
  text-align: left;
  position: relative;
  cursor: pointer;
  max-width: 100%;
  border: 1px solid #d6d6d6;
  line-height: 17px;

  &:before {
    @extend .font-icon;
    @extend .mc-icon-arrow-down;
    font-size: 15px;
    color: #9EA9AD;
    position: absolute;
    right: 10px;
    top: 13px;
  }

  .form-disabled & {
    background: #ccc;
  }

  select {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.form-item {
	margin-bottom: 20px;

	label {
    @extend .thin-font;
		margin-bottom: 5px;
    font-size: 15px;
    line-height: 18px;
	}

	& > .description {
    @extend .thin-font;
		margin-top: 5px;
    font-size: 12px;
    line-height: 18px;
    color: #9F9F9F;

    &.password-suggestions {
      border: 0;
      padding: 0;
    }

  }

  &.form-type-password-confirm > .description {
    color: #000000;

    &.password-suggestions {
      color: #9f9f9f;
    }
  }
}


input[type="checkbox"],
input[type="radio"] {
	display: none;
}

.form-type-checkbox label,
input[type="checkbox"] + label {
	position: relative;
	display: block;
	padding-left: 20px;

	&:before {
		width: 12px;
    height: 12px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    display: block;
    content: "";
    border: 1px solid $black;
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    border-radius: 2px;
    left: 0;
  }

  &:after {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    display: none;
    content: "";
    background: #003971;
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    left: 2px;
    border: 1px solid $black;
	}
}

input[type="checkbox"]:checked + label:after {
	display: block;
}

.form-type-radio label,
input[type="radio"] + label {
	position: relative;
	display: block;
	padding-left: 20px;

	&:before {
		width: 12px;
    height: 12px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    display: block;
    content: "";
    border: 1px solid $black;
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    left: 0;
    border-radius: 50%;
	}

	&:after {
    width: 8px;
    height: 8px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    display: none;
    content: "";
    background: #003971;
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    left: 2px;
    border: 1px solid $black;
    border-radius: 50%;
	}
}

input[type="radio"]:checked + label:after {
	display: block;
}

.webform-client-form-3526 {
  @include pie-clearfix;
  max-width: 840px;
  margin: 0 auto;
  padding: 0 20px;

  .form-item {
    width: 100%;

    @include breakpoint($tab) {
      width: 50%;
      float: left;
      display: inline-block;
      padding: 0 10px;

    }

    &.webform-component--page-title {
      @include breakpoint($tab) {
        width: 100%;
        float: none;
        display: block;
      }
    }

    &.webform-component-textarea {
      @include breakpoint($tab) {
        width: 100%;
      }

      textarea {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .form-submit {
    display: block;
    margin: 10px auto 0;
    font-weight: 400;
    clear: both;
  }
}

.form-type-textfield #autocomplete {
  max-height: 400px;
  overflow-y: scroll;
  
  ul {
    padding: 10px;
    overflow-y: scroll;
    
    li {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
$columns-count-default: 12;

@function grid_percentage($target, $container) {
  @return ($target / $container) * 100%;
}

@mixin columns-classes($breakpoint: false, $columns-count: $columns-count-default, $columns-widths-delimiter: "-") {
    @if $breakpoint {

        @media only screen and (max-width: $breakpoint) {
            $device: "";
            @if $breakpoint == $tab
            {
                $device: "sm";
            }
            @if $breakpoint == $desk
            {
                $device: "md";
            }
            @if $breakpoint == $wide
            {
                $device: "lg";
            }
            @include columns-width($columns-count, $columns-widths-delimiter, "#{$device}-");
        }
    }
    @else
    {
        @include columns-width($columns-count, $columns-widths-delimiter);
    }
}

@mixin columns-width($columns-count, $columns-widths-delimiter, $device: "") {
    @for $x from 1 through $columns-count {
        @for $y from 1 through $x {

            @if ($y == $x and $x != 1) or
                ($y % 2 == 0 and $x % 2 == 0) or
                ($y % 3 == 0 and $x % 3 == 0) or
                ($y % 5 == 0 and $x % 5 == 0) {
                // do nothing we already have that fraction
            }
            @else {
                &--#{$device}#{$y}#{$columns-widths-delimiter}#{$x} {
                    width: grid_percentage($y, $x);
                }
            }
        }
    }
}

.grid {
    @include clearfix;
    display: block;
    height: 100%;
    letter-spacing: -.215em;
    list-style: none;
    margin: 0;
    padding-left: 0;
    &--gutter
    {
        margin: 0 -10px;
        &--large
        {
            margin: 0 -25px;
        }
    }
    &__table
    {
        display: table;
        width: 100%;
        &__item
        {
            display: table-cell !important;
            vertical-align: middle !important;
        }
    }
    &--item,
    &__item
    {
        @include columns-classes();
        @include columns-classes($breakpoint: $desk);
        @include columns-classes($breakpoint: $tab);
        display: inline-block;
        height: 100%;
        letter-spacing: normal;
        vertical-align: top;
        &--gutter
        {
            padding: 0 10px;
            &--large
            {
                padding: 0 25px;
            }
        }
        &--right
        {
            text-align: right;
        }
        &--middle
        {
            vertical-align: middle;
        }
        &--bottom
        {
            vertical-align: bottom;
        }
    }
}
@media only screen and (max-width: $tab)
{
    .grid
    {
        &--gutter
        {
            margin: 0 -5px;
        }
        &--item--gutter,
        &__item--gutter
        {
            padding: 0 5px 5px;
        }
    }
}
a {
	color: $blue;

	&:hover {
		color: $green;
	}
}

.green-text {
	color: $green;
}

.red-text {
	color: $red;
}
.view-empty {
	text-align: center;
	margin: 40px 0;
	font-size: 16px;
	font-family: $second_font;
}

.view-faq {
  .faq-group {
    & > h3 {
      margin: 0;
      background: #fbfbfb;
      border: 1px solid #d6d6d6;
      color: #4D4D4D;
      display: block;
      padding: 12px;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      text-align: left;
      cursor: pointer;
      line-height: 1.5;
      margin-bottom: -1px;
      font-weight: 400;
      font-size: 14px;
      padding-right: 55px;
      word-wrap: break-word;
      position: relative;

      &:before {
        @extend .font-icon;
        @extend .mc-icon-plus;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 50px;
        font-size: 20px;
        font-weight: 100;
        vertical-align: middle;
        text-align: center;
        bottom: 0;
        margin: auto;
        line-height: 45px;
      }
    }

    .faq-group-content {
      padding: 10px;
      display: none;
    }

    &.active {
      & > h3 {
        background-color: #edf6fb;
        color: #003971;

        &:before {
          @extend .mc-icon-minus;
          color: #003971;
        }
      }

      .faq-group-content {
        display: block;
      }
    }

    .views-field-field-faq {
      margin-bottom: 15px;

      h3 {
        margin: 0 0 10px;
        font-size: 18px;
        cursor: pointer;
        color: #003971;
        font-weight: 400;
      }

      .faqfield-answer {
        display: none;

        p {
          line-height: 20px;
        }

        &.active {
          display: block;
        }
      }
    }
  }
}

.view-manuals {
  .manuals-group {
    & > h3 {
      margin: 0;
      background: #fbfbfb;
      border: 1px solid #d6d6d6;
      color: #4D4D4D;
      display: block;
      padding: 12px;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      text-align: left;
      cursor: pointer;
      line-height: 1.5;
      margin-bottom: -1px;
      font-weight: 400;
      font-size: 14px;
      padding-right: 55px;
      word-wrap: break-word;
      position: relative;

      &:before {
        @extend .font-icon;
        @extend .mc-icon-plus;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 50px;
        font-size: 20px;
        font-weight: 100;
        vertical-align: middle;
        text-align: center;
        bottom: 0;
        margin: auto;
        line-height: 45px;
      }
    }

    .manuals-group-content {
      padding: 10px;
      display: none;
    }

    &.active {
      & > h3 {
        background-color: #edf6fb;
        color: #003971;

        &:before {
          @extend .mc-icon-minus;
          color: #003971;
        }
      }

      .manuals-group-content {
        display: block;
      }
    }


    .views-field-field-manual-file {
      display: list-item;
      color: #4d4d4d;
      margin-left: 10px;

      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        word-spacing: 0px;
        color: #16467b;
        line-height: 20px;
      }
    }
  }
}

.view-latest-advice {
  @extend .container;
  margin-top: 30px;

  .view-content {
    @include pie-clearfix;
  }

  .views-row {
    float: left;
    width: 50%;

    @include breakpoint($tab) {
      width: 25%;
    }

    &:nth-child(4n+1) {
      @include breakpoint($tab) {
        clear: both;
      }
    }
  }
}

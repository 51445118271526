.commerce-line-item-views-form {
  .line-item-summary {
    margin: 20px 0 30px;
  }
  table {
    th {
      font-size: 12px;
      padding-bottom: 8px;
      @include breakpoint(520px) {
        font-size: 16px;
        padding-bottom: 15px;
      }
    }
    .views-field-edit-quantity {
      display: none;
      &.open {
        display: table-cell;
      }
      @include breakpoint($tab) {
        display: table-cell;
      }
      .delete-line-item {
        line-height: 25px;
        padding: 0 8px;
        font-size: 10px;
      }
    }
    .prices-wrapper {
      & > * {
        line-height: 18px;
      }
      .final-price {
        font-size: 18px;
        &.red-price {
          color: $red;
        }
      }
      .first-price {
        font-size: 15px;
        -webkit-text-decoration-line: strikethrough;
        text-decoration-line: strikethrough;
        text-decoration: line-through;
      }
    }
    .title-image-wrapper {
      .title-wrapper,
      .image-wrapper {
        display: inline-block;
      }
      .cart-image-wrapper {
        float: left;
        margin-right: 20px;
        display: inline-block;
        position: relative;
        @include breakpoint($tab) {
          float: none;
        }
        span.quantity {
          position: absolute;
          font-size: 12px;
          display: block;
          right: -10px;
          background: $blue;
          color: #fff;
          width: 22px;
          border-radius: 50%;
          height: 22px;
          text-align: center;
          line-height: 22px;
          top: -10px;
        }
      }
    }
    .views-field-edit-delete {
      display: none;
      @include breakpoint(520px) {
        display: table-cell;
      }
      input {
        background: transparent;
        color: transparent;
        background-image: url(/sites/all/themes/custom/mazeblock_theme/images/icons/close.svg);
        background-repeat: no-repeat;
        width: 10px;
        height: 10px;
        background-size: contain;
      }
    }
    .views-field-commerce-unit-price {
      display: none;
      @include breakpoint($tab) {
        display: table-cell;
      }
    }
  }
  tbody {
    .views-field {
      line-height: 40px;
    }
    .views-field-line-item-title {
      line-height: 20px;
      padding-top: 15px;
      padding-right: 10px;
      @include breakpoint(520px) {
        padding-top: 25px;
      }
      a {
        font-size: 12px;
        line-height: 15px;
        @include breakpoint(520px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    td.views-field-commerce-total {
      padding-right: 15px;
      line-height: 30px;
      @include breakpoint(520px) {
        line-height: 40px;
      }
      .cart-item-more {
        line-height: 25px;
        padding: 0 8px;
        font-size: 10px;
        border: 1px solid $black;
        display: inline-block;
        text-align: center;
      }
    }
    .delete-line-item {
      line-height: 12px;
      padding: 0;
      margin-left: 25px;
      background: transparent;
      color: $black; // border: 1px solid $black;
      &:hover {
        transition: all .2s;
      }
    }
    .form-type-textfield {
      input {
        background: #fff;
        border: 1px solid $border;
        padding: 0 15px;
        line-height: 20px;
        font-size: 12px;
        max-width: 40px;
        @include breakpoint(520px) {
          line-height: 38px;
          max-width: 60px;
        }
      }
    }
  }
  .commerce-line-item-actions {
    margin-bottom: 100px;
    .form-submit {
      margin-left: 10px;
      &#edit-submit {
        background: #fff;
        color: $blue;
        line-height: 48px;
        border: 1px solid $blue;
        margin-left: 0;
        @include breakpoint($tab) {
          margin-left: 10px;
        }
        &:hover {
          transition: all .2s;
          background: $blue;
          color: #fff;
          border: 1px solid $blue;
        }
      }
      &#edit-checkout {
        background: $green;
        margin-left: 20px;
      }
    }
  }
}

.page-cart,
.page-checkout {
  @include pie-clearfix;
  .selector {
    width: 100%!important;
    max-width: 570px;
  }
  .l-page h1 {
    text-align: left;
    font-size: 26px;
    margin: 30px 0 20px;
    @include breakpoint($tab) {
      display: none;
    }
  }
  .form-item label {
    text-transform: inherit;
  }
  .form-item-commerce-payment-payment-method {
    label {
      margin-bottom: 8px;
      &:after,
      &:before {
        top: 9px;
      }
    }
  }
}

.page-cart .l-page h1 {
  @include breakpoint($tab) {
    display: block;
  }
}

.page-cart {
  .views-table {
    margin-top: 20px;
    @include breakpoint($tab) {
      margin-top: 30px;
    }
  }
}

.page-checkout {
  @include pie-clearfix;
  .l-page {
    overflow: hidden;
  }
  .l-main .checkout-content-wrapper {
    margin-top: 25px;
    @include breakpoint($tab) {
      margin-top: 40px;
      @include breakpoint($desk) {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
      }
    }
  }
  .view-checkout-cart-summary-wrapper {
    width: 100%;
    margin-bottom: 25px;
    @include breakpoint($tab) {
      margin-bottom: 40px;
    }
    @include breakpoint($desk) {
      margin: 0;
      float: right;
      width: 35%;
    }
    .checkout-cart-side-links {
      @include pie-clearfix;
      display: none;
      padding: 15px 20px 0;
      background: #fff;
      @include breakpoint($tab) {
        background: none;
        display: block;
        margin-top: 40px;
        padding: 0 0 0 40px;
      }
      .edit-cart-link-wrapper {
        float: left;
        line-height: 50px;
        svg {
          width: 17px;
          display: inline-block;
          margin-right: 7px;
          position: relative;
          top: 2px;
        }
        a {
          font-size: 12px;
        }
      }
      .checkout-cart-side-checkout-link {
        float: right;
        font-size: 14px;
        font-weight: 300;
        padding: 0 28px;
        line-height: 50px;
        color: #fff;
        background: #00a900;
        border: 1px solid #00a900;
        cursor: pointer;
        display: none;
        @include breakpoint($tab) {
          display: inline-block;
        }
        &:hover {
          @include breakpoint($tab) {
            background: #003971;
            border: 1px solid #003971;
          }
        }
      }
    }
  }
  .commerce-checkout-form-checkout,
  .commerce-checkout-form-shipping {
    width: 100%;
    padding: 0; // border: 1px solid #d6d6d6;
    @include breakpoint($desk) {
      float: left;
      width: 65%;
      padding: 0 0 40px;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
    }
    .field--name-commerce-customer-address {
      .field__item {
        line-height: 18px;
      }
    }
  }
  .addressfield-container-inline .form-item {
    float: none;
  }
  .l-footer {
    margin: 0;
  }
  .form-item-commerce-fieldgroup-pane--group-gdpr-field-terms-and-conditions-und,
  .form-item-commerce-fieldgroup-pane--group-comments-field-terms-and-conditions-und {
    label {
      display: inline-block;
    }
    .form-required {
      display: none;
    }
    .description {
      display: inline-block;
      vertical-align: top;
      width: calc( 100% - 50px);
      font-size: 15px !important;
      line-height: 1.3em;
      margin-top: 0 !important;
      position: relative;
      padding-left: 12px;
      &::after {
        content: "*";
        color: red;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
  }
}

.page-checkout .view-checkout-cart-summary-wrapper {
  position: relative;
  padding: 0;
  .preorder-wrapper {
    border-bottom: 0;

    @include breakpoint($tab) {
      margin-left: 40px;
      border: 1px solid #d6d6d6;
      border-top: 0;
    }

    .preorder-message {
      padding: 22px 20px 15px;
      text-align: center;
      line-height: 22px;
      display: block;
      color: #ff6501;
    }
  }
  .top-checkout-summary-wrapper {
    border-bottom: 0;
    @include breakpoint($tab) {
      margin-left: 40px;
      border: 1px solid #d6d6d6;
    }
  }
  &.open-summary {
    z-index: 1000;
    background: #fff;
    border: 1px solid #E1E1E1;
    h2 {
      border-bottom: 1px solid #E1E1E1;
      background: #fff;
    }
    .checkout-cart-side-links {
      display: block;
    }
    .view-checkout-cart-summary {
      display: block;
      background: #fff;
    }
    .checkout-cart-close-popup {
      display: block;
    }
    .checkout-cart-overlay {
      display: block;
    }
  }
  h2 {
    margin: 0;
    padding: 15px 20px;
    font-weight: normal;
    font-size: 16px;
    position: relative;
    border-bottom: 2px solid #E1E1E1;
    color: #003971;
    font-weight: 700;
    &:before {
      @extend .font-icon;
      @extend .mc-icon-arrow-down;
      font-size: 14px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translatey(-50%);
      @include breakpoint($tab) {
        display: none;
      }
    }
    @include breakpoint($tab) {
      // padding-left: 40px;
      font-size: 21px;
      line-height: 20px;
      border: 0;
      display: inline-block;
    }
  }
  .edit-cart-link-wrapper {
    float: right;
    padding: 15px 20px;
    line-height: 20px;
    a {
      text-decoration: underline;
      color: #003971;
      font-size: 12px;
    }
  }
  .item-counter {
    padding: 15px 20px;
    border-bottom: 1px solid #E1E1E1;
    background: #fff;
    @include breakpoint($tab) {
      padding: 22px 20px 15px;
      border-bottom: 0;
    }
  }
  .checkout-cart-close-popup {
    display: none;
    background: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 50px;
    border-top: 1px solid #E1E1E1;
  }
  .checkout-cart-overlay {
    display: none;
    background: rgba(228, 228, 228, 0.6);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .view-checkout-cart-summary {
    display: none;
    @include breakpoint($tab) {
      display: block;
    }
  }
  .view-content {
    padding: 0 15px;
    @include breakpoint($tab) {
      padding-left: 40px;
      padding-right: 0;
    }
    table td {
      padding: 10px 30px 10px 10px;
    }
  }
  table {
    margin-top: 0;
    border: 0;
    @include breakpoint($tab) {
      border-left: 1px solid #d6d6d6;
      border-right: 1px solid #d6d6d6;
    }
    td {
      border: 0;
    }
  }
  tr:nth-child(2n+1) {
    background: transparent;
  }
  .views-field-title {
    h4 {
      font-size: 18px;
    }
    span {
      font-size: 13px;
    }
  }
  .price {
    font-size: 17px;
    .prices-wrapper {
      & > * {
        line-height: 18px;
      }
      .final-price {
        font-size: 18px;
        &.red-price {
          color: $red;
        }
      }
      .first-price {
        font-size: 15px;
        -webkit-text-decoration-line: strikethrough;
        text-decoration-line: strikethrough;
        text-decoration: line-through;
      }
    }
  }
  .views-field-field-product-image {
    padding-left: 0;
    width: 64px;
    & > div {
      position: relative;
    }
    .quantity {
      position: absolute;
      left: 55px;
      background: $blue;
      color: #fff;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      text-align: center;
      line-height: 22px;
      top: -10px;
    }
  }
  .view-footer {
    border-top: 1px solid #ccc;
    padding: 0 15px;
    @include breakpoint($tab) {
      padding: 0;
      margin-left: 40px;
      margin-right: 0;
    }
    .component-title {
      font-size: 16px; // opacity: 0.5;
      text-align: right;
    }
    .component-total {
      font-size: 16px;
      font-weight: 700;
      text-align: right;
      color: #003971;
    }
    table td,
    table {
      border: 0;
    }
    .commerce-order-handler-area-order-total {
      padding: 15px 20px;
      background: #fff;
      @include breakpoint($tab) {
        background: #e7e7e7;
      }
    }
  }
}

.checkout-breadcrumb {
  display: table;
  margin: 30px auto 0;
  .breadcrumb {
    background: transparent;
    padding-left: 0;
    padding-right: 0;
    font-size: 12px;
    text-align: left;
    @include breakpoint($tab) {
      text-align: center;
      font-size: 17px;
      @include breakpoint($desk) {
        text-align: left;
      }
    }
    li {
      display: inline-block;
      width: auto;
      margin-right: 5px;
      position: relative;
      padding-right: 12px;
      a {
        color: $black;
        text-decoration: underline;
        font-weight: 300;
      }
      &:after {
        content: '/';
        position: absolute;
        top: 0;
        right: 0;
      }
      &:last-child:after {
        display: none;
      }
    }
  }
  span.hidden-sm {
    display: none;
    @include breakpoint($desk) {
      display: inline;
    }
  }
}

.checkout-title {
  padding: 20px 0;
  text-align: center;
  color: #003971;
  border-bottom: 1px solid #d6d6d6;
  font-size: 17px;
  @include breakpoint($tab) {
    font-size: 22px;
  }
  span {
    color: #888;
  }
}

.commerce-checkout-form-checkout,
.commerce-checkout-form-shipping {
  @include pie-clearfix;
  & > div {
    @include clearfix;
  }
  & > div > fieldset,
  & > div > div {
    padding: 0;
    width: 100%;
    &:first-child {
      padding-top: 0;
    }
    .fieldset-wrapper {
      padding: 20px;
      @include breakpoint($tab) {
        padding: 20px 30px;
      }
    }
    &.checkout-buttons {
      padding-bottom: 0;
      border: 0;
    }
  }
  & > div > .commerce_fieldgroup_pane__group_invoice .fieldset-wrapper {
    padding-top: 0;
  }
  .mothercare_loyalty {
    .description {
      margin-bottom: 10px;
    }
  }
  legend {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 8px;
    background: #fbfbfb;
    color: #003971;
    width: 100%;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    padding: 15px 20px;
    @include breakpoint($tab) {
      padding: 15px 30px;
    }
  }
  tr.pane-title {
    background: transparent;
    display: none;
    td {
      font-size: 21px;
      font-family: $second_font;
      padding-left: 0;
    }
    &:first-child {
      display: table-row;
    }
  }
  .form-type-select {
    position: relative;
    label {
      font-size: 13px;
      margin-bottom: 0;
      @include breakpoint($tab) {
        margin-bottom: 5px;
      }
    }
  }
  .form-type-textfield {
    label {
      font-size: 13px;
      margin-bottom: 0;
      @include breakpoint($tab) {
        margin-bottom: 5px;
      }
    }
  }
  .commerce_coupon {
    @include pie-clearfix;
    .form-item-commerce-coupon-coupon-code {
      float: left;
      width: 65%;
      padding-right: 20px;
    }
    .form-submit {
      float: left;
      width: 35%;
      line-height: 35px;
      margin-top: 18px;
      padding: 1px 0 2px 0;
      font-size: 10px;
      @include breakpoint($tab) {
        line-height: 29px;
        margin-top: 21.9px;
        font-size: 14px;
        padding: 5px 38px;
      }
    }
  }
}

.group-invoice {
  display: none;
}

.commerce-checkout-form-checkout .sticky-wrapper {
  float: right;
  width: 311px;
  z-index: 2;
}

.commerce-checkout-form-checkout > div .cart-contents-wrapper,
.commerce-checkout-form-shipping > div .cart-contents-wrapper {
  z-index: 500;
  background: #fff;
  width: 100%;
  @include breakpoint($tab) {
    float: right;
    width: 311px;
  }
  .cart_contents {
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.24);
    .view-commerce-cart-summary {
      .form-actions {
        .form-submit {
          display: none;
        }
      }
    }
  }
  legend {
    font-size: 18px;
    color: $blue;
    text-align: center;
    line-height: 52px;
    border-bottom: 1px solid $blue;
    background: #000000;
  }
  .line-item-quantity {
    position: absolute;
    top: -32px;
    right: 0;
    .line-item-quantity-label {
      display: none;
    }
    .line-item-quantity-raw {
      background: $blue;
      font-size: 10.5px;
      font-weight: 400;
      line-height: 18px;
      border-radius: 50%;
      text-align: center;
      width: 18px;
      height: 18px;
      display: inline-block;
    }
  }
  .links {
    text-align: center;
    margin: 20px 0 0;
  }
  .line-item-summary-view-cart {
    display: none;
  }
  .line-item-summary {
    margin: 0 0 20px;
    clear: both;
  }
  .line-item-summary-checkout {
    display: inline-block;
    float: none;
    margin: 0;
    a {
      background: $blue;
      padding: 15px 20px;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      display: inline-block;
      &:hover {
        transition: all .2s;
        text-decoration: none;
        color: $blue;
        background: #000;
      }
    }
  }
  .commerce-price-formatted-components {
    background: transparent;
    width: 100%;
    margin-bottom: 20px;
    tr {
      background: transparent;
    }
  }
  .view-content {
    margin: 0;
    padding: 10px 20px 0;
    table.views-table {
      margin: 0 0 5px;
      max-height: 160px;
      overflow-y: scroll;
      display: block;
    }
  }
  .views-row {
    @include clearfix();
    margin: 0 0 15px;
    position: relative;
    &:before {
      width: 100%;
      height: 1px;
      bottom: 7px;
      background: #ddd;
      left: 0;
      clear: both;
      position: absolute;
      display: table;
      content: '';
    }
    .views-field-edit-delete {
      top: 0;
      right: 0;
      position: absolute;
      .field-content:before {
        @extend .font-icon;
        @extend .mc-icon-close;
        font-size: 11px;
      }
      input[type="submit"] {
        padding: 0;
        font-size: 0;
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .views-field-commerce-total,
    .views-field-field-product-image,
    .views-field-quantity,
    .views-field-line-item-title {
      display: inline-block;
      float: left;
      max-width: 220px;
    }
    .prices-wrapper {
      & > * {
        line-height: 18px;
      }
      .final-price {
        font-size: 18px;
        &.red-price {
          color: $red;
        }
      }
      .first-price {
        font-size: 15px;
        -webkit-text-decoration-line: strikethrough;
        text-decoration-line: strikethrough;
        text-decoration: line-through;
      }
    }
    .views-field-line-item-title {
      font-size: 14px;
      font-weight: 400;
      width: 190px;
      margin-bottom: 8px;
    }
    .views-field-quantity {
      font-size: 12px;
      font-weight: 400;
      color: $grey;
    }
    .views-field-field-product-image {
      padding-right: 25px;
      background: #fff;
      position: relative;
      z-index: 1;
    }
    .views-field-commerce-total {
      float: right;
      font-size: 14px;
    }
  }
}

.checkout_completion_message {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  padding: 100px 20px 100px;
}

#commerce-checkout-form-complete {
  margin: auto;
}

.checkout-buttons {
  text-align: center;
  .checkout-continue {
    margin: 0 auto 5px;
    display: block;
    border: 1px solid #00a900;
    font-size: 14px;
    font-weight: 300;
    padding: 0 28px;
    line-height: 50px;
    color: #fff;
    background: #00a900;
    &:hover {
      @include breakpoint($tab) {
        background: #003971;
        border: 1px solid #003971;
      }
    }
  }
}

.checkout_review legend {
  display: none;
}

.commerce_payment {
  img {
    width: auto;
    max-width: 250px;
  }
}

.mothercare_review {
  .field--name-commerce-customer-shipping,
  .field--name-commerce-customer-billing {
    width: 100%;
    display: inline-block;
    float: left;
    @include breakpoint($tab) {
      width: 50%;
    }
    .field__label {
      margin-bottom: 10px;
    }
    .field--name-field-phone .field__label {
      display: none;
    }
  }
  .field--name-commerce-customer-shipping {
    margin-bottom: 30px;
    @include breakpoint($tab) {
      margin-bottom: 0;
    }
  }
  .field--name-field-invoice {
    clear: both;
    padding: 30px 0 10px;
  }
}

.commerce-checkout-form-review {
  margin-bottom: 100px;
  .checkout-help {
    margin: 50px 0;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
  }
  .checkout_review {
    padding: 0 20px;
    width: 100%;
    max-width: 680px;
    display: block;
    margin: 0 auto;
    legend {
      display: none;
    }
    .checkout-review {
      width: 100%;
      border: 1px solid #f8f8f8;
      .pane-title {
        font-size: 18px;
        text-align: center;
        line-height: 52px;
        font-family: $second_font;
        background: #f8f8f8;
      }
      .pane-data {
        background: transparent;
        & > td {
          padding: 20px;
        }
        font-size: 16px;
        .form-item {
          width: 50%;
          display: inline-block;
        }
      }
      .view-commerce-cart-summary {
        .commerce-order-handler-area-order-total {
          .commerce-price-formatted-components {
            width: 50%;
          }
        }
        .line-item-quantity {
          position: absolute;
          top: -175px;
          right: -25px;
          .line-item-quantity-label {
            display: none;
          }
          .line-item-quantity-raw {
            background: $blue;
            font-size: 10.5px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            width: 18px;
            height: 18px;
            display: inline-block;
          }
        }
        .links {
          text-align: center;
          margin: 20px 0 0;
        }
        .line-item-summary-view-cart {
          display: none;
        }
        .line-item-summary {
          margin: 0 0 20px;
          clear: both;
          position: relative;
        }
        .line-item-summary-checkout {
          display: inline-block;
          float: none;
          margin: 0;
          a {
            background: $blue;
            padding: 15px 20px;
            font-weight: 400;
            font-size: 13px;
            line-height: 13px;
            display: inline-block;
            &:hover {
              transition: all .2s;
              text-decoration: none;
              color: $blue;
              background: #000;
            }
          }
        }
        .views-row {
          @include clearfix();
          margin: 0 0 15px;
          position: relative;
          &:before {
            width: 100%;
            height: 1px;
            bottom: 7px;
            background: #ddd;
            z-index: -1;
            left: 0;
            clear: both;
            position: absolute;
            display: table;
            content: '';
          }
          .views-field-edit-delete {
            top: 0;
            right: 0;
            position: absolute;
            .field-content:before {
              @extend .font-icon;
              @extend .mc-icon-close;
              font-size: 11px;
            }
            input[type="submit"] {
              padding: 0;
              font-size: 0;
              background: transparent;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
          .views-field-commerce-total,
          .views-field-field-product-image,
          .views-field-quantity,
          .views-field-line-item-title {
            display: inline-block;
            float: left;
            max-width: 220px;
          }
          .views-field-line-item-title {
            font-size: 14px;
            font-weight: 400;
            width: calc(100% - 200px);
            max-width: initial;
            margin-bottom: 8px;
          }
          .views-field-quantity {
            width: calc(100% - 400px);
            max-width: initial;
            font-size: 12px;
            font-weight: 400;
            color: $grey;
          }
          .views-field-field-product-image {
            padding-right: 25px;
            background: #fff;
          }
          .views-field-commerce-total {
            float: right;
            font-size: 14px;
          }
        }
      }
    }
  }
}

table.checkout-review {
  margin-top: 0;
  .pane-data {
    .pane-data-full {
      padding: 0;
    }
  }
}

.cart-empty-page {
  text-align: center;
  margin: 20px 0 40px;
  font-size: 20px;
}

.mazeblock-commerce-checkout-user-links {
  position: relative;
  .mazeblock-commerce-checkout-user-links-inner {
    @include breakpoint($tab) {
      position: absolute;
      top: -8px;
      right: 0;
    }
    a {
      border: 1px solid $blue;
      color: $blue;
      padding: 10px 20px;
      font-size: 12px;
      margin: 0 10px 0 0;
      @include breakpoint($tab) {
        margin: 0 0 0 10px;
      }
      &:hover {
        transition: all .2s;
        background: $blue;
        color: #fff;
      }
    }
  }
}

.commerce-checkout-form-checkout > div > fieldset.account {
  margin-bottom: 40px;
  @include pie-clearfix;
  #account-login-container .form-item {
    margin-bottom: 5px;
  }
  .mazeblock-commerce-checkout-login-link {
    font-size: 12px;
  }
  #edit-account-picture {
    display: inline-block;
    margin-right: 20px;
    border-radius: 50%;
    border: 1px solid $blue;
    float: left;
    overflow: hidden;
    img {
      max-width: 50px;
      height: auto;
    }
  }
  #edit-account-username {
    display: none;
  }
  #edit-account-mail {
    float: left;
    display: inline-block;
    margin-bottom: 0;
    margin-top: 16px;
  }
}

.customer_profile_billing,
.customer_profile_shipping {
  .form-submit {
    background: transparent;
    color: $black;
    margin-top: 10px;
    border: 1px solid $black;
    font-size: 12px;
    line-height: 30px;
    padding: 0 15px;
    &:hover {
      transition: all .2s;
      background: $black;
      color: #fff;
    }
  }
}

.terms_conditions,
.commerce_fieldgroup_pane__group_gdpr,
.commerce_fieldgroup_pane__group_comments,
.commerce_fieldgroup_pane__group_invoice {
  & > legend {
    display: none;
  }
}

.shipping-discount-text {
  color: $red;
}

.payment-redirect-form {
  width: 100%;
  padding: 100px 40px;
  text-align: center;
  .checkout-help {
    margin-bottom: 30px;
  }
}

.field-group-tab-wrapper {
  display: none;
}

.view-commerce-line-item-table,
.view-checkout-cart-summary {
  .line-item-wrapper {
    .line-item-left-info {
      width: 25%;
      float: left;
      display: inline-block;
      position: relative;
      padding-right: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .line-item-main-info {
      width: 75%;
      display: inline-block;
      float: left;
      text-align: left;
    }
    .line_item_title {
      font-size: 14px;
      color: $main;
      margin-bottom: 6px;
    }
    .line_item_size {
      margin: 3px 0;
    }
    .prices-wrapper {
      margin-bottom: 10px;
      text-align: left;
      display: block;
      width: 100%;
      font-size: 14px;
      @include pie-clearfix;
      .both-prices-wrapper {
        float: right;
        text-align: right;
      }
      .final-price {
        font-weight: 400;
        color: $main;
        float: right;
      }
      label {
        font-size: 12px;
        margin-bottom: 0;
        color: #888;
        float: left; // @extend .thin-font;
      }
      .initial-price {
        font-size: 12px;
        color: #414141;
        text-decoration: line-through;
      }
    }
    .preorder-message {
      display: block;
      clear: both;
      padding-top: 10px;
      color: #ff6501;
    }
    .line-item-quantity {
      clear: both;
      font-size: 12px;
      margin-bottom: 10px;
      color: #888;
      input {
        max-width: 65px;
      }
    }
    .total-line-item {
      font-size: 16px;
      color: $main;
      width: 100%;
      display: block; // text-align: right;
      .total-line-item-label {
        float: left;
        font-size: 14px;
        margin-bottom: 0;
        color: #888;
        float: left;
      }
      .total-line-item-amount {
        float: right;
      }
    }
  }
}

html[lang="en"] .message--el,
html[lang="el"] .message--en {
  display: none;
}

.commerce_fieldgroup_pane__group_invoice {
  .group-gdpr {
    display: none;
  }
}

.mothercare_complete {
  .fieldset-legend {
    display: none;
  }
}

.complete-order-message {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  margin: 100px 0 80px;
  h2 {
    font-size: 32px;
    font-family: "futura-plus";
    margin: 25px 0;
  }
  .complete-order-front {
    margin-top: 10px;
  }
  .complete-order-dias-number {
    span {
      font-weight: bold;
    }
  }
}

.complete-order-banner {
  img {
    width: 100%;
  }
}

.rocket-path-commerce-checkout-user-links-inner {
  margin-bottom: 20px;
  @include breakpoint($tab) {
    margin-bottom: 40px;
    display: table;
    width: 100%;
  }
  .rocket-path-commerce-checkout-login,
  .rocket-path-commerce-checkout-register {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    background: #f0f0f0;
    color: #003971;
    &:hover {
      color: $blue;
    }
  }
  .rocket-path-commerce-checkout-login {
    margin-right: 10px;
  }
  .image,
  .text {
    display: table-cell;
    vertical-align: middle;
    color: #003971;
  }
  img {
    display: inline-block;
    width: auto;
    max-width: 60px;
    vertical-align: top;
  }
  img:nth-of-type(2) {
    max-width: 90px;
    padding: 5px 0;
  }
  .image {
    width: 200px;
    padding-left: 30px;
  }
}

.page-checkout div.addressfield-container-inline > div.form-item {
  margin-right: 0;
}

.commerce-checkout-form-shipping .group-contact-methods,
fieldset#edit-commerce-fieldgroup-pane-group-gdpr .fieldset-wrapper {
  padding-top: 0;
}

.form-item-commerce-fieldgroup-pane--group-gdpr-field-gdpr-profiling-sw-und,
.form-item-commerce-fieldgroup-pane--group-gdpr-field-gdpr-market-research-sw-und {
  .description {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 18px;
    color: #414141;
    display: inline-block;
    width: calc( 100% - 50px);
    margin-top: -3px;
  }
  label {
    font-size: 0;
    display: inline-block;
    width: 30px;
    float: left;
  }
}

.commerce-checkout-form-shipping > div > fieldset.commerce_fieldgroup_pane__group_comments .fieldset-wrapper {
  padding-top: 10px;
  padding-bottom: 0;
}

html.js .form-autocomplete {
  background: none;
}

#payment-details {
  display: none;
}
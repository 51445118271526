.term-info {
	.term-designer-title {
		background: $background;
		position: relative;
		@include breakpoint($tab) {
			background: transparent;
			margin-bottom: 20px;
		}
		&:before {
			content: url('../svg/down-arrow.svg');
			position: absolute;
			top: 50%;
			transform: translatey(-50%);
			right: 10px;
			width: 20px;
			height: 5px;
			@include breakpoint($tab) {
				display: none;
			}
		}
	}
	.term-info-highlighted-wrapper {
		@include pie-clearfix;
		background: $background;
		.term-info-wrapper {
			.term-info-inner {
				@including pie-clearfix;
			}
			.designer-content-wrapper {
				max-width: 1040px;
				margin: 0 auto;
				width: 100%;
				@including pie-clearfix;
				display: none;
				@include breakpoint($tab) {
					padding: 0 20px;
					display: block;
					text-align: center;
					@include breakpoint($desk) {
						text-align: left;
					}
				}
				.term-info-logo {
					display: inline-block;
					width: 100%;
					text-align: center;
					@include breakpoint($tab) {
						width: 30%;
					}
					img {
						max-width: 300px;
					}
					h1 {
						display: none;
					}
				}
				.term-designer-description {
					max-width: 640px;
					float: right;
					font-size: 13px;
					line-height: 21px;
					padding: 0 0 20px;
					@include breakpoint($tab) {
						font-size: 15px;
						text-align: left;
						@include breakpoint($desk) {
							padding: 0 0 0 60px;
						}
					}
				}
			}
		}
		.term-info-banner {
			text-align: right;
			width: 100%;
			height: auto;
		}
		.term-info-wrapper {
			@include pie-clearfix;
			@include breakpoint($tab) {
				padding: 20px 0 0;
				margin-bottom: 20px;
			}
			.term-info-description {
				width: 100%;
				max-width: 745px;
				font-size: 12px;
				color: $grey;
				line-height: 18px;
				@include breakpoint($desk) {
					float: right;
					padding-top: 5px;
					margin-right: 215px;
				}
			}
			.term-info-name {
				display: inline-block;
				background: $background;
				position: relative;
				text-align: center;
				margin: 0 auto;
				width: 100%;
				@include breakpoint($tab) {
					background: transparent;
				}
				&:before {
					content: url('../svg/down-arrow.svg');
					position: absolute;
					top: 50%;
					transform: translatey(-50%);
					right: 10px;
					width: 20px;
					height: 5px;
					@include breakpoint($tab) {
						display: none;
					}
				}
			}
		}
		.taxonomy-term-children {
			text-align: center;
			display: none;
			@include breakpoint($tab) {
				display: block;
			}
			.child-wrapper {
				display: inline-block;
				position: relative;
				padding: 0 7px;
				width: 50%;
				margin-bottom: 20px;
				@include breakpoint($tab) {
					width: 25%;
					margin-bottom: 50px;
				}
				&:first-child {
					padding: 0 14px 0 0;
					margin-bottom: 20px;
					@include breakpoint($tab) {
						margin-bottom: 50px;
					}
				}
				&:last-child {
					padding: 0 0 0 14px;
					margin-bottom: 20px;
					@include breakpoint($tab) {
						margin-bottom: 50px;
					}
				}
				.child-term-info-wrapper {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					text-align: center;
					z-index: 1;
					a {
						color: #fff;
						font-size: 17px;
						display: block;
					}
					.shop-button {
						text-decoration: underline;
						clear: both;
						font-size: 14px;
						line-height: 18px;
					}
				}
				.child-term-info-banner-wrapper {
					.child-term-info-banner {
						overflow: hidden;
						&:hover {
							img {
								@include breakpoint($desk) {
									transform: scale(1.05);
									filter: brightness(0.7);
									transition: all 1s ease;
								}
							}
						}
					}
				}
			}
		}
	}
	.term-info-highlighted-wrapper.empty-term-content {
		@include breakpoint($tab) {
			background: transparent;
		}
		.term-info-wrapper {
			padding: 0;
			margin: 0;
		}
	}
}

.term-info.open {
	.term-designer-title {
		position: relative;
		&:before {
			content: url('../svg/up-arrow.svg');
			right: 10px;
			width: 20px;
			height: 5px;
		}
	}
	.term-info-highlighted-wrapper .term-info-wrapper .term-info-name {
		position: relative;
		&:before {
			content: url('../svg/up-arrow.svg');
			right: 10px;
			width: 20px;
			height: 5px;
		}
	}
	.term-info-highlighted-wrapper .term-info-wrapper .designer-content-wrapper {
		display: block;
		padding-top: 20px;
	}
	.taxonomy-term-children {
		display: block;
	}
}

#rocket-path-elastic-page-wrapper {
	@include pie-clearfix;
	margin-bottom: 70px;
}

.rocket-path-elastic-results {
	width: 100%;
	@include breakpoint($tab) {
		width: 75%;
		float: right;
		border: 0;
	}
	.hub-child-teaser {
		position: relative;
		text-decoration: none;
		display: block;
		.puff_banner_description {
			padding: 10px 4px;
			background-color: #fff;
			text-align: center;
			overflow: hidden;
			position: absolute;
			right: 10px;
			bottom: 10px;
			left: 10px;
		}
		h3 {
			color: $main;
			font-size: 16px;
			font-weight: 400;
			margin: 0;
			margin-bottom: 5px;
		}
		.field--name-field-term-subtitle {
			color: #959595;
			font-size: 13px;
		}
		.field--name-description-field {
			font-size: 11px;
			font-weight: 400;
			display: block;
			height: 42px;
			margin-bottom: -42px;
			padding-top: 10px;
			transition: margin-bottom .5s ease-in-out;
			p {
				font-size: 11px;
				color: #959595;
			}
		}
		&:hover {
			.field--name-description-field {
				margin-bottom: 0;
			}
		}
	}
	.rocket-path-elastic-results-empty {
		text-align: center;
		padding: 30px 0;
	}
	.products-grid {
		border-bottom: 1px solid $background;
		@include breakpoint($tab) {
			border: 0;
		}
	}
	.products-grid > * {
		//border-top: 1px solid $background;
		position: relative;
		float: left;
		margin-bottom: 30px;
		@include breakpoint($tab) {
			margin-bottom: 50px;
		}
		&:nth-child(2n + 1) {
			clear: both;
			@include breakpoint($desk) {
				clear: none;
			}
		}
		&:nth-child(3n + 1) {
			@include breakpoint($desk) {
				clear: both;
			}
		}
		@include breakpoint($tab) {
			border: 0;
			&:nth-child(n) {
				&:before {
					display: none;
				}
			}
		}
	}
}

.rocket-path-elastic-header {
	h1 {
		margin: 0;
		margin-bottom: 22px;
		font-size: 33px;
		text-align: left;
		color: $main;
		font-weight: 400;
		padding: 0 10px;
	}
}

.rocket-path-elastic-facets-total {
	margin-top: 60px;
	color: #414141;
	font-size: 13px;
}

.rocket-path-elastic-header-inner {
	@include pie-clearfix;
	margin: 0;
	padding: 15px 0;
	border-top: 1px solid #e7e7e7;
	border-bottom: 1px solid #e7e7e7;
	margin-top: 10px;
}

.rocket-path-elastic-footer {
	clear: both;
	@include pie-clearfix;
	font-size: 12px;
	padding: 5px 20px;
	margin-bottom: 5px;
	@include breakpoint($tab) {
		border-top: 1px solid $background;
		padding: 15px 0 20px 30px;
		margin: 0;
		border: 0;
	}
}

.rocket-path-elastic-header,
.rocket-path-elastic-footer {
	.rocket-path-elastic-total {
		font-size: 12px;
		width: 100%;
		text-align: right;
		line-height: 19px;
		@include breakpoint($tab) {
			text-align: left;
			width: auto;
			float: right;
			margin-right: 50px;
		}
		a {
			color: $black;
			text-decoration: underline;
		}
	}
	.rocket-path-elastic-sort {
		float: left;
		position: relative;
		label {
			font-size: 14px;
			line-height: 40px;
			margin-right: 10px;
			display: inline-block;
			vertical-align: top;
		}
		ul {
			position: absolute;
			top: 0;
			left: 100%;
			width: auto;
			display: table;
			min-width: 155px;
			z-index: 0;
			padding: 0;
			margin: 0;
			&:before {
				@extend .font-icon;
				@extend .mc-icon-arrow-down;
				font-size: 15px;
				top: 11px;
				right: 30px;
				position: absolute;
				line-height: 19px;
				width: 1px;
				height: 20px;
				display: block;
			}
			li {
				list-style: none;
				display: none;
				width: 100%;
				&.active {
					display: block;
				}
				a {
					display: block;
					padding: 10px;
					color: #000;
					border: 1px solid $main;
					line-height: 18px;
					font-size: 13px;
				}
			}
			&.open-sort {
				width: 150px;
				background: #fff;
				border: 1px solid $main;
				top: -6px;
				z-index: 1;
				&:after {
					right: 10px;
					top: 15px;
				}
				li {
					display: block;
					border-bottom: 1px solid $main;
					a {
						line-height: 20px;
						padding: 10px;
						width: 100%;
						border: 0;
					}
					&:last-child {
						border: 0;
					}
				}
			}
		}
	}
	.rocket-path-elastic-pager {
		@include breakpoint($tab) {
			padding: 8px 0;
			float: right;
		}
		label {
			color: $black;
			margin: 0;
			margin-right: 8px;
			font-weight: normal;
			line-height: 17px;
			font-size: 12px;
		}
		ul {
			display: inline-block;
			margin: 0;
			padding: 0;
			li {
				list-style-type: none;
				list-style-image: none;
				display: inline-block;
				margin: 0 5px;
				@include breakpoint($tab) {
					margin: 0 3px;
				}
				a {
					font-size: 20px;
					@include breakpoint($tab) {
						font-size: 16px;
					}
				}
				&.active-page a {
					color: #fff;
					background: $main;
				}
				&.previous-page,
				&.next-page {
					width: auto;
					font-size: 16px;
					display: inline-block;
					a {
						width: 100%;
						background: $main;
						border-radius: 0;
						padding-right: 20px;
						padding-left: 10px;
						color: #fff;
						line-height: 30px;
						display: block;
						height: auto;
						position: relative;
					}
				}
				&.next-page a {
					@extend .font-icon;
					@extend .mc-icon-arrow-right;
					&:before {
						position: absolute;
						top: 50%;
						transform: translatey(-50%);
						right: 5px;
						font-size: 10px;
					}
				}
				&.previous-page a {
					@extend .font-icon;
					@extend .mc-icon-arrow-left;
					padding-left: 20px;
					padding-right: 10px;
					&:before {
						position: absolute;
						top: 50%;
						transform: translatey(-50%);
						left: 5px;
						font-size: 10px;
					}
				}
				a {
					color: $black;
					font-size: 13px;
					border-radius: 50%;
					display: inline-block;
					width: 24px;
					line-height: 24px;
					text-align: center;
					height: 24px;
				}
			}
		}
	}
}

.rocket-path-elastic-sort-mobile {
	width: 100%;
	label {
		float: right;
		padding: 5px 10px;
		color: $black;
		border: 1px solid $border;
		line-height: 18px;
		font-size: 13px;
		width: 45%;
		display: inline-block;
		position: relative;
		&:before {
			content: '';
			font-size: 20px;
			top: 4px;
			right: 30px;
			position: absolute;
			line-height: 19px;
			width: 1px;
			height: 20px;
			display: block;
			background: $border;
		}
		&:after {
			content: url('../svg/triangle-down.svg');
			font-size: 20px;
			top: 50%;
			right: 10px;
			position: absolute;
			transform: translatey(-50%);
			line-height: 3px;
		}
	}
	.rocket-path-elastic-sort-overlay {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: none;
		z-index: 140;
		background: #fff;
		padding: 50px 0 0;
		margin: 0;
		.rocket-path-elastic-sort-overlay-close {
			top: 20px;
			right: 20px;
			position: absolute;
			&:before {
				@extend .font-icon;
				@extend .mc-icon-close;
				color: $black;
				font-size: 15px;
				line-height: 0;
			}
		}
		ul {
			margin: 0;
			padding: 0;
		}
		li {
			list-style: none;
			display: block;
			width: 100%;
			border-bottom: 1px solid $background;
			&:first-child {
				border-top: 1px solid $background;
			}
			&.active {
				a {
					text-decoration: underline;
				}
			}
			a {
				font-size: 14px;
				line-height: 39px;
				display: block;
				width: 100%;
				position: relative;
				color: $black;
				font-weight: 100;
				padding: 0 20px;
			}
		}
		&.open-sort {
			display: block;
		}
	}
}

.mobile-instant-search-results {
	.rocket-path-elastic-header {
		display: none;
	}
}

.rocket-path-elastic-breadcrumbs {
	@include pie-clearfix;
	padding: 4px 0 20px;
	.l-breadcrumb {
		margin: 0;
		li {
			line-height: 17px;
			margin-right: 0;
			padding-right: 0;
			&:after {
				position: relative;
				padding: 0 5px;
				font-size: 10px;
			}
		}
	}
}

.rocket-path-elastic-facets-active {
	float: left;
	@include pie-clearfix;
	font-size: 12px;
	label {
		float: left;
		display: inline-block;
		line-height: 32px;
	}
	ul {
		float: left;
		border-top: 1px solid $background;
		padding: 8px 20px;
		margin: 0;
		@include breakpoint($tab) {
			padding: 1px 0;
			border: 0;
		}
		li {
			list-style-type: none;
			list-style-image: none;
			line-height: 20px;
			position: relative;
			font-size: 12px;
			color: $main;
			display: inline-block;
			padding: 6px 22px 6px 9px;
			font-weight: 400;
			.remove-facet {
				display: block;
				text-decoration: none;
				width: 14px;
				height: 14px;
				background: $main;
				border-radius: 50%;
				position: absolute;
				top: 9px;
				right: 0;
				cursor: pointer;
				.line {
					display: block;
					width: 8px;
					height: 1px;
					background: #fff;
					position: absolute;
					top: 7px;
					margin: 0 auto;
					left: 0;
					right: 0;
					&:first-child {
						-ms-transform: rotate(45deg);
						-webkit-transform: rotate(45deg);
						transform: rotate(45deg);
					}
					&:last-child {
						-ms-transform: rotate(-45deg);
						-webkit-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
}

.rocket_path-elastic-total-active-clear-button {
	float: left;
	margin-left: 25px;
	a {
		line-height: 34px;
		font-size: 11px;
		color: $main;
		@include breakpoint($desk) {
			text-decoration: underline;
		}
	}
}

.rocket-path-elastic-facets {
	@include pie-clearfix;
	margin: 0 0 30px;
	@include breakpoint($tab) {
		clear: both;
		width: 25%;
		float: left;
		margin: 0;
		padding-right: 10px;
	}
	@include breakpoint($desk) {
		padding-right: 15px;
		position: relative;
	}
	.rocket-path-elastic-facets-inner {
		display: none;
		@include breakpoint($tab) {
			display: block;
		}
	}
	.rocket-path-elastic-facets-label {
		float: left;
		padding: 5px 10px;
		color: $black;
		border: 1px solid $border;
		line-height: 18px;
		font-size: 13px;
		width: 45%;
		position: relative;
		&:before {
			content: '';
			font-size: 20px;
			top: 4px;
			right: 30px;
			position: absolute;
			line-height: 19px;
			width: 1px;
			height: 20px;
			display: block;
			background: $border;
		}
		&:after {
			content: url('../svg/triangle-down.svg');
			font-size: 20px;
			top: 50%;
			right: 10px;
			position: absolute;
			transform: translatey(-50%);
			line-height: 3px;
		}
	}
	.rocket-path-elastic-facets-term-title {
		line-height: 40px;
		font-size: 16px;
		padding: 0 20px;
	}
	&.mobile-facets .rocket-path-elastic-facets-inner {
		position: fixed;
		display: block;
		height: 100%;
		z-index: 115;
		width: 100%;
		top: 0;
		left: 0;
		background: #fff;
		overflow: scroll;
		padding-bottom: 100px;
		.rocket-path-elastic-facets-mob-header {
			background: #fff;
			.rocket-path-elastic-facets-title {
				width: 100%;
				text-align: center;
				position: relative;
				font-size: 16px;
				color: $black;
				line-height: 57px;
				.close-filters {
					position: absolute;
					top: 50%;
					right: 20px;
					font-size: 0;
					color: $grey;
					line-height: 0;
					transform: translatey(-50%);
					&:before {
						content: url('../svg/close.svg');
						display: inline-block;
					}
				}
			}
		}
		.rocket-path-elastic-actions-button {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 20px 25px;
			text-align: center;
			background: #fff;
			border-top: 1px solid $border;

			.rocket_path-elastic-total-active-clear-button {
				line-height: 30px;
				border: 1px solid $border;
				width: 45%;
				display: inline-block;
				float: left;

				a {
					font-size: 15px;
					color: $border;
				}

				&.active {
					border: 1px solid $black;

					a {
						color: $black;
					}
				}
			}
			.rocket-path-elastic-facets-apply {
				line-height: 34px;
				border: 1px solid $black;
				width: 45%;
				display: inline-block;
				color: #fff;
				background: $black;
				float: right;
			}
		}
		.facet-wrapper:not(.facet-color) {
			width: 100%;
			float: none;
			margin: 0;
			&.facet-merge {
				.facet-content {
					margin-bottom: 0;
				}
			}
			.facet-title {
				padding: 10px 30px;
				width: 100%;
				margin: 0;
				background: #fff;
				border-top: 1px solid #d6d6d6;
				border-bottom: 1px solid #d6d6d6;
				color: $black; // text-align: center;
				font-weight: 500;
				line-height: 21px;
				font-size: 15px;
				&:before {
					// display: none;
					top: 50%;
					transform: translateY(-50%);
					margin: 0;
					right: 30px;
				}
			}
			.facet-content > .facet-ul-wrapper {
				overflow-y: auto;
			}
			ul {
				background: #fff;
				li {
					margin: 0;
					padding: 0 5px;
					&.checked {
						background: $background;
					}
				}
				a {
					padding: 0 20px;
					font-size: 14px;
					line-height: 39px;
					display: block;
					width: 100%;
					position: relative;
				}
			}
			&.open-facet .facet-ul-wrapper {
				display: block;
			}
		}
		.facet-wrapper.facet-color .facet-title {
			padding: 15px 30px;
			width: 100%;
			margin: 0;
			background: #fff;
			color: $black;
			text-align: center;
			border-bottom: 0;
			font-weight: 500;
			line-height: 21px;
			font-size: 15px;
			&:before {
				display: none;
			}
		}
	}
	.facet-wrapper.facet-price {
		display: none;
	}
	.facet-wrapper {
		clear: both;
		display: block;
		width: 100%;
		margin-bottom: 30px;
		.facet-content {
			display: block;
			.facet-ul-wrapper {
				padding: 0 15px;
				max-height: 200px;
				overflow: scroll;
			}
		}
		p {
			font: inherit;
		}
		ul {
			margin: 0;
			padding: 0;
			li {
				line-height: 18px;
				list-style-type: none;
				list-style-image: none;
				font-size: 14px;
				ul {
					padding-top: 5px;
					padding-left: 15px;
					position: relative;
				}
				a {
					line-height: 13px;
					padding: 5px 0;
					color: #414141;
					font-size: 14px;
					@extend .thin-font;
					display: inline-block;
					width: 100%;
					position: relative;
					text-decoration: none;
				}
				&.active-path > a {
					font-weight: 400;
					color: $main;
				}
				&.checked > a {
					font-weight: 400;
					color: $main;
				}
				&.zero-results a {
					cursor: default;
					color: #C4C4C4;
				}
				.facet-count {
					position: absolute;
					right: 0;
					color: #ccc;
				}
			}
		}
		&.facet-category {
			ul li {
				ul {
					padding-left: 10px;
				}
				a {
					padding-right: 30px;
				}
			}
		} // With checkbox
		&.facet-cupsize,
		&.facet-gender,
		&.facet-capacity,
		&.facet-season,
		&.facet-gender,
		&.facet-brand,
		&.facet-size,
		&.facet-collection,
		&.facet-reclination,
		&.facet-sleeve,
		&.facet-trouser_length,
		&.facet-waist_type,
		&.facet-carrier_positions,
		&.facet-character,
		&.facet-group_car_seats,
		&.facet-isize,
		&.facet-isofix,
		&.facet-nipple_synthesis,
		&.facet-package,
		&.facet-number_of_players,
		&.facet-age  {
			ul li {
				a {
					padding: 5px 25px;
					&:after {
						content: '';
						display: inline-block;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						width: 11px;
						height: 11px;
						margin-right: 6px;
						border: 1px solid #b3b3b3;
						border-radius: 2px;
						font-size: 9px;
						line-height: 12px;
						text-align: center;
						vertical-align: top;
					}
				}
				&.checked > a {
					&:after {
						content: '';
						display: inline-block;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						width: 11px;
						height: 11px;
						margin-right: 6px;
						border: 1px solid $main;
						border-radius: 2px;
						font-size: 9px;
						line-height: 12px;
						text-align: center;
						vertical-align: top;
					}
					&:before {
						@extend .font-icon;
						@extend .mc-icon-tick;
						color: $main;
						position: absolute;
						left: 2px;
						font-size: 10px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
		&.facet-color {
			.facet-ul-wrapper {
				max-height: none;
			}
			ul {
				@include pie-clearfix;
				li {
					// margin: 4px 2px;
					display: inline-block;
					float: left;
					text-align: center;
					margin: 0 0 9px 9px;
					padding: 4px;
					border: 1px solid #fff;
					a {
						display: block;
						width: 100%;
						height: 100%;
						width: 24px;
						height: 24px;
						font-size: 0;
						border: 1px solid #b3b3b3;
					}
					&.checked,
					&:hover {
						border-color: #d6d6d6;
					}
					&.zero-results a {
						cursor: default;
						color: #C4C4C4;
						border: 1px solid #C4C4C4;
					}
					.facet-count {
						display: none;
					}
				}
			}
		}
	}
	.facet-title {
		display: block;
		margin-bottom: 10px;
		padding: 12px 15px;
		background: #f6f6f6;
		color: #888;
		cursor: pointer;
		font-size: 13px;
		line-height: 13px;
		font-weight: 400;
		position: relative;
		&:before {
			@extend .font-icon;
			@extend .mc-icon-minus;
			display: block;
			position: absolute;
			right: 15px;
			margin-right: 2px;
			top: 10px;
			color: #888;
			font-size: 16px
		}
	}
	.facet-wrapper.close-facet {
		margin: 0;
		.facet-ul-wrapper {
			display: none;
		}
		.facet-title {
			position: relative;
			border-top: none;
			margin-bottom: 20px;
			&:before {
				@extend .font-icon;
				@extend .mc-icon-plus;
				font-size: 12px;
			}
		}
	}
	.facet-wrapper.close {
		margin-bottom: 10px;
		.facet-title {
			position: relative;
			cursor: pointer;
			margin: 0;
			border-bottom: 0;
			&:before {
				@extend .font-icon;
				@extend .mc-icon-plus;
				display: block;
				position: absolute;
				right: 15px;
				top: 10px;
				color: #888;
				margin-right: 2px;
				font-size: 16px
			}
		}
		.facet-content {
			display: none;
		}
	}
}

body.with-overlay {
	overflow: hidden;
}

//Search page
.search-overlay {
	display: none !important;
	position: absolute;
	overflow-y: scroll;
	background: #fff;
	z-index: 1000;
	width: calc(100% + 2px);
	max-height: 250px;
	left: -1px;
	z-index: 1000;
	border: 1px solid #d6d6d6;
	border-top: 0;
	padding: 30px 20px;
	top: 143px;
	@include breakpoint($desk) {
		top: 39px;
	}
	&.show {
		display: block !important;
	}
	.rocket-path-elastic-instant-search-wrapper {
		// padding: 27px 50px 0;
		.rocket-path-elastic-instant-search-results-wrapper {
			@include pie-clearfix;
			position: relative;
			display: block;
			&:before {
				position: absolute;
				top: 0;
				bottom: 10px;
				left: 50%;
				width: 1px;
				background-color: #d6d6d6;
				content: "";
			}
		}
		.instant-product-wrapper,
		.instant-taxonomy-wrapper {
			float: left;
			width: 50%;
		}
		.instant-taxonomy-wrapper {
			text-align: left;
			padding-right: 10px;
			.vocabulary-wrapper {
				margin-bottom: 20px;
			}
			h4,
			h4 a {
				margin: 0;
				margin-bottom: 15px;
				color: $main;
				font-size: 14px;
			}
			a {
				display: block;
				color: #414141;
				font-size: 12px;
				margin-bottom: 10px;
			}
		}
		.instant-product-wrapper {
			padding-left: 20px;
			.grid__item {
				width: 100%;
				padding: 0;
				margin-bottom: 15px;
				text-align: left;
				.product-teaser {
					@include pie-clearfix;
					padding: 0;
					display: block;
					.image-wrapper {
						float: left;
						width: 30%;
						padding-right: 10px;
					}
					.product-teaser-info-wrapper {
						float: left;
						width: 70%;
						text-align: left;
						.product-teaser-title-wrapper {
							margin: 10px 0 5px;
							height: auto;
						}
					}
					.image-wrapper a {
						max-width: 60px;
					}
					.product-actions {
						display: none;
					}
				}
			}
		}
		.rocket-path-elastic-pager {
			display: none;
		}
		.rocket-path-elastic-instant-logo {
			width: 30%;
			display: inline-block;
			float: left;
			margin-top: 4px;
			@include breakpoint($desk) {
				width: 240px;
				margin-right: 75px;
				margin-top: 0;
			}
			img {
				width: 100%;
				height: auto;
				max-width: 306px;
			}
		}
		.search-input-wrapper {
			width: 50%;
			display: inline;
			position: relative;
			float: left;
			margin: 0 50px;
			border-bottom: 1px solid $black;
			@include breakpoint($desk) {
				margin: 0;
			}
			@include breakpoint($wide) {
				max-width: 800px;
				margin-top: 5px;
			}
			.form-text {
				background: #fff;
				padding: 7px 0 15px;
				line-height: 16px;
				font-size: 14px;
				border: 0;
			}
			&:before {
				@extend .font-icon; // @extend .icon-search;
				color: $black;
				font-size: 20px;
				top: 15px;
				right: 0;
				position: absolute;
				line-height: 0;
			}
		}
		.rocket-path-elastic-instant-search-close {
			float: right;
			display: inline-block;
			position: relative;
			font-size: 0;
			line-height: 0px;
			top: 10px;
			&:before {
				@extend .font-icon; // @extend .icon-cross;
				color: $black;
				font-size: 30px;
				top: 15px;
				right: 0;
				position: absolute;
				line-height: 0;
			}
		}
		.rocket-path-elastic-instant-search-results .rocket-path-elastic-results {
			float: none;
			clear: both;
			margin: 0 auto;
			max-width: 1020px;
			padding: 20px;
			width: 100%;
			.products-grid {
				overflow: hidden;
				@include pie-clearfix;
				border: 1px solid $background;
				border-top: 0;
				.grid-1-3 {
					border-top: 1px solid $background;
					margin-bottom: 50px;
					position: relative;
					&:after {
						width: 1px;
						height: 150%;
						top: 0;
						right: -1px;
						content: '';
						background: $background;
						display: block;
						position: absolute;
					}
					.image-wrapper {
						border: 0;
					}
				}
			}
		}
	}
}

.term-body {
	margin: 50px 0;
}

.term-seo-block {
	color: #959595;
	max-width: 960px;
	margin: 20px auto 0;
	h2 {
		color: #959595;
		margin: 0 0 15px;
	}
}

.rocket-path-elastic-footer {
	border-top: 1px solid $border;
	border-bottom: 1px solid $border;
	padding: 15px 0;
	text-align: center;
	@include breakpoint($tab) {
		padding: 15px 0 15px 0;
		text-align: left;
	}
}

.b-refinement-count {
	opacity: 0.5;
}

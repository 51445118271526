.user-block {
  position: relative;
  display: none;
  @include breakpoint($desk) {
    display: inline-block;
  }
  a.user-button {
    font-size: 17px;
    color: $black;
    font-family: $second_font;
  }
  .user-block-wrapper {
    line-height: 40px;
  }
  &.open-user-block {
    .options-wrapper {
      display: block;
    }
  }
  .options-wrapper {
    display: none;
    position: absolute;
    top: 30px;
    right: -10px;
    z-index: 121;
    padding: 10px 0 0 0;
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style: none;
      background: #fff;
      padding: 0;
      width: 180px;
      border: 1px solid $background;
      border-bottom: 0;
      &:last-child {
        border-bottom: 1px solid $background;
      }
      a {
        text-align: left;
        display: block;
        width: 100%;
        line-height: 40px;
        font-size: 14px;
        padding: 0 20px 0 40px;
        position: relative;
        color: $black;
        &:hover {
          background: $black;
          color: #fff;
          text-decoration: none;
          &:before {
            color: #fff;
          }
          .fa-sign-out,
          .fa-pencil {
            color: #fff;
          }
        }
        &:before {
          @extend .font-icon;
          position: absolute;
          color: #767676;
          top: 10px;
          left: 11px;
          font-size: 14px;
          line-height: 21px;
          transition: all .3s;
          left: 10px;
        }
        &.wishlist:before {
          @extend .mc-icon-heart;
        }
        &.my-orders:before {
          @extend .mc-icon-minicart;
        }
        .fa-sign-out,
        .fa-pencil {
          position: absolute;
          color: #767676;
          top: 10px;
          font-size: 14px;
          line-height: 21px;
          transition: all .3s;
          left: 13px;
        }
      }
    }
  }
}

.page-user.not-logged-in {
  h1 {
    color: $main;
  }
  .l-content {
    margin-bottom: 60px;
    form {
      max-width: 570px;
      margin: 40px auto;
      border: 1px solid #d6d6d6;
      padding: 22px 24px;
      position: relative;
    }
  }
}

.page-user.logged-in {
  h1 {
    color: $main;
  }
  .l-content .main-content-region > .user-profile-form {
    max-width: 570px;
    margin: 40px auto;
    border: 1px solid #d6d6d6;
    padding: 22px 24px;
    position: relative;
  }
}

.page-user .l-content {
  .user-forms-wrapper {
    @include pie-clearfix;
    input[type="text"],
    input[type="email"],
    input[type="password"],
    textarea {
      font-size: 18px;
      line-height: 18px;
      color: #404040;
      font-weight: 500;
      font: inherit;
      letter-spacing: 0.5px;
    }
    .user-register-right {
      width: 50%;
      padding: 0 10px;
      float: right;
      form {
        margin: 0 0 20px;
      }
    }
    .user-login-left {
      width: 50%;
      padding: 0 10px;
      float: left;
    }
    h3 {
      @extend .thin-font;
      margin: 0 0 20px;
      color: $main;
    }
    .user-register-right-wrapper {
      border: 1px solid #d6d6d6;
      margin: 40px auto 20px;
      .or {
        text-align: center;
        margin: 18px 24px;
        position: relative;
        &:after,
        &:before {
          content: "";
          position: absolute;
          height: 1px;
          background: #C4C4C4;
          width: calc(50% - 50px);
          top: 60%;
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }
        span {
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          color: #404040;
          display: inline-block;
        }
      }
      form {
        border: 0;
      }
    }
    .user-register-with-card {
      padding: 22px 24px;
      margin: 0;
      border: 0;
      form {
        margin: 0;
        border: 0;
        padding: 0;
        input[type="submit"] {
          border: none;
          margin-top: 10px;
          text-transform: none;
          background: #ff6501;
          color: #fff;
          min-width: 200px;
          height: 40px;
          padding: 0 40px;
          font-size: 18px;
          line-height: 37px;
          font-weight: 500;
          width: 100%;
          display: block;
          &:hover {
            background: #ff7922;
          }
        }
      }
    }
  }
}

.user-register-with-card {
  .b-register-banner {
    position: relative;
    margin-bottom: 15px;
  }
  .b-register-text {
    position: absolute;
    top: 50%;
    left: 30px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: left;
    max-width: 270px;
    color: #fff;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
  }
  .info {
    @extend .thin-font;
    margin-bottom: 20px;
    display: block;
    font-size: 15px;
    line-height: 18px;
    color: #9F9F9F;
    max-width: 380px;
  }
  .form-item-mobile-prefix {
    width: 30%;
    float: left;
    padding-right: 20px;
  }
  .form-item-mobile-validation {
    width: 70%;
    float: left;
  }
}

.commerce-checkout-form-shipping,
.page-user .l-content .user-forms-wrapper .user-register-right {
  .group-contact-methods {
    @include pie-clearfix;
    border-top: 0;
    border-bottom: 0;
    padding: 20px 0 0;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    h3 {
      color: #000000;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      font-family: inherit;
      margin-bottom: 15px !important;
      display: block;
      width: 100%;
    }
    .accept-all-methods-check {
      position: relative;
      padding-left: 20px;
      font-size: 15px;
      line-height: 15px;
      // font-family: 'futura-plus-thin', sans-serif;
      font-family: $default_font;
      font-weight: 300;
      cursor: pointer;
      -webkit-font-smoothing: subpixel-antialiased;
      &:before {
        width: 12px;
        height: 12px;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        display: block;
        content: "";
        border: 1px solid #414141;
        position: absolute;
        top: 2px;
        border-radius: 2px;
        left: 0;
      }
      &.checked:after {
        width: 8px;
        height: 8px;
        border-radius: 2px;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        display: block;
        content: "";
        background: #003971;
        position: absolute;
        top: 4px;
        left: 2px;
        border: 1px solid #414141;
      }
    }
    .field-type-list-boolean {
      width: 100%;
      flex-basis: 100%;
      @include breakpoint($tab) {
        width: 50%;
        flex-basis: 50%;
      }
      @include breakpoint($desk) {
        width: 25%;
        flex-basis: 25%;
      }
    }
  }
  .field-name-field-gdpr-profiling-sw .form-item {
    margin-bottom: 10px;
  }
}

#edit-picture,
.user-profile {
  .user-picture {
    width: 100px;
    height: 100px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.ms-products-user-purchase-options-form {
  margin: 40px 0;
}

.user-profile {
  position: relative;
  .account-settings-links {
    display: inline-block;
    top: 20px;
    width: 140px;
    text-align: center;
    right: 0;
    padding: 0 20px;
    line-height: 40px;
    background: $grey;
    color: #fff;
    margin-bottom: 10px;
    @include breakpoint($desk) {
      position: absolute;
      margin: 0;
    }
    &:hover {
      background: $black;
    }
    &.memberships-link {
      top: 70px;
    }
  }
  .field {
    display: inline-block;
    margin-right: 40px;
    font-size: 18px;
    .field__label {
      font-size: 13px;
      color: $grey;
    }
  }
  .user-profile-category-history {
    h2 {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.user-profile-form {
  // max-width: 540px;
  // margin: 0 auto;
  fieldset.date-combo .container-inline-date .date-padding {
    padding: 0;
  }
  .field-type-datetime {
    legend {
      color: $grey;
      font-size: 12px;
    }
  }
}

.form-item-mail {
  position: relative;
}

.forgot-password {
  margin-bottom: 20px;
  font-size: 12px;
  text-align: left;
}

.info-tooltip {
  position: absolute;
  z-index: 100;
  bottom: 9px;
  right: 11px;
  display: table;
  .info-icon {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 22px;
    text-align: center;
    background: $grey;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    color: #fff;
  }
  .info-content {
    position: absolute;
    display: none;
    top: 22px;
    right: 0;
    min-width: 250px;
    max-width: 360px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    background: #f6f9fc;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    @include breakpoint($tab) {
      min-width: 360px;
    }
  }
  &:hover .info-content {
    display: block;
  }
}

.information-text {
  font-size: 12px;
  line-height: 16px;
  margin: 20px auto 0;
  max-width: 405px;
}

.sign-in-link,
.sign-up-link {
  background: $grey;
  line-height: 20px;
  max-width: 405px;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  &.sign-in-link-dark {
    background: $blue;
    color: #fff;
  }
  label {
    margin: 0;
  }
  a {
    text-decoration: underline;
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    margin-left: 30px;
  }
}

.user-register-form {
  .register-banner-container {
    margin-bottom: 20px;
    .b-register-banner {
      position: relative;
      margin-bottom: 15px;
    }
    .b-register-text {
      position: absolute;
      top: 50%;
      left: 30px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: left;
      max-width: 270px;
      color: #fff;
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
    }
    .info {
      @extend .thin-font; // margin-bottom: 20px;
      display: block;
      font-size: 15px;
      line-height: 18px;
      color: #9F9F9F;
      max-width: 380px;
    }
  }
  .b-login_box-specials {
    border-top: 1px solid #d6d6d6;
    margin: 13px -24px 0;
    padding: 20px 24px 0;
    .tiny-text {
      font-size: 12px;
      letter-spacing: -.1px;
    }
    .tooltip-container {
      display: inline-block;
      position: relative;
      height: 12px;
      width: 14px;
      margin-left: 5px;
      .tooltip-spot {
        color: #fff;
        height: 12px;
        position: relative;
        width: 14px;
        cursor: pointer;
        &:after {
          background-color: #003971;
          border-radius: 7px;
          bottom: 0;
          content: "i";
          cursor: pointer;
          display: block;
          font-size: 11px;
          font-weight: 400;
          height: 14px;
          line-height: 14px;
          position: absolute;
          text-align: center;
          top: 0;
          width: 14px;
        }
      }
      .tooltip-context {
        background-color: #003971;
        border-radius: 5px;
        bottom: 25px;
        color: #fff;
        display: none;
        font-size: 11px;
        left: -134px;
        line-height: 14px;
        padding: 10px;
        position: absolute;
        width: 276px;
        z-index: 1;
        &:after {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #003971;
          bottom: -6px;
          content: "";
          height: 0;
          left: 135px;
          position: absolute;
          width: 0;
        }
        &.active {
          display: block;
        }
      }
    }
    p {
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 20px;
    }
    p.bold {
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
  .field-name-field-newsletter {
    border-bottom: 1px solid #d6d6d6;
    margin: 0 -24px 20px;
    padding: 20px 24px;
    .form-item {
      @include pie-clearfix;
      margin: 0;
      label {
        font-weight: 400;
        float: left;
        &:before {
          top: 3px;
        }
      }
      .description {
        font-weight: 400;
        width: calc(100% - 115px);
        float: left;
        clear: none;
        margin-left: 13px;
        line-height: 17px;
        margin-top: 0;
        padding-top: 1px;
      }
    }
  }
  .box-policy {
    font-size: 12px;
    line-height: 18px;
    margin: 20px 0 25px;
    a {
      color: #003971;
      display: inline-block;
      font-weight: 400;
      text-decoration: underline;
    }
  }
}

form.user-login {
  .b-login-banner {
    margin: 0 0 15px;
    color: #414141;
    position: relative;
  }
  .b-signin-text {
    position: absolute;
    top: 50%;
    left: 30px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: auto;
    text-align: left;
    max-width: 250px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
  }
  .info {
    @extend .thin-font;
    margin-bottom: 20px;
    display: block;
    font-size: 15px;
    line-height: 18px;
    color: #9F9F9F;
    max-width: 380px;
  }
}

.user-register-form,
.user-login,
.user-pass {
  .forgot-password a {
    @extend .thin-font;
    color: #003971;
    font-size: 15px;
    line-height: 18px;
    text-decoration: underline;
  }
  .form-actions {
    text-align: left;
    input[type="submit"] {
      border: none;
      margin-top: 10px;
      text-transform: none;
      background: #ff6501;
      color: #fff;
      min-width: 200px;
      height: 40px;
      padding: 0 40px;
      font-size: 18px;
      line-height: 37px;
      font-weight: 500;
      &:hover {
        background: #ff7922;
      }
    }
  }
}

// .password-confirm,
.password-strength {
  display: none;
}

.accept-all-methods-check {
  margin-bottom: 20px;
}

.logged-in.page-user h1 {
  display: none;
}

.order-tracking-form-wrapper {
  border: 1px solid #d6d6d6;

  h2 {
    border-bottom: 1px solid #d6d6d6;
    padding: 30px;
    margin: 0;
    color: #003971;
    font-family: "futura-plus";
    font-size: 26px;
    line-height: 20px;
  }

  .order-tracking-form-inner {
    padding: 30px;

    p {
      margin-bottom: 40px;
      font-size: 13px;
      line-height: 20px;
    }
  }

  .form-item {
    max-width: 325px;
    width: 100%;

    label {
      font-family: "futura-plus";
      color: #414141;
      line-height: 20px;
      font-size: 14px;
    }

    &.form-item-order-number input {
      max-width: 90px;
    }
  }

  .form-submit {
    max-width: 325px;
    width: 100%;
    background: #ff6501;
    border: 1px solid #ff6501;
  }
}
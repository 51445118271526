.cart-block {
	float: right;
	position: inherit;

	// @include breakpoint($tab) {
 //    margin: 0;

 //    @include breakpoint($desk) {
 //  		margin: 0 15px;
 //    }
	// }

  body.cart-block-active & {
    #cart-wrapper-total:after {
      content: '';
      display: block;
      width: 42px;
      height: 42px;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.59);
      background-color: rgba(255, 255, 255, 1);
      background-image: url(../svg/close-black.svg);
      background-size: 15px;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
      top: 0;
      right: 0;

      @include breakpoint($tab) {
        width: 0;
        height: 0;
      }
    }

    .cart-button {
      color: $blue;
    }
  }

	.cart-wrapper {
		position: relative;
		line-height: 21px;
		top: 0;
    text-align: right;

		@include breakpoint($desk) {
			line-height: 25px;
		}

		.cart-button {
			font-size: 0;
      line-height: 0;
      text-align: right;
      margin-bottom: 10px;
      font-size: 13px;
      transition: all .3s ease;
      letter-spacing: .8px;
      text-align: left;
      color: $main;
      line-height: 10px !important;
      display: none;

      @include breakpoint($desk) {
        display: block;
      }
    }
	}

	#cart-wrapper-total {
    color: $main;
    font-size: 10px;
    line-height: 13px;
    display: block;
    padding: 0 5px;

    @include breakpoint($desk) {
		  font-size: 18px;
      line-height: 20px;
      display: inline;
      padding: 0;
    }
	}

	#cart-wrapper-items {
		position: absolute;
    width: 19px;
    height: 19px;
    text-align: center;
    background: $blue;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    top: 0;
    right: 0;
    line-height: 19px;
    font-size: 10px;

  @include breakpoint($tab) {
    padding: 0;
    position: relative;
    top: inherit;
    right: inherit;
    left: inherit;
    width: auto;
    height: auto;
    background: none;
    border-radius: 0;
    color: rgb(136, 136, 136);

    @include breakpoint($desk) {
      font-size: 12px;
      line-height: 13px;
      font-family: mc-thin, sans-serif;
      font-weight: 300;
      text-align: center;
      margin-right: 15px;
      float: left;
      margin-top: 8px;
    }
  }

    span {
      padding-left: 5px;
      display: none;

      @include breakpoint($tab) {
        display: inline;
      }

      // &.bold {
      //   padding-left: 5px;
      // }
    }
	}

	#cart-wrapper {
    display: none;
    z-index: 1499;
    right: 0;
    width: 100%;
    max-width: 375px;
    height: auto;
    overflow: auto;
    text-align: center;
    background: #fff;
    // padding-top: 20px;
    position: fixed;
    top: 82px;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    -webkit-animation: fade-out .3s 1 ease-in;
    animation: fade-out .3s 1 ease-in;
    height: 100%;

    @include breakpoint($tab) {
      border: 1px solid #d6d6d6;
      width: 325px;
      max-width: 325px;
      position: absolute;
      top: 55px;
      padding-top: 0;
      height: auto;
    }

    .cart-empty-block {
      margin-bottom: 20px;
      padding: 0 20px;
      text-align: center;
    }

    .cart-title {
      font-size: 14px;
      color: $main;
      margin: 0 0 20px;
      padding: 0 20px;
      text-align: center;
    }
    .views-table {
      margin-top: 0;
    }

    .views-field.views-field-quantity {
      display: none;
    }

    .prices-wrapper {
      font-weight: 400;
      float: left;
      margin-top: 4px;
      text-align: left;

      & > * {
        line-height: 18px;
        display: inline-block;
      }

      .final-price {
        font-size: 16px;

        &.red-price {
          color: $red;
        }
      }

      .first-price {
        font-size: 15px;
        -webkit-text-decoration-line: strikethrough;
        text-decoration-line: strikethrough;
        text-decoration: line-through;
      }
    }

    .views-field-edit-delete {
      float: right;
      margin-top: 5px;
      opacity: 0.7;

      &:hover {
        opacity: 0.9;
      }

      span {
        position: relative;

        &:before {
          @extend .font-icon;
          @extend .mc-icon-close;
          color: $red;
          position: absolute;
          top: 0;
          left: -3px;
          z-index: -2;
        }
      }

      input {
        width: 25px;
        height: 25px;
        display: block;
        font-size: 0;
        background: transparent;
        border: 0;
        position: relative;
        z-index: 1;
      }
    }

    .title-image-wrapper {
      text-align: left;

      .image-wrapper {
        max-width: 50px;
        margin-right: 20px;
        float: left;
        display: inline-block;
        position: relative;

        img {
          width: 100%;
          height: auto;
        }

        span.quantity {
          position: absolute;
          font-size: 12px;
          display: block;
          right: -10px;
          background: $blue;
          border-radius: 50%;
          color: #fff;
          width: 22px;
          height: 22px;
          text-align: center;
          line-height: 22px;
          top: -10px;
        }
      }

      .title-wrapper {
        width: calc(100% - 70px);
        display: inline-block;
        float: left;
        text-align: left;
        margin-top: 15px;
      }

      a {
        font-size: 12px;
        text-align: left;
        color: $black;
        font-weight: 300;

        @include breakpoint($tab) {
          font-size: 13px;
        }
      }
    }

    body.cart-block-active &.has-items {
      display: block;
      -webkit-animation: fade-in .2s 1 ease-in;
      animation: fade-in .2s 1 ease-in;
    }

    .view-content {
      .views-row {
        @include pie-clearfix;
      }

      .views-table td:last-child {
        padding-right: 15px;
      }

      .commerce-price-formatted-components {
        margin: 0;

        tr {
          background: none;
        }

        .component-title {
          display: none;
        }
      }
    }

    .cart-contents {
      padding: 0 45px 25px;

      .preorder-message {
        font-size: 11px;
        color: #ff6501;
        margin-top: 10px;
        display: block;
      }
    }

    .mini-cart-footer-info {
      color: $grey;
      font-size: 15px;
      line-height: 19px;
      font-family: $second_font;
      margin-top: 15px;
    }


    .line-item-summary {
      margin: 0;
      padding: 10px 0 0;
      clear: both;

      .line-item-quantity {
        display: none;
      }

      .line-item-total {
        .line-item-total-label {
          font-weight: 300;
        }
        .line-item-total-raw {
          font-weight: 400;
        }
      }
    }

    ul.links {
      text-align: center;
      margin-top: 10px;

      li {
        float: none;
        margin: 0 5px;

        &:first-child {
          display: none;
        }

        @include breakpoint($tab) {
          margin: 0;
        }
      }

      a {
        background: $blue;
        color: #fff;
        width: 100%;
        display: inline-block;
        padding: 0 20px;
        line-height: 43px;
        font-size: 16px;
        border: 1px solid $blue;
        outline: none;

        &:hover {
          transition: all .2s;
          background: transparent;
          color: $blue;
        }
      }
    }
  }
}

.cart-block-active-mobile {
  overflow: hidden;
}

.cart-block-active {
  .l-header {
    .active-cart-overlay {
      display: block;
      background: rgba(228, 228, 228, 0.6);
      top: 100%;
      left: 0;
      width: 100%;
      height: 1000px;
      position: absolute;
      z-index: 1;

      @include breakpoint($tab) {
        display: none;
      }
    }
  }
}

@keyframes run {
	0% { left: 60%;}
	100% { left: 30%;}
}

@keyframes spin {
	0%  {
		-webkit-transform: translate(-50%,-50%) rotate(0deg);
		transform: translate(-50%,-50%) rotate(0deg);
	}
	100% {
		-webkit-transform: translate(-50%,-50%) rotate(360deg);
		transform: translate(-50%,-50%) rotate(360deg);
	}
}
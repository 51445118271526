.view-open-jobs {
  // max-width: 960px;
  margin: 0 auto;

  .view-header {
    @include pie-clearfix;

    .opens-jobs-banner {
      background-image: url('../images/hr_background.jpg');
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      margin-bottom: 20px;
      background-size: contain;
      height: 75px;

      @include breakpoint($tab) {
        height: 165px;

        @include breakpoint($desk) {
          background-size: cover;
          height: 277px;
        }
      }
    }

    p {
      line-height: 20px;
      font-size: 16px;
      margin-bottom: 20px;
    }

    span {
      font-size: 18px;
      color: $main;
      margin-bottom: 20px;
      display: block;
    }
  }

  .view-footer {
    margin-top: 20px;
    font-size: 16px;

    p {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    ul {
      font-size: 16px;
      margin-bottom: 5px;
      list-style: initial;
      padding-left: 18px;

      li {
        margin-bottom: 5px;
      }
    }

    #open-job-apply-general {
      font-size: 18px;
      color: $main;
      margin-top: 10px;
      cursor: pointer;
      display: block;
    }

  }

  .view-empty p {
    font-size: 18px;
    color: $main;
  }

  .views-row {
    @include pie-clearfix;
    padding: 30px 10px;
    position: relative;

    &.views-row-odd {
      background-color: #eaf0f9;
    }

    .node--open-jobs--teaser {
      @include pie-clearfix;
    }

    .top-wrapper {
      @include pie-clearfix;

      .logo-wrapper {
        padding: 0 20px;
        max-width: 200px;
        margin-bottom: 10px;

        @include breakpoint($tab) {
          margin: 0;
          float: left;
          width: 15%;
        }
      }

      .title-wrapper {
        padding: 0 20px;
        margin-bottom: 10px;

        @include breakpoint($tab) {
          margin: 0;
          float: left;
          width: 35%;
        }

        h2 {
          color: #122F9E;
          font-size: 20px;
          margin: 0;
        }
      }

      .details {
        padding: 0 20px;
        margin-bottom: 10px;

        @include breakpoint($tab) {
          margin: 0;
          float: left;
          width: 25%;
        }

        table,
        tr,
        td {
          margin: 0;
          border: 0;
          padding: 0;
          height: auto;
          line-height: 22px;
        }

        td {
          padding: 0;
          text-align: left;
          color: $main;
        }
      }

      .view-wrapper {
        margin-bottom: 10px;
        padding: 0 20px;

        @include breakpoint($tab) {
          margin: 0;
          float: left;
          width: 25%;
        }

        .view {
          position: relative;
          display: block;
          color: white;
          line-height: 14px;
          padding: 10px 5px;
          text-align: center;
          font-weight: bold;
          text-transform: uppercase;
          margin-top: 0;
          cursor: pointer;
          border-radius: 3px;
          background: #004dab;
          background: -moz-linear-gradient(top, #004dab 0%, #00387b 100%);
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #004dab), color-stop(100%, #00387b));
          background: -webkit-linear-gradient(top, #004dab 0%, #00387b 100%);
          background: -o-linear-gradient(top, #004dab 0%, #00387b 100%);
          background: -ms-linear-gradient(top, #004dab 0%, #00387b 100%);
          background: linear-gradient(to bottom, #004dab 0%, #00387b 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#004dab', endColorstr='#00387b', GradientType=0);
          -webkit-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.6);
          -moz-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.6);
          box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.6);
        }
      }
    }

    .inner-wrapper {
      display: none;
      padding: 0 20px;
      color: $main;
      padding: 30px 20px 10px;

      &.open {
        display: block;
        position: relative;
      }

      p {
        font-size: 16px;
      }

      .apply {
        clear: both;
        margin-top: 40px;
        max-width: 160px;
        position: relative;
        display: block;
        color: white;
        line-height: 14px;
        padding: 10px 5px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 3px;
        background: #004dab;
        background: -moz-linear-gradient(top, #004dab 0%, #00387b 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #004dab), color-stop(100%, #00387b));
        background: -webkit-linear-gradient(top, #004dab 0%, #00387b 100%);
        background: -o-linear-gradient(top, #004dab 0%, #00387b 100%);
        background: -ms-linear-gradient(top, #004dab 0%, #00387b 100%);
        background: linear-gradient(to bottom, #004dab 0%, #00387b 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#004dab', endColorstr='#00387b', GradientType=0);
        -webkit-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.6);
        -moz-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.6);
        box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.open-job-popup {
  background: #fff;
  padding: 35px 1em;
  width: 95%;
  height: 95%;
  top: 10px;
  right: 0;
  left: 0;
  display: block;
  z-index: 101;
  position: fixed;
  overflow: auto;
  z-index: 499;
  margin: 0 auto;
  display: none;

  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
    background: none;
    line-height: 20px;
    opacity: 1;
    padding: 0;
    &:before {
      @extend .font-icon;
      @extend .mc-icon-close;
      color: $main;
      font-size: 22px;
    }
  }

  @include breakpoint($tab) {
    height: calc(100% - 130px);
    max-width: 920px;
    top: 100px;
  }

  & > .block__title {
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
    font-size: 25px;
    color: $main;
  }

  & > .block__content {
    max-width: 500px;
    margin: 0 auto;
  }

  &.open {
    display: block;
    overflow-x: hidden;
    .b-sizing_guide,
    table {
      max-width: 100%;
      overflow-x: auto;
      display: block;
    }
  }
  .ui-widget-content {
    border: 0;
  }
  .size-guide-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    &:before {
      @extend .font-icon;
      @extend .mc-icon-close;
      font-size: 15px;
      color: $main;
      top: 10px;
      right: 10px;
      position: absolute;
      &:hover {
        @include breakpoint($tab) {
          color: #0085ca;
        }
      }
    }
  }

  .open-jobs-terms {
    position: relative;

    &:after {
      content: "*";
      display: block;
      color: red;
      position: absolute;
      top: 5px;
      left: 0;
    }
  }

  .webform-component--resume {
    input[type=submit] {
      padding: 7px 25px 8px;
      font-size: 14px;
      line-height: 16px;
    }

    .file-icon {
      width: 15px;
    }
  }
}

* {
	box-sizing: border-box;
}

::selection {
  color: #fff;
  background: $main;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $main;
}

.clear-both {
	clear: both;
}

.thin-font {
	// font-family: 'futura-plus-thin', sans-serif;
  font-family: $default_font;
	font-weight: 300;
	-webkit-font-smoothing: subpixel-antialiased;
}

.regular-font {
	font-family: $default_font;
	-webkit-font-smoothing: subpixel-antialiased;
	font-weight: 400;
	letter-spacing: 0;
}

.marydale-font {
  font-family: "marydale",sans-serif;
  font-weight: 400;
}

body {
	font-family: $default_font;
	color: $black;
	-webkit-font-smoothing: subpixel-antialiased;
	font-size: 15px;
	font-weight: 400;
}

h1 {
	text-align: center;
	margin: 25px 0;
	font-size: 32px;
  font-family: $default_font;
}

h2 {
	font-size: 22px;
  font-family: $default_font;
  margin: 22px 0;
}

h3 {
	font-size: 18px;
  font-family: $default_font;
  margin: 20px 0;
}

h4 {
	font-size: 16px;
	font-family: $default_font;
	margin: 18px 0;
}

h5 {
	font-size: 14px;
	font-family: $default_font;
	margin: 16px 0;
}

h6 {
	font-size: 12px;
	margin: 14px 0;
}

a {
	text-decoration: none;
	font-weight: 400;
	transition: all 0.2s;
	font-family: $default_font;
	color: $main;

	&:hover {
		transition: all 0.2s;
		text-decoration: underline;
	}
}

img {
	width: 100%;
	max-width: 100%;
	height: auto;

	.krumo-root & {
		width: auto;
	}
}


b,
strong {
  @extend .regular-font;
	font-weight: 400;
}

u {
	font-weight: normal;
}

p {
	@extend .thin-font;
  line-height: 18px;
  font-size: 14px;
}

.cke_editable {
  margin: 20px;

  h4 {
    @extend .marydale-font;
    font-size: 24px;
  }

  ul,
  ol {
    @extend .thin-font;
    line-height: 18px;
    font-size: 14px;
  }


  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin-bottom: 3px;
      padding-left: 18px;
      position: relative;

      &:before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        background: #000;
        top: 50%;
        left: 0;
        transform: translatey(-50%);
      }
    }
  }

  ol {
    counter-reset: li;
    list-style: none;

    li {
      margin-bottom: 3px;
      padding-left: 18px;
      position: relative;
      counter-increment: li;

      &:before {
        content: counter(li);
        color: #000;
        width: 8px;
        height: 13px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translatey(-50%);
      }
    }
  }
}


.container {
	width: 100%;
	//max-width: $mob;
	margin: 0 auto;
	padding: 0 20px;

	@include breakpoint($tab) {
		max-width: $tab;
	}

	@include breakpoint($desk) {
		max-width: 1220px;
		padding: 0 20px;
	}

	// @include breakpoint($wide) {
	// 	max-width: $wide;
	// }
}

.container-tab {
	width: 100%;
	margin: 0 auto;

	@include breakpoint($tab) {
		padding: 0 20px;
		max-width: $tab;
	}

	@include breakpoint($desk) {
		max-width: 100%;
	}

	@include breakpoint($wide) {
		max-width: $wide;
	}
}

.clearfix {
	clear: both;
}

.tab-and-above {
	display: none;

	@include breakpoint($tab) {
		display: block;
	}
}

.till-mobile {
	@include breakpoint($tab) {
		display: none;
	}
}

.only-desk {
	display: none;

	@include breakpoint($desk) {
		display: block;
	}
}

.mobile-and-tab {
	@include breakpoint($desk) {
		display: none;
	}
}

// --------------- Scrollbar ----------------

.mCustomScrollbar {
    max-height: 200px;
    overflow: scroll;
}

.mCSB_inside > .mCSB_container {
  margin: 0;

  @include breakpoint($tab) {
    // padding-right: 10px;
    // padding: 0 15px;
  }
}

.mCSB_scrollTools {
  right: 10px;
}

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: $grey_icon;
}

.mCSB_scrollTools .mCSB_draggerRail {
  background: $border;
	width: 1px;
}

// Ajax loader
.ajax-progress {
  position: fixed;
  z-index: 112;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  top:  0;
  left: 0;

  .throbber {
  	position: fixed;
    background: none;
    width: 80px;
    height: 80px;
    content: '';
    border-top: 5px solid $main;
    border-left: 5px solid $main;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 50px;
		-webkit-animation: spin 2s infinite linear;
		animation: spin 2s infinite linear;
		z-index: 112;
  }
}

.checkout-processing,
.flag-waiting .flag-throbber {
  position: fixed;
  z-index: 112;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  top:  0;
  left: 0;
  padding: 25px;

  &:before {
  	position: fixed;
    background: none;
    width: 80px;
    height: 80px;
    content: '';
    border-top: 5px solid $main;
    border-left: 5px solid $main;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 50px;
		-webkit-animation: spin 2s infinite linear;
		animation: spin 2s infinite linear;
		z-index: 112;
  }
}


.loader-wrapper {
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  z-index: 9999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  .site-logo {
  	display: none;
  }

  .loader {
    position: fixed;
    background: none;
    width: 80px;
    height: 80px;
    content: '';
    border-top: 5px solid $main;
    border-left: 5px solid $main;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 50px;
		-webkit-animation: spin 2s infinite linear;
		animation: spin 2s infinite linear;
  }
}


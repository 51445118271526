ul.menu {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    list-style-type: none;
    display: inline-block;
    a {
      .red-link {
        color: $red;
      }
    }
    &:first-child {
      a {
        padding-left: 0;
      }
    }
    ul {
      li {
        display: block;
      }
    }
  }
}

.mothercare-dropdown-menu {
  @include pie-clearfix;
  display: none;
  @include breakpoint($desk) {
    display: block;
    position: relative;
  }
  & > ul.menu {
    @include pie-clearfix;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 0 auto;
    position: inherit;
    line-height: 30px;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    & > li {
      display: inline-block;
      text-align: left; // border-right: 1px solid #d6d6d6;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      z-index: 1;
      &.last {
        border: 0;
        & > a:after,
        & > span:after {
          display: none;
        }
      }
      &.toys:hover {
        @include breakpoint($tab) {
          background: #0D943F;
          color: #fff;
        }
      }
      a,
      span {
        padding: 0;
        color: $black;
        @extend .thin-font;
        font-size: 14px;
        text-align: center;
        position: relative;
        cursor: pointer;
        &:hover {
          @include breakpoint($tab) {
            text-decoration: none;
          }
          &:after {
            display: none;
          }
        }
      }
      & > a,
      & > span {
        padding: 20px 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        width: 100%;
        line-height: 10px;
        font-size: 11px;
        @include breakpoint(1070px) {
          font-size: 16px;
          line-height: 17px;
        }
        &:hover {
          @include breakpoint($tab) {
            color: #fff;
          }
        }
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          margin-top: -15px;
          right: -1px;
          width: 1px;
          height: 30px;
          background: #d6d6d6;
        }
        &.toys:hover {
          @include breakpoint($tab) {
            background: #0D943F;
            color: #fff;
          }
        }
        &.blog {
          background: url(/sites/all/themes/custom/rocket_path_theme/images/blog.png) 10px no-repeat;
          color: #0677b1;
          padding-left: 30px;
          &:hover {
            @include breakpoint($tab) {
              background: url(/sites/all/themes/custom/rocket_path_theme/images/blog-white.png) 10px no-repeat #0085c9;
              color: #fff;
            }
          }
        }
        &.home {
          font-size: 0;
          height: 51px;
          width: 100%;
          &:before {
            @extend .font-icon;
            @extend .mc-icon-house;
            font-size: 20px;
            font-weight: 400;
            display: inline-block;
            padding-top: 0px;
            position: relative;
            top: 5px;
          }
          &.active {
            background-color: #0085c9;
            &:before {
              color: #fff;
            }
          }
        }
      }
      & > ul.menu {
        display: none;
        width: 100%;
        position: absolute;
        left: 0;
        background: #fff;
        padding: 50px;
        z-index: 111;
        text-align: center;
        top: 51px;
        & > li {
          @include pie-clearfix;
          display: inline-block;
          border: 0;
          width: auto;
          padding: 0 25px;
          li {
            list-style: none;
          }
          &.expanded.column {
            > a {
              display: none;
            }
            & > ul.menu {
              li {
                list-style: none;
              }
              & > li {
                margin-bottom: 18px;
                .expanded {
                  margin-top: 18px;
                }
              }
              & > li > span,
              & > li > a,
              li.expanded > a {
                @extend .regular-font;
                line-height: 22px;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                padding: 0 0 15px;
                color: #fff;
                display: block;
                text-align: left;
                @include breakpoint($tab) {
                  color: #003971;
                  margin: 0;
                  line-height: 17px;
                  padding: 0;
                  margin-bottom: 10px;
                  font-size: 16px;
                }
              }
              & > li > span,
              & > li > a {
                // padding-bottom: 10px;
              }
              a {
                line-height: 16px;
                font-size: 16px;
                color: $black;
                font-weight: 400;
                font-style: normal;
                padding: 0;
                margin-bottom: 12px;
                display: block;
                text-align: left;
                &:hover {
                  @include breakpoint($tab) {
                    font-weight: 500;
                  }
                }
                &:after {
                  display: none;
                }
              }
            }
          }
          &.column-block {
            & > a {
              display: none;
            }
            .menu-attach-block-wrapper {
              max-width: 130px;
              margin: 0 auto;
            }
          }
        }
      }
      &.col-1 > ul.menu > li {
        width: 100%;
      }
      &.col-2 > ul.menu > li {
        width: 50%;
      }
      &.col-3 > ul.menu > li {
        width: 33.333%;
      }
      &.col-4 > ul.menu > li {
        width: 25%;
      }
      &.col-5 > ul.menu > li {
        width: 20%;
      }
      &.col-6 > ul.menu > li {
        width: 16.666%;
      }
      &.col-7 > ul.menu > li {
        width: 14.28%;
      }
      &.col-8 > ul.menu > li {
        width: 12.5%;
      }
      &.col-9 > ul.menu > li {
        width: 11.111%;
      }
      &.col-10 > ul.menu > li {
        width: 10%;
      }
      &.expanded {
        &:hover {
          @include breakpoint($tab) {
            & > ul {
              display: flex;
              justify-content: center;
              border: 1px solid #d6d6d6;
            }
          }
        }
      }
      &:hover {
        @include breakpoint($tab) {
          background: $blue;
        }
        a {
          color: #fff;
        }
      }
    }
    li.link-only-icon {
      background: $blue;
    }
    li.red-link {
      background: $red;
      a {
        color: #fff;
      }
    }
  }
  & > ul.menu > li.open {
    li.open {
      color: $black;
      & > a {
        position: relative;
        font-weight: 400;
        color: $black;
        &:after {
          width: 10px;
          height: 10px;
          display: block;
          content: ‘’;
          position: absolute;
          background: $black;
          z-index: 1000;
          left: 50%;
          transform: translateX(-50%);
          bottom: -24px;
          border-radius: 50%;
        }
      }
    }
  }
}

.header-top-menu {
  ul.menu {
    text-align: center;
  }
  li {
    padding: 0 15px;
  }
  a {
    color: $black;
    @extend .thin-font;
    font-size: 12px;
    &:before {
      display: inline-block;
      margin-right: 10px;
      font-size: 14px;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
    &.account:before {
      @extend .font-icon;
      @extend .mc-icon-account;
    }
    &.wish-list:before {
      @extend .font-icon;
      @extend .mc-icon-heart;
    }
    &.gift-list:before {
      @extend .font-icon;
      @extend .mc-icon-gift;
    }
    &.order-tracking:before {
      @extend .font-icon;
      @extend .mc-icon-box-alt;
    }
    &.contact:before {
      @extend .font-icon;
      @extend .mc-icon-question;
    }
  }
}

.logged-in {
  .header-top-menu {
    span {
      color: $main;
      @extend .thin-font;
      font-size: 12px;
      display: inline-block;
      font-weight: 700;
      &:before {
        display: inline-block;
        margin-right: 10px;
        font-size: 14px;
        vertical-align: middle;
        position: relative;
        top: -2px;
        font-weight: 700;
      }
      &.account:before {
        @extend .font-icon;
        @extend .mc-icon-account;
        color: $main;
      }
    }
    li.expanded {
      ul.menu {
        display: inline-block;
        li {
          display: inline-block;
          padding: 0;
          position: relative;
          &:before {
            content: "/";
            padding: 0 5px;
            color: #414141;
            @extend .thin-font;
            font-size: 10px;
          }
        }
      }
    }
  }
}

.header-left-menu {
  display: none;
  @include breakpoint($desk) {
    display: block;
  }
  li {
    position: relative;
  }
  a {
    color: $main;
    letter-spacing: .8px;
    font-weight: 400;
    margin: 0 0 0 28px;
    font-size: 13px;
    &:before {
      @extend .font-icon;
      @extend .mc-icon-location;
      position: absolute;
      font-size: 22px;
      left: 0;
      top: -3px;
    }
  }
}

.articles-sidebar-menu,
.faq-menu {
  display: inline-block;
  float: left;
  width: 100%;
  .block__title {
    font-weight: 400;
    margin: 0 0 20px;
    font-size: 28px;
    line-height: 28px;
    color: $main;
  }
  li {
    width: 100%;
    padding: 0 0 15px;
    &.expanded {
      padding-bottom: 15;
    }
    a {
      font-size: 14px;
      color: $black;
      font-weight: 400;
      padding: 0 15px;
      display: block;
      line-height: 14px;
      position: relative;
      span {
        position: absolute;
        right: 0;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  ul.menu li:first-child a {
    padding: 0 15px;
    display: block;
    width: 100%;
    span {
      position: absolute;
      right: 0;
    }
  }
  .expanded {
    & > .nolink {
      display: block;
      padding: 12px 15px;
      background: #f6f6f6;
      color: #888;
      cursor: pointer;
    }
    &.open > .nolink {
      margin-bottom: 10px;
    }
    ul.menu {
      display: none;
      padding-left: 10px;
      li:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    &.open > .menu {
      display: block;
    }
    &.open > a {
      font-weight: 400;
      color: #003971;
      margin-bottom: 15px;
    }
  }
  ul.node-menu {
    padding-left: 20px !important;
    li {
      padding-bottom: 0;
      margin-bottom: 15px;
      a {
        padding: 0 !important;
      }
    }
  }
  .active-trail a {
    color: $main;
  }
}

.faq-menu {
  .block__title {
    padding: 12px;
    border: 1px solid $border;
    border-bottom: 0;
    margin-bottom: 0;
    background: #edf6fb;
    font-size: 1.4em;
    color: $main;
  }
  & > ul.menu {
    border: 1px solid $border;
  }
  .expanded {
    & > .nolink {
      border-bottom: 1px solid $border;
      &:last-child {
        border: 0;
      }
    }
  }
  .expanded.open {
    & > .nolink {
      background: #edf6fb;
    }
  }
  .active-trail a {
    color: $black;
  }
}

.open-menu-mobile .l-mobile-overlay {
  // background: rgba(25,25,25,0.2);
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.open-menu-mobile .header-top {
  border: 0;
  border-top: 1px solid $border;
  max-width: 760px;
  margin: 0 auto;
  display: block;
  padding: 25px 0 5px;
  ul.menu {
    text-align: left;
    li {
      width: 100%;
      padding: 6px 35px 6px 0;
    }
  }
  .block--lang-dropdown-language {
    margin-top: 15px;
  }
}

.open-menu-mobile.logged-in .header-top-menu {
  span {
    display: block;
  }
  li.expanded ul.menu {
    margin-bottom: 10px;
    li {
      padding: 7px 0px;
      &:before {
        @extend .font-icon;
        @extend .mc-icon-arrow-right;
      }
    }
  }
}

.l-mobile-menu {
  position: fixed;
  top: 62px;
  left: 0;
  width: 100%;
  height: 100%;
  height: calc(100% - 62px);
  z-index: 99;
  background: #fff;
  padding-bottom: 50px;
  overflow: scroll;
  display: none;
  max-width: 85%;
  .admin-menu & {
    top: 109px;
    height: calc(100% - 109px);
  }
  &:before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    content: ‘’;
    background: #fff;
    height: 78px;
    @include breakpoint($tab) {
      height: 101px;
    }
  }
  .open-menu-mobile & {
    display: block;
  }
  .user-block {
    display: block;
    float: none;
    position: fixed;
    background: #DADADA;
    top: 78px;
    margin: 0;
    width: 100%;
    padding: 10px 20px;
    @include breakpoint($tab) {
      top: 120px;
    }
  }
  .main-menu-inner {
    @extend .container;
    padding: 0;
    .menu-attach-block-drop-link.external.expand-on-click {
      display: none;
    }
    a.menu-attach-block-drop-link ~ .menu-attach-block-wrapper {
      display: block;
    }
    .menu-attach-block-wrapper.orientation-horizontal .block {
      background-color: transparent;
      position: relative;
      top: 0;
      width: 100%;
      z-index: -1;
      text-align: center;
      padding: 0;
      margin: 0;
      .block__title {
        display: none;
      }
      img {
        display: block;
      }
    }
    .main-menu-back {
      margin: 25px 0 0 20px;
      font-size: 12px;
      display: none;
      &.show {
        display: table;
      }
      &:before {
        @extend .font-icon;
        @extend .mc-icon-arrow-right; // .icon-arrow-left;
      }
    }
    ul.menu {
      list-style-type: none;
      list-style-image: none;
      list-style: none;
      li {
        list-style-type: none;
        list-style-image: none;
        list-style: none;
        display: block;
        clear: both;
        @include pie-clearfix;
      }
      li.column-block {
        display: none;
      }
      li.link-only-icon {
        background: $blue;
      }
      li.red-link {
        background: $red;
        a {
          color: #fff;
        }
      }
    }
    & > ul.menu {
      & > li {
        &.hide {
          display: none;
        }
        & > a,
        & > span {
          display: block;
          padding: 16px 35px 16px 20px;
          border-top: 1px solid $border;
          color: $main;
          text-align: left;
          @include breakpoint($tab) {
            padding-left: 20px;
          }
        }
        &.open > span {
          display: block;
          text-align: center;
        }
        &.expanded.open > a,
        &.expanded.open > span {
          background: $main;
          color: #fff;
          &:before {
            filter: brightness(0) invert(1);
          }
        }
      }
      li.red a {
        color: $red;
      }
      li.expanded {
        & > span,
        & > a {
          position: relative;
          &:before {
            @extend .font-icon;
            @extend .mc-icon-arrow-down;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translatey(-50%);
          }
        }
        & > .mobile-submenu {
          display: none;
        }
        &.open {
          display: block;
          & > span,
          & > a {
            position: relative;
            &:before {
              @extend .font-icon;
              @extend .mc-icon-arrow-up;
              position: absolute;
              top: 50%;
              right: 20px;
              transform: translatey(-50%);
            }
          }
          & > .mobile-submenu {
            display: block;
          }
          li > span,
          li.expanded > a {
            margin-top: 5px;
            line-height: 20px;
            padding: 10px 20px;
            display: block;
            color: $black;
            font-weight: 400;
            cursor: pointer;
          }
          li {
            // margin-top: 10px;
            ul.menu {
              display: none;
            }
            &.open ul.menu {
              display: block;
              padding: 0 20px;
            }
          }
          li {
            &.column {
              ul.menu {
                display: block;
              }
              & > a {
                display: none;
              }
            }
          }
        }
        .see-all-off-category {
          padding-left: 20px; // margin: 15px 0;
          a {
            font-size: 15px;
            color: $main;
            line-height: 20px;
            display: block;
            padding: 10px 20px;
          }
        }
        li a {
          line-height: 20px;
          font-size: 15px;
          color: $black;
          padding: 10px 20px;
          display: block;
        }
      }
      & > li.expanded > .mobile-submenu > .see-all-off-category a {
        padding: 10px 0;
        margin-top: 5px;
      }
    }
  }
}

// --------------- Menu Attach Block ----------------
.main-menu > ul.menu > li > ul.menu > li > a.menu-attach-block-drop-link,
a.menu-attach-block-drop-link.external,
a.menu-attach-block-drop-link.external:hover,
a.menu-attach-block-drop-link.external:focus,
#main-menu a.menu-attach-block-drop-link.external,
#main-menu a.menu-attach-block-drop-link.external:hover,
#main-menu a.menu-attach-block-drop-link.external:focus,
#main-menu-links li.active-trail a.menu-attach-block-drop-link.external {
  display: none;
}

a.menu-attach-block-drop-link ~ .menu-attach-block-wrapper {
  display: block;
  .block__title {
    display: none;
  }
}

.menu-attach-block-wrapper.orientation-horizontal .block {
  background-color: transparent;
  padding: 0;
  position: relative;
  top: 0;
  width: 100%;
}
.hero-banner {
  @include pie-clearfix;
  @extend .container;
  margin: 0 auto 20px;
  padding: 0;

  @include breakpoint($tab) {
    padding: 0 20px;

    @include breakpoint($desk) {
  		margin: 0 auto;
 		}
  }

	.image-wrapper {
		position: relative;
    text-align: center;

    .mob-image {
      display: none;
    }

    &.with-mobile {
      .mob-image {
        display: block;
      }

      .desk-image {
        display: none;
      }

      @include breakpoint($tab) {
        .mob-image {
          display: none;
        }

        .desk-image {
          display: block;
        }
      }
    }

    .overlay {
      display: block;
    }
	}

	.owl-carousel {
		overflow: hidden;
	}

	.owl-item {
		display: inline-block;
		float: left;
	}

	.owl-controls {
    @include pie-clearfix;
    position: relative;
    pointer-events: none;

    .owl-buttons {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: initial;

      .owl-prev {
        position: absolute;
        left: 0;
      }

      .owl-next {
        position: absolute;
        right: 0;
      }

      div {
        color: transparent;
        zoom: 1;
        margin: 0;
        padding: 0;
        font-size: 12px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background: transparent;
        opacity: 1;

      }

      .owl-next,
      .owl-prev {
        color: $black;
        display: inline-block;
        line-height: 32px;
        font-size: 12px;
        padding-left: 35px;
        // transform: translateX(21px);
        font-size: 0;
        cursor: pointer;
        margin-right: 20px;

        &:before {
          width: 38px;
          height: 24px;
          @extend .font-icon;
          font-size: 24px;
          line-height: 32px;
          position: absolute;
          z-index: 1;
          pointer-events: none;
          top: 0;
          left: 0;
        }
      }

      .owl-prev {
        margin-rights: 0;
        margin-left: 20px;

        &:before {
          // @extend .font-icon;
          @extend .mc-icon-arrow-left;
          color: $main;
          font-size: 34px;
        }
      }

      .owl-next {
        &:before {
          // @extend .font-icon;
          @extend .mc-icon-arrow-right;
          color: $main;
          font-size: 34px;
        }
      }
    }

    .owl-pagination {
      text-align: center;
      position: absolute;
      bottom: 5px;
      width: 100%;
      pointer-events: initial;

      @include breakpoint($tab) {
        bottom: 15px;
      }

      .owl-page {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        width: 9px;
        height: 9px;
        margin-left: 5px;
        margin-right: 5px;
        background: $blue;
        border:none;
        border-radius: 50%;
        display: inline-block;
        cursor: pointer;
      }

      .owl-page.active {
        background: $main;
        width: 11px;
        height: 11px;
      }
    }
  }
}

.front-promo-blocks {
  @include pie-clearfix;
  @extend .container;
  padding: 0;

  @include breakpoint($tab) {

    @include breakpoint($desk) {
      padding: 0 10px;
  		margin: 20px auto;
 		}
  }

	.views-row {
    display: inline-block;
    float: left;
    width: 33%;

    @include breakpoint($desk) {
      padding: 0 10px;
		  width: 25%;
    }

		.node--marketing-post--front-promo-blocks {
      text-align: center;
      padding: 0 2px;
      border-right: 1px solid $border;
      margin: 10px 0;

      @include breakpoint($desk) {
        margin: 0;
        border: 1px solid $border;
        padding: 5px 2px;
        height: 39px;
      }

	    a,
	    a:hover {
	    	text-decoration: none;
	    }
		}

    &:last-child .node--marketing-post--front-promo-blocks {
      border: 0;

      @include breakpoint($desk) {
        border: 1px solid $border;
      }
    }

		.title {
			display: block;
      // @extend .regular-font;
      padding-top: 1px;
      font-size: 13px;
      color: $main;
      // height: 20px;

      @include breakpoint($desk) {
        // font-family: 'futura-plus-thin', sans-serif;
        font-family: $default_font;
        font-weight: 300;
        -webkit-font-smoothing: subpixel-antialiased;
        color: $blue;
        height: auto;
        font-size: 15px;
      }
		}

		.cta {
      @include breakpoint($desk) {
			 display: none;
      }

			a {
        @extend .thin-font;
        color: $main;
        font-size: 12px;
        position: relative;
        display: block;
        padding: 5px 0;

        @include breakpoint($desk) {
				  font-size: 15px;
          color: #fff;
        }


        &:before {
          @extend .font-icon;
          @extend .mc-icon-arrow-right;
          position: absolute;
          color: #fff;
          right: 10px;
          top: 8px;
          display: none;

          @include breakpoint($desk) {
            display: block;
          }
        }
			}
		}

    &:nth-child(1) .title .field__item:before {
      @extend .font-icon;
      @extend .mc-icon-box;
      margin: -1px 10px 0 0;
      font-size: 28px;
      vertical-align: middle;
      display: none;

      @include breakpoint($desk) {
        display: inline-block;
      }
    }

    &:nth-child(1) {
      display: none;

      @include breakpoint($desk) {
        display: block;
      }
    }

    &:nth-child(2) .title .field__item:before {
      @extend .font-icon;
      @extend .mc-icon-gift-card;
      margin: -1px 10px 0 0;
      font-size: 28px;
      vertical-align: middle;
      display: none;

      @include breakpoint($desk) {
        display: inline-block;
      }
    }

    &:nth-child(3) .title .field__item:before {
      @extend .font-icon;
      @extend .mc-icon-cursor-circle;
      margin: 3px 10px 4px 0;
      font-size: 22px;
      vertical-align: middle;
      display: none;

      @include breakpoint($desk) {
        display: inline-block;
      }
    }

    &:nth-child(4) .title .field__item:before {
      content: "";
      background-image: url(/sites/all/themes/custom/rocket_path_theme/images/blog.png);
      background-repeat: no-repeat;
      background-size: cover;
      width: 22px;
      height: 22px;
      margin: 2px 10px 4px 0;
      vertical-align: middle;
      display: none;

      @include breakpoint($desk) {
        display: inline-block;
      }
    }

    &:last-child {
      border: 0;
    }

		&:hover {
      @include breakpoint($desk) {
  			.node--marketing-post--front-promo-blocks {
  				background: $blue;
  				border: 1px solid $blue;
  				padding: 4px 2px;
  			}

  			.title {
  				display: none;
  			}

  			.cta {
  				display: block;
  			}
      }
		}
	}
}

.front-three-images {
	@extend .container;
  padding: 0;
  margin: 0 auto;
  @include pie-clearfix;

  @include breakpoint($tab) {
  	padding: 0 20px;
  	margin: 0 auto 20px;

  	@include breakpoint($desk) {
  		margin: 20px auto;
 		}
  }

	.node__content {
		@include pie-clearfix;
		position: relative;

		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}

	.views-row {
		width: 100%;
		display: inline-block;
		float: left;
		position: relative;
		margin-bottom: 20px;

		@include breakpoint($tab) {
			padding: 0 7px;
			width: 33.333%;
			margin-bottom: 0;
      padding-bottom: 21px;
		}

    &:nth-child(3n+1) {
			@include breakpoint($tab) {
				padding-left: 0;
				padding-right: 14px;
			}
		}

    &:nth-child(3n) {
			@include breakpoint($tab) {
				padding-right: 0;
				padding-left: 14px;
			}
		}
	}

	.title {
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 0 10px;

    a {
	    text-decoration: none;
	    background: #fff;
	    display: block;
	    padding: 20px;
	    color: $main;

	    @include breakpoint($desk) {
	    	padding: 20px 30px;
	    }
    }
	}
}

.full-width-image,
.top-full-width-image {
	@extend .container;
  padding: 0;
  margin: 0 auto 20px;
  @include pie-clearfix;

  @include breakpoint($tab) {
  	padding: 0 20px;

  	@include breakpoint($desk) {
  		margin: 20px auto;
 		}
  }
}

.front-two-images {
	@extend .container;
	  padding: 0;
	  margin: 0 auto;
	  @include pie-clearfix;

	  @include breakpoint($tab) {
	  	padding: 0 20px;
	  	margin: 0 auto 20px;

	  	@include breakpoint($desk) {
	  		margin: 20px auto;
	 		}
	  }

	.node__content {
		@include pie-clearfix;
		position: relative;

		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}

	.views-row {
		display: inline-block;
		float: left;
		position: relative;
		width: 100%;
		margin-bottom: 20px;

		@include breakpoint($tab) {
			width: 50%;
		}
	}

  .views-row:nth-child(2n+1) {
    clear: both;
  }

	.views-row-odd {
		@include breakpoint($tab) {
			padding-right: 10px;
		}
	}

	.views-row-even {
		@include breakpoint($tab) {
			padding-left: 10px;
		}
	}

	.cta {
    position: absolute;
    bottom: 15%;
    left: 12%;
    background: #fff;
    padding: 8px 38px;
    line-height: 20px;

    @include breakpoint($desk) {
	    line-height: 25px;
	  }

    a {
	    text-decoration: none;
	    color: red;
	    font-weight: 400;
	    letter-spacing: 1px;
	    font-size: 20px;

	    @include breakpoint($desk) {
		    font-size: 25px;
		  }
    }
	}
}

.product-recall-notice {
	border-bottom: 1px solid $border;

	.block__content {
		// max-width: 1260px;
	  padding: 0 20px;
	  margin: 20px auto;
	  @include pie-clearfix;
    @extend .container;
	}

	.node__content {
		@include pie-clearfix;
		position: relative;
		text-align: center;

		@include breakpoint($tab) {
			text-align: left;
		}

		img {
			width: 100%;
			height: auto;
			display: block;
	    max-width: 55px;
		}
	}

	.views-row {
    width: 50%;
    display: inline-block;
    float: left;
    padding: 0 7px;
    position: relative;
    border-left: 1px solid $border;

    @include breakpoint($tab) {
		  width: 33.333%;
    }

		&:first-child {
			padding-left: 0;
			padding-right: 14px;
	    border-left: 0;
		}

		&:last-child {
			padding-right: 0;
			padding-left: 14px;
		}
	}

	.left-side,
	.middle-side  {
		display: inline-block;

		@include breakpoint($tab) {
	    float: left;
	  }
	}

	.middle-side {
		@include breakpoint($tab) {
			margin-left: 20px;
		}

		.subtitle {
	    margin-bottom: 8px;
	    color: $main;
	    font-size: 13px;
	    font-weight: 400;
		}

		.title {
	    color: #959595;
    	font-size: 12px;
		}
	}

	.right-side {
		display: inline-block;

		@include breakpoint($tab) {
	    float: right;
	  }

    .cta {
    	margin: 10px 0;

    	a {
    		color: $main;
    		text-decoration: none;
        font-size: 14px;
        position: relative;

    		@include breakpoint($tab) {
					padding-right: 28px;
    		}

        &:before {
          @extend .font-icon;
          @extend .mc-icon-carrow-left-1;
          position: absolute;
          right: 0;
          bottom: 0;
          transform: rotate(180deg);
          font-size: 15px;
        }
    	}
    }
	}
}

.footer-catalogue {
	float: right;
	display: inline-block;
  padding-top: 45px;
  width: 100%;
  text-align: center;

  @include breakpoint($tab) {
	  width: 32%;
	  text-align: left;
	}

  .cta a {
    font-size: 13px;
    color: $main;
    margin-top: 15px;
    padding-right: 19px;
    position: relative;
    text-decoration: none;

    &:hover {
    	text-decoration: none;
    }
  }
}

.node--marketing-post {
	img {
		display: block;
	}

	a {
		text-decoration: underline;
		font-weight: 400;
		color: $black;
		font-size: 14px;

		@include breakpoint($tab) {
			font-size: 15px;

			@include breakpoint($desk) {
				font-size: 19px;
			}
		}
	}
}

.hub-master {

  @extend .container;
  padding: 0;
  margin: 0 auto;
  @include pie-clearfix;

  @include breakpoint($tab) {
    padding: 0 10px;
    margin: 0;

    @include breakpoint($desk) {
      // margin: 20px auto;
    }
  }

  .views-row {
    width: 100%;
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;

    @include breakpoint($tab) {
      padding: 0 7px;
      width: 33.333%;
    }

    &:nth-child(3n+1) {
      @include breakpoint($tab) {
        padding-left: 0;
        padding-right: 14px;
      }
    }

    &:nth-child(3n) {
      @include breakpoint($tab) {
        padding-right: 0;
        padding-left: 14px;
      }
    }
  }

  .hub-child-teaser {
    position: relative;
    text-decoration: none;
    display: block;

    @include breakpoint($tab) {
      max-height: 303px;
    }

    .puff_banner_description {
      padding: 10px 4px;
      background-color: #fff;
      text-align: center;
      overflow: hidden;
      position: absolute;
      right: 10px;
      bottom: 10px;
      left: 10px;
    }

    h3 {
      color: $main;
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 5px;
    }

    .field--name-field-subtitle {
      color: #959595;
      font-size: 13px;
    }

    .field--name-body {
      font-size: 11px;
      font-weight: 400;
      display: block;
      height: 42px;
      margin-bottom: -42px;
      padding-top: 10px;
      transition: margin-bottom .5s ease-in-out;

      p {
        font-size: 11px;
        color: #959595;
      }
    }

    &:hover {
      .field--name-body {
        margin-bottom: 0;
      }
    }
  }
}

.hub-two-images {

  @extend .container;
  padding: 0;
  margin: 0 auto;
  @include pie-clearfix;

  @include breakpoint($tab) {
    padding: 0 10px;
  }

  .views-row {
    width: 100%;
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;

    @include breakpoint($tab) {
      padding: 0 5.25px;
      width: 50%;
    }

    &:nth-child(2n+1) {
      @include breakpoint($tab) {
        padding-left: 0;
        padding-right: 10.5px;
      }
    }

    &:nth-child(2n) {
      @include breakpoint($tab) {
        padding-right: 0;
        padding-left: 10.5px;
      }
    }
  }

  .cta {
    position: absolute;
    bottom: 15%;
    left: 12%;
    background: #fff;
    padding: 8px 38px;
    line-height: 20px;

    @include breakpoint($desk) {
      line-height: 25px;
    }

    a {
      text-decoration: none;
      color: red;
      font-weight: 400;
      letter-spacing: 1px;
      font-size: 20px;

      @include breakpoint($desk) {
        font-size: 25px;
      }
    }
  }
}

.hub-header-banner,
.view-advice {
  margin: 0 auto 20px;
  @include breakpoint($tab) {
    padding: 0 10px;

    @include breakpoint($desk) {
      margin: 0 auto;
      margin: 0 auto 20px;
    }
  }

  .image-wrapper {
    position: relative;
    text-align: center;

    .mob-image {
      display: none;
    }

    &.with-mobile {
      .mob-image {
        display: block;
      }

      .desk-image {
        display: none;
      }

      @include breakpoint($tab) {
        .mob-image {
          display: none;
        }

        .desk-image {
          display: block;
        }
      }
    }

    .overlay {
      display: block;
    }
  }

  .owl-carousel {
    overflow: hidden;
  }

  .owl-item {
    display: inline-block;
    float: left;
  }

  .owl-controls {
    @include pie-clearfix;
    position: relative;
    pointer-events: none;

    .owl-buttons {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: initial;

      .owl-prev {
        position: absolute;
        left: 0;
      }

      .owl-next {
        position: absolute;
        right: 0;
      }

      div {
        color: transparent;
        zoom: 1;
        margin: 0;
        padding: 0;
        font-size: 12px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background: transparent;
        opacity: 1;

      }

      .owl-next,
      .owl-prev {
        color: $black;
        display: inline-block;
        line-height: 32px;
        font-size: 12px;
        padding-left: 35px;
        // transform: translateX(21px);
        font-size: 0;
        cursor: pointer;
        margin-right: 20px;

        &:before {
          width: 38px;
          height: 24px;
          @extend .font-icon;
          font-size: 24px;
          line-height: 32px;
          position: absolute;
          z-index: 1;
          pointer-events: none;
          top: 0;
          left: 0;
        }
      }

      .owl-prev {
        margin-rights: 0;
        margin-left: 20px;

        &:before {
          // @extend .font-icon;
          @extend .mc-icon-arrow-left;
          color: $main;
          font-size: 34px;
        }
      }

      .owl-next {
        &:before {
          // @extend .font-icon;
          @extend .mc-icon-arrow-right;
          color: $main;
          font-size: 34px;
        }
      }
    }

    .owl-pagination {
      text-align: center;
      position: absolute;
      bottom: 5px;
      width: 100%;
      pointer-events: initial;

      @include breakpoint($tab) {
        bottom: 15px;
      }

      .owl-page {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        width: 9px;
        height: 9px;
        margin-left: 5px;
        margin-right: 5px;
        background: $blue;
        border:none;
        border-radius: 50%;
        display: inline-block;
        cursor: pointer;
      }

      .owl-page.active {
        background: $main;
        width: 11px;
        height: 11px;
      }
    }
  }
}

.hub-header-bottom,
.hub-header-top {
  @include breakpoint($tab) {
    margin: 0 auto 20px;
    padding: 0 10px;

    @include breakpoint($desk) {
      margin: 0 auto 20px;
    }
  }
}

.rocket-recommendations {
}

.products-carousel-wrapper {
  background-color: #ebf4f9;
  padding: 20px 15px 40px;
}

.view-products-carousel {
  // background-color: #ebf4f9;
  // border: 1px solid #003971;

  .image-wrapper {
    position: relative;
    text-align: center;
  }

  .owl-carousel {
    // overflow: hidden;
    padding: 20px 50px 30px;
    position: relative;
  }

  .owl-wrapper-outer {
    -webkit-transform: translate3d(0px,0,0);
    transform: translate3d(0px,0,0);
    overflow: hidden;
  }

  .owl-item {
    display: inline-block;
    float: left;
    padding: 0 10px;
  }

  .product-teaser {
    background: #fff;
    padding: 0 10px 10px;
    max-width: 300px;

    .product-actions .view-details {
      width: auto;
    }
  }

   .owl-controls {
    @include pie-clearfix;
    position: relative;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    pointer-events: none;
    top: 0;

    .owl-buttons {
      position: absolute;
      width: 100%;
      top: 40%;
      transform: translateY(-50%);
      pointer-events: initial;

      .owl-prev {
        position: absolute;
        left: 0;
      }

      .owl-next {
        position: absolute;
        right: 0;
      }

      div {
        color: transparent;
        zoom: 1;
        margin: 0;
        padding: 0;
        font-size: 12px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background: transparent;
        opacity: 1;

      }

      .owl-next,
      .owl-prev {
        display: inline-block;
        font-size: 0;
        cursor: pointer;
        // background-color: #ebf4f9;
        height: 80px;
        line-height: 80px;

        &:before {
          @extend .font-icon;
          font-size: 24px;
          z-index: 1;
          pointer-events: none;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .owl-prev {
        margin-left: -10px;

        &:before {
          @extend .mc-icon-arrow-left;
          color: $main;
          font-size: 34px;
        }
      }

      .owl-next {
        margin-right: -10px;

        &:before {
          @extend .mc-icon-arrow-right;
          color: $main;
          font-size: 34px;
        }
      }
    }

    .owl-pagination {
      text-align: center;
      position: absolute;
      bottom: 5px;
      width: 100%;
      pointer-events: initial;

      @include breakpoint($tab) {
        bottom: 15px;
      }

      .owl-page {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        width: 12px;
        height: 12px;
        margin-left: 2px;
        margin-right: 2px;
        background: $blue;
        border:none;
        border-radius: 50%;
        display: inline-block;
      }

      .owl-page.active,
      .owl-page:hover{
        background: $main;
      }
    }
  }
}

@import "compass";
@import "breakpoint";
// @import "font-awesome";
// Import variables, abstractions, base styles and components.
@import "abstractions/animation.scss";
// Uncomment the next lines and adjust them to your needs.
@import "variables/variables.scss";
@import "variables/icons.scss";
//@import "abstractions/{YOUR FILE NAME HERE}";
@import "base/default.scss";
@import "base/grid.scss";
@import "base/table.scss";
//@import "components/{YOUR FILE NAME HERE}";
@import "components/general.scss";
@import "components/tabs.scss";
@import "components/navigation.scss";
@import "components/form.scss";
@import "components/messages.scss";
@import "components/product.scss";
@import "components/user.scss";
@import "components/page.scss";
@import "components/commerce.scss";
@import "components/views.scss";
@import "components/article.scss";
@import "components/wishlist.scss";
@import "components/category.scss";
@import "components/mkt_posts.scss";
@import "components/jobs.scss";
@import "components/events.scss";
@import "components/checkout.scss";
// --------- Page ----------
.l-page {
	overflow: hidden;
}

// --------- Header ----------
.header-top {
	@include pie-clearfix;
	display: block;
	text-align: center;
	padding: 15px 0;
	border-bottom: 1px solid $border;
	display: none;
	@include breakpoint($desk) {
		display: block;
	}
	.header-top-wrapper {
		position: relative;
	}
}

.l-header {
	z-index: 100;
	position: fixed;
	width: 100%;
	left: 0;
	@include breakpoint($tab) {
		position: relative;
		@include breakpoint($desk) {
			margin-bottom: 15px;
		}
	}
}

.cookie-consent {
	position: fixed;
	background: #fff;
	bottom: 0;
	left: 0;
	text-align: center;
	width: 100%;
	padding: 15px 20px;
	.cookie-consent-close {
		display: table;
		width: auto;
		margin: 15px auto 0;
		@include breakpoint($tab) {
			display: inline-block;
			margin: 0 15px;
			padding: 10px 25px;
		}
	}
}

.open-menu-mobile {
	overflow: hidden;
	height: 100%;
	width: 100%;
	position: fixed;
}

.open-simple-overlay {
	overflow: hidden;
	.simple-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .5);
		z-index: 498;
	}
}

.l-header-inner {
	@include pie-clearfix;
	background: #fff;
	position: relative;
	border-bottom: 1px solid #d6d6d6;
	padding: 12px 10px;
	@include breakpoint($desk) {
		padding: 12px 20px;
		border: 0;
	}
	.header-left,
	.header-right {
		float: left;
		width: 20%;
		margin-top: 0;
		@include breakpoint($tab) {
			@include breakpoint($desk) {
				margin-top: 0;
				width: 29%;
				border-bottom: 0;
			}
		}
	}
	.l-branding {
		float: left;
		width: 60%;
		text-align: center;
		padding-top: 3px;
		@include breakpoint($tab) {
			padding-top: 2px;
		}
		a {
			display: inline-block;
			max-width: 244px;
		}
		@include breakpoint($desk) {
			width: 42%;
			padding-top: 20px;
		}
		img {
			max-width: 120px;
			@include breakpoint($tab) {
				max-width: 135px;
				@include breakpoint($desk) {
					max-width: 285px;
				}
			}
		}
	}
	.header-left {
		@include breakpoint($tab) {
			padding-top: 0;
			@include breakpoint($desk) {
				padding-top: 80px;
			}
		}
		.burger-wrapper {
			position: relative;
			width: 29px;
			height: 17px;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .5s ease-in-out;
			-moz-transition: .5s ease-in-out;
			-o-transition: .5s ease-in-out;
			transition: .5s ease-in-out;
			margin-top: 11px;
			@include breakpoint($tab) {
				margin-top: 11px;
			}
			.line {
				width: 100%;
				background: $main;
				border-radius: 2px;
				height: 3px;
				display: block;
				margin-bottom: 3px;
				display: block;
				position: absolute;
				opacity: 1;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: .25s ease-in-out;
				-moz-transition: .25s ease-in-out;
				-o-transition: .25s ease-in-out;
				transition: .25s ease-in-out;
				&:nth-child(1) {
					top: 0;
				}
				&:nth-child(2),
				&:nth-child(3) {
					top: 8px;
				}
				&:nth-child(4) {
					top: 16px;
				}
				.open-menu-mobile & {
					&:nth-child(1) {
						top: 8px;
						width: 0%;
						left: 50%;
					}
					&:nth-child(2) {
						-webkit-transform: rotate(45deg);
						-moz-transform: rotate(45deg);
						-o-transform: rotate(45deg);
						transform: rotate(45deg);
					}
					&:nth-child(3) {
						-webkit-transform: rotate(-45deg);
						-moz-transform: rotate(-45deg);
						-o-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}
					&:nth-child(4) {
						top: 8px;
						width: 0%;
						left: 50%;
					}
				}
			}
		}
		.site-icon img {
			max-width: 68px;
		}
	}
	.header-right {
		position: relative;
		padding-top: 0;
		margin: 0;
		@include breakpoint($desk) {
			padding-top: 60px;
			margin-top: 0;
		}
	}
}

.main-navigation {
	.l-region {
		border-top: 1px solid $border;
		border-bottom: 1px solid $border;
	}
}



.search-form {
	@include pie-clearfix;

	.container-inline {
		display: flex;
    align-items: center;
	}

	.form-wrapper {
		@include breakpoint($tab) {
			text-align: center;
			margin: 30px 0;
		}
	}
	.form-type-textfield {
		display: inline-block;
		max-width: calc(100% - 100px);
		margin-right: 5px;
    margin-bottom: 23px;
		@include breakpoint($tab) {
			max-width: 640px;
		}
		label {
			text-align: center;
			@include breakpoint($tab) {
				text-align: left;
			}
		}
		input[type="text"] {
			border-bottom: 1px solid $border;
		}
	}
	input[type="submit"] {
		line-height: 13px;
		padding-left: 10px;
		padding-right: 10px;
		@include breakpoint($tab) {
			margin: 20px;
			padding-left: 38px;
			padding-right: 38px;
		}
	}
}

.block--search-form {
	@include pie-clearfix;
	border: 0;
	width: auto;
	position: absolute;
	top: 10px;
	left: 45px;
	margin-top: 0;
	@include breakpoint($desk) {
		position: relative;
		left: initial;
		top: initial;
		display: inline-block;
		width: 100%;
		border: 1px solid #d6d6d6;
		margin-top: 20px;
	}
	.container-inline div {
		display: block;
	}
	.search-block-form {
		.element-invisible {
			display: none;
		}
		.form-item {
			width: calc(100% - 47px);
			display: block;
			float: left;
			margin: 0;
			input[type="text"] {
				background: transparent;
				border: 0;
				font-size: 13px;
				color: $black;
				line-height: 17px;
				box-shadow: none;
				padding: 10px 15px;
				max-width: none;
				width: 100%;
				display: none;
				@include breakpoint($desk) {
					display: block;
				}
			}
		}
		.form-actions {
			width: 47px;
			float: right;
			&:before {
				@extend .font-icon;
				@extend .mc-icon-search;
				height: 37px;
				width: 47px;
				color: $main;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				font-size: 22px;
				top: 10px;
			}
			input {
				height: 37px;
				width: 47px;
				background-color: transparent;
				color: transparent;
				padding: 0;
				border: 0;
			}
		}
	}
	&.open-search {
		.search-block-form {
			margin-right: 10px;
			-webkit-animation: open-search .3s 1 ease-in;
			animation: open-search .3s 1 ease-in;
			input[type="text"] {
				background: $background;
				border: 1px solid $background;
				width: 240px;
				cursor: initial;
				-webkit-animation: open-search-text .3s 1 ease-in;
				animation: open-search-text .3s 1 ease-in;
				@include breakpoint($tab) {
					width: 100%;
					background: transparent;
					border: 0;
				}
			}
			.form-item-search-block-form {
				&:before {
					right: 5px;
				}
			}
			input[type="submit"] {
				width: 40px;
				height: 40px;
				display: inline-block;
				position: absolute;
				top: 0;
				right: 0;
				opacity: 0;
				padding: 0;
			}
		}
	}
}

.mobile-search-form {
	padding: 20px;
	background: $background;
	display: none;
	&.show {
		display: block;
	}
	.rocket-path-elastic-instant-search {
		cursor: pointer;
		@extend .container;
		.container-inline {
			text-align: center;
		}
		input[type="text"] {
			border: 0;
			padding: 0 20px;
			line-height: 40px;
			width: 100%;
			box-shadow: none;
		}
		.form-item-search-block-form {
			position: relative;
			&:before {
				@extend .font-icon;
				@extend .mc-icon-search;
				color: $black;
				font-size: 17px;
				top: 50%;
				right: 0;
				position: absolute;
				line-height: 0;
				transform: translate(-50%, 0);
			}
		}
		input[type="submit"] {
			display: none;
		}
	}
}

.highlighted-region {
	@include pie-clearfix;
	padding-top: 64px;
	@include breakpoint($tab) {
		padding-top: 0;
	}
	.l-region--highlighted {
		width: 100%;
		margin: 0 auto;
		@include breakpoint($tab) {
			max-width: $tab;
		}
		@include breakpoint($desk) {
			max-width: 100%;
		}
		@include breakpoint($wide) {
			max-width: $wide;
		}
		.left-high {
			width: 100%;
			float: left;
			margin-bottom: 10px;
			@include breakpoint($tab) {
				width: 60%;
				margin-bottom: 25px;
			}
		}
		.new-arrivals.left-high {
			@include breakpoint($tab) {
				width: 100%;
				@include breakpoint($desk) {
					width: 60%;
				}
			}
		}
		.right-high {
			width: 100%;
			float: right;
			padding: 0 40px 0;
			background: linear-gradient(to bottom, #fff 0%, #fff 50%, $background 50%, $background 50%, $background 100%);
			@include breakpoint($tab) {
				width: 40%;
				padding-left: 70px;
				background: transparent;
				padding-top: 15px;
				@include breakpoint($desk) {
					padding-top: 80px;
					padding-right: 0;
				}
			}
		}
		.term-info {
			margin: 0 auto;
			clear: both;
			@extend .container;
			position: relative;
			padding: 0;
			@include breakpoint($tab) {
				margin: 35px auto 50px;
			}
			.block__content.empty-term-content {
				.term-designer-title {
					margin: 0;
					&:before {
						display: none;
					}
				}
				.l-breadcrumb {
					position: absolute;
					top: 0;
					left: 20px;
				}
			}
		}
		.high-info {
			text-align: center;
			font-size: 15px;
			line-height: 18px;
			padding: 14px 20px;
			.green-text,
			.red-text {
				margin: 0 7px;
				@include breakpoint($tab) {
					display: inline-block;
				}
			}
		}
	}
}

// ------------ Blue Background ------------
.l-blue-background {
	background: $blue;
	color: #fff;
	padding: 15px 20px;
	.block {
		text-align: center;
		.block__title {
			font-family: $second_font;
			font-size: 27px;
			line-height: 41px;
			@include breakpoint($tab) {
				font-size: 43px;
				line-height: 43px;
			}
		}
		.block__content {
			p {
				margin: 10px 0;
				line-height: 21px;
				font-size: 15px;
				@include breakpoint($tab) {
					font-size: 13px;
					line-height: 19px;
				}
			}
			a {
				display: inline-block;
				border: 1px solid #fff;
				font-size: 16px;
				line-height: 16px;
				color: #fff;
				padding: 10px 40px;
				margin-top: 25px;
				@include breakpoint($tab) {
					margin-top: 15px;
					font-size: 15px;
					line-height: 16px;
				}
				&:hover {
					font-style: normal;
					color: $blue;
					background: $background;
					border: 1px solid $background;
				}
			}
		}
	}
}

// ------------ Light Grey Background --------
.l-light-grey-background {
	// background: $background_light;
	padding: 25px 0;
	@include breakpoint($tab) {
		padding: 40px 0 25px;
	}
}

// ------------ Newsletter --------
.l-newsletter {
	background: $grey;
	color: #fff;
	padding: 40px 0;
	.l-newsletter-wrapper {
		@extend .container;
		@include pie-clearfix;
		.block {
			@include breakpoint($tab) {
				width: 50%;
				float: left;
				display: inline-block;
			}
			.block__title {
				font-family: $second_font;
				font-size: 27px;
				line-height: 35px;
				margin-bottom: 13px;
			}
		}
		.subscribe-form {
			margin-top: 35px;
			text-align: center;
			@include breakpoint($tab) {
				margin-top: 10px;
				@include breakpoint($desk) {
					margin-top: 35px;
				}
			}
			.form-item {
				display: inline-block;
				margin: 0;
				margin-right: 15px;
			}
			.form-actions {
				display: table;
				margin: 25px auto 0;
				@include breakpoint($tab) {
					display: inline-block;
					margin: 20px 0 0;
					margin-right: 15px;
					@include breakpoint(1144px) {
						margin: 0;
					}
				}
			}
			input[type="submit"] {
				color: #fff;
				background: transparent;
				border: 1px solid #fff;
				&:hover {
					border: 1px solid $background;
					color: $background;
				}
			}
		}
	}
}

// ------------ Pre Footer --------
.l-prefooter {
	margin-top: 40px;
	.footer-top-text {
		border-top: 1px solid $border;
		border-bottom: 1px solid $border;
		padding: 30px 0;
		.block__content {
			margin: 0 auto;
			max-width: 800px;
			padding: 0 20px;
			text-align: center;
			p {
				color: #959595;
				font-size: 12px;
			}
			a {
				@extend .thin-font;
				font-size: 12px;
				text-decoration: underline;
				color: $main;
			}
		}
	}
}

// ------------ Footer --------
.l-footer-wrapper {
	padding: 0 0 30px;
	border-bottom: 1px solid $border;
	clear: both;
	@include breakpoint($tab) {
		padding: 30px 0;
	}
	.l-region--footer {
		@extend .container;
		@include pie-clearfix;
		padding: 0;
		@include breakpoint($tab) {
			padding: 0 20px;
		}
		.footer-menu {
			@include pie-clearfix;
			margin: 0;
			width: 100%;
			display: inline-block;
			float: left;
			@include breakpoint($tab) {
				margin: 0;
				width: 68%;
				width: 100%;
			}
			ul.menu {
				padding: 0;
				margin: 0;
				list-style-type: none;
				list-style-image: none;
				list-style: none;
				padding-right: 10px;
				li {
					list-style-type: none;
					list-style-image: none;
					list-style: none;
					text-align: left;
					padding: 6px 0;
					a {
						line-height: 27px;
						font-size: 14px;
						color: $main;
						line-height: 18px;
					}
					span.nolink {
						color: $main;
						font-size: 19px;
					}
				}
				& > .expanded {
					display: inline-block;
					width: 100%;
					padding: 20px;
					border-bottom: 1px solid $border;
					@include breakpoint($tab) {
						float: left;
						width: 33.333%;
						padding: 0;
						border-bottom: 0;
					}
					& > .nolink {
						display: block;
						position: relative;
						@include breakpoint($tab) {
							margin-bottom: 15px;
						}
						&:before {
							@extend .font-icon;
							@extend .mc-icon-arrow-down;
							font-size: 20px;
							top: 0;
							right: 0;
							position: absolute;
							line-height: 19px;
							display: block;
							@include breakpoint($tab) {
								display: none;
							}
						}
					}
				}
				li ul.menu {
					display: none;
					@include breakpoint($tab) {
						display: block;
					}
				}
				li.footer-open {
					ul.menu {
						display: block;
					}
					& > .nolink {
						margin-bottom: 15px;
					}
				}
			}
		}
		.newsletter-block {
			display: inline-block;
			width: 100%;
			padding: 30px 20px 20px;
			@include breakpoint($tab) {
				width: 32%;
				padding: 0;
			}
			h2 {
				margin-top: 0;
				font-size: 12px;
				font-weight: 400;
				color: $main;
				line-height: 12px;
				margin: 0 0 10px;
				@include breakpoint($tab) {
					margin: 20px 0;
				}
			}
			.webform-client-form-23 {
				@include pie-clearfix;
				.form-item {
					margin-bottom: 0;
					display: inline-block;
					width: 70%;
					float: left;
					input[type="email"] {
						width: 100%;
						height: 40px;
						padding: 10px 15px;
						border: 0;
						border-radius: 0;
						color: #414141;
						font-size: 12px;
						line-height: 20px;
						-webkit-appearance: none;
						border: 1px solid $border;
						border-right: 0;
						max-width: 100%;
						@include breakpoint($tab) {
							max-width: 345px;
						}
					}
				}
				.form-actions {
					margin-bottom: 0;
					display: inline-block;
					float: right;
					border: 0;
					width: 30%;
					input[type="submit"] {
						background: #ff6501;
						color: #fff;
						padding: 7px;
						font-size: 15px;
						line-height: 24px;
						cursor: pointer;
						border-radius: 0;
						border: 0;
						width: 100%;
						border: 1px solid #ff6501;
					}
				}
			}
		}
		.our-catalogue {
			display: inline-block;
			padding: 25px 20px 0;
			width: 100%;
			@include breakpoint($tab) {
				width: auto;
				margin: 30px auto 0;
				padding: 0;
			}
			.block__title {
				color: $main;
				font-size: 19px;
				position: relative;
				margin: 0px auto 10px;
				padding-left: 60px;
				line-height: 50px;
				&:before {
					@extend .font-icon;
					@extend .mc-icon-book;
					color: $main;
					font-size: 50px;
					position: absolute;
					left: 0;
				}
			}
			ul.menu {
				li {
					display: block;
					a {
						line-height: 27px;
						font-size: 14px;
						color: $main;
					}
				}
			}
		}
	}
}

// ------------ Bottom Footer --------
.l-bottom-footer-wrapper {
	@extend .container;
	@include pie-clearfix;
	padding: 20px;
	.copyright {
		display: inline-block;
		float: left;
		width: 100%;
		margin-bottom: 15px;
		@include breakpoint($tab) {
			width: 60%;
			padding-right: 20px;
			margin-bottom: 0;
		}
		.block__content {
			max-width: 360px;
			margin: auto;
			text-align: center;
			@include breakpoint($tab) {
				text-align: left;
				max-width: 100%;
			}
		}
		p {
			color: #818181;
			font-size: 10px;
			line-height: 15px;
			@include breakpoint($tab) {
				line-height: 18px;
			}
		}
	}
	.payment-icons {
		display: inline-block;
		float: right;
		width: 100%;
		@include breakpoint($tab) {
			width: 40%;
		}
		.block__content {
			max-width: 360px;
			margin: auto;
			@include breakpoint($tab) {
				max-width: 100%;
			}
		}
	}
}

// ----------- Social -----------
.solial-links {
	@include pie-clearfix;
	padding: 0;
	text-align: center;
	float: left;
	display: inline-block;
	width: 100%;
	@include breakpoint($tab) {
		padding: 15px 0 0;
		width: 68%;
	}
	ul.social_networking {
		list-style: none;
		max-width: 235px;
		margin: 0 auto;
		@include breakpoint($tab) {
			margin: 0;
			@include breakpoint($desk) {
				max-width: 100%;
			}
		}
		li.social_networking-item {
			float: left;
			margin: 6px 10px;
			@include breakpoint($desk) {
				margin: 6px 15px 9px 0;
			}
			&:last-child {
				margin: 6px 10px;
				@include breakpoint($desk) {
					margin: 6px 0 9px 0;
				}
			}
			.social_networking-link {
				display: block;
				position: relative;
				width: 44px;
				height: 44px;
				transition: all .3s ease;
				border: 1px solid #d6d6d6;
				border-radius: 50%;
				color: #003971;
				font-size: 0;
				text-indent: -9999px;
				overflow: hidden;
				background-color: #16467b;
				color: #fff;
				width: 38px;
				height: 38px;
				&.elc {
					background-color: #049367;
				}
			}
			.social_networking-link:hover {
				background-color: #fff;
				border: 1px solid #16467b;
				color: #16467b;
			}
			.social_networking-link.elc:hover {
				background-color: #fff;
				border: 1px solid #049367;
				color: #049367;
			}
			.social_networking-link:before {
				font-size: 20px;
				text-indent: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				font-size: 17px;
			}
			.social_networking-link.i-social_fb {
				@extend .font-icon;
				@extend .mc-icon-facebook;
			}
			.social_networking-link.i-social_fb_elc {
				@extend .font-icon;
				@extend .mc-icon-facebook;
				color: green;
			}
			.social_networking-link.i-social_twitter {
				@extend .font-icon;
				@extend .mc-icon-twitter;
			}
			.social_networking-link.i-social_pinterest {
				@extend .font-icon;
				@extend .mc-icon-pinterest;
			}
			.social_networking-link.i-social_youtube {
				@extend .font-icon;
				@extend .mc-icon-youtube;
			}
			.social_networking-link.i-social_instagram {
				@extend .font-icon;
				@extend .mc-icon-instagram;
			}
			.social_networking-link.i-social_instagram_elc {
				@extend .font-icon;
				@extend .mc-icon-instagram;
				color: green;
			}
			.social_networking-link.i-social_google {
				@extend .font-icon;
				@extend .mc-icon-google;
			}
			.social_networking-link.i-social_linkedin {
				@extend .font-icon;
				@extend .mc-icon-linkedin;
			}
			.social_networking-link.i-social_chat {
				@extend .font-icon;
				@extend .mc-icon-chat;
			}
		}
	}
}

// ----------- Faooter App -----------
.footer-app {
	padding-top: 15px;
	clear: both;
	width: 100%;
	margin: 0 auto;
	max-width: 360px;
	padding: 20px;
	@include breakpoint($tab) {
		width: 32%;
		float: right;
		display: inline-block;
		max-width: 100%;
		padding: 0;
	}
	.apple {
		display: inline-block;
		float: left;
		width: 50%;
		padding-right: 5px;
		@include breakpoint($tab) {
			padding-right: 15px;
		}
	}
	.play-google {
		display: inline-block;
		float: left;
		width: 50%;
		padding-left: 5px;
		@include breakpoint($tab) {
			padding-left: 15px;
		}
	}
}

// --------------- Product Teasers ----------------
.product-teasers {
	margin-bottom: 35px;
	@extend .container;
	.block__title {
		font-size: 23px;
		line-height: 29px;
		margin: 15px 0 40px;
		text-align: center;
		@include breakpoint($tab) {
			line-height: 34px;
			font-size: 34px;
			margin: 55px 0 60px;
		}
	}
	.views-row {
		margin-bottom: 30px;
		@include breakpoint($tab) {
			margin-bottom: 0;
		}
	}
}

// --------------- Contact Page ----------------
.page-node-3526 {
	.l-content {
		padding: 0; // max-width: 100%;
	}
	.node--page--full {
		margin: 0;
		background: #fff;
		padding: 0;
		@include breakpoint($tab) {
			padding: 60px 20px;
		}
		h1 {
			margin: 0
		}
	}
}

// --------------- Our Concept Page ----------------
.node--article--full,
.node--faq--full {
	margin: 0;
	background: #fff;
	padding: 0;
	width: 100%;
	display: inline-block;
	@include breakpoint($tab) {
		width: 75%;
		padding-left: 40px;
	}
	h1 {
		margin: 0 0 1em;
		padding: 0 2em;
		font-size: 28px;
		font-weight: 400;
		line-height: 1.1;
		text-align: center;
		color: $main;
	}
	.faqfield-question {
		font-size: 1.4em;
		color: $main;
		margin-bottom: 0.7em;
		font-weight: bold;
	}
	.front & {
		display: none;
	}
	.body-wrapper {
		p {
			line-height: 22px;
			font-size: 16px;
		}
	}
	.faqfield-answer {
		@include pie-clearfix;
		position: relative;
		p {
			line-height: 19px;
			color: #414141;
			font-size: 14px;
		}
	}
	img {
		width: auto;
		height: auto;
	}
}

.node--article--full {
	h1,
	h2,
	h3,
	h4,
	h5 {
		@extend .regular-font;
		font-family: $default_font !important;
	}
	#buying-guide {
		.block_title {
			font-size: 40px;
		}
		h1,
		h2 {
			@extend .regular-font;
			font-family: "marydale", sans-serif !important;
			font-weight: 400;
		}
		.title {
			margin-bottom: 20px;
			h2 {
				text-align: center;
				@include breakpoint($tab) {
					text-align: left;
				}
			}
		}
		.title_big {
			font-size: 60px;
		}
		ul {
			padding-left: 20px;
			list-style: initial;
			margin: 10px 0;
			li {
				padding-left: 0;
			}
		}
		#size-guide table tbody td {
			padding: 3px;
			@include breakpoint($tab) {
				padding: 15px 10px;
			}
		}
	}
	p,
	ul {
		@extend .thin-font;
		// font-family: 'futura-plus-thin', sans-serif !important;
		// font-family: $default_font;
	}
}

.node--article--full,
.node--faq--full {
	.field-name-body,
	.body-wrapper {
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			li {
				margin-bottom: 3px;
				padding-left: 18px;
				position: relative;
			}
		}
		ol {
			counter-reset: li;
			list-style: none;
			li {
				margin-bottom: 3px;
				padding-left: 18px;
				position: relative;
			}
		}
	}
	.field-name-field-custom-body,
	.custom-body-wrapper {
		h4 {
			@extend .marydale-font;
			font-family: "marydale", sans-serif !important;
			font-size: 24px;
		}
		ul,
		ol {
			@extend .thin-font;
			line-height: 18px;
			font-size: 14px;
		}
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			padding-left: 20px;
			li {
				margin-bottom: 3px;
				padding-left: 18px;
				position: relative;
				&:before {
					content: "";
					width: 4px;
					height: 4px;
					border-radius: 50%;
					position: absolute;
					background: #000;
					top: 50%;
					left: 0;
					transform: translatey(-50%);
				}
			}
		}
		ol {
			counter-reset: li;
			list-style: none;
			li {
				margin-bottom: 3px;
				padding-left: 18px;
				position: relative;
				counter-increment: li;
				&:before {
					content: counter(li);
					color: #000;
					width: 8px;
					height: 13px;
					border-radius: 50%;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translatey(-50%);
				}
			}
		}
	}
}

.node--page--full {
	.field--name-body {
		.scroll-to li {
			color: #0085c9;
			cursor: pointer;
		}
		h4 {
			@extend .marydale-font;
			font-family: "marydale", sans-serif !important;
			font-size: 24px;
		}
		ul,
		ol {
			@extend .thin-font;
			line-height: 18px;
			font-size: 14px;
		}
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			padding-left: 20px;
			li {
				margin-bottom: 3px;
				padding-left: 18px;
				position: relative;
				&:before {
					content: "";
					width: 4px;
					height: 4px;
					border-radius: 50%;
					position: absolute;
					background: #000;
					top: 50%;
					left: 0;
					transform: translatey(-50%);
				}
			}
		}
		ol {
			counter-reset: li;
			list-style: none;
			li {
				margin-bottom: 3px;
				padding-left: 18px;
				position: relative;
				counter-increment: li;
				&:before {
					content: counter(li);
					color: #000;
					width: 8px;
					height: 13px;
					border-radius: 50%;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translatey(-50%);
				}
			}
		}
	}
}

.view-advice {
	margin: 0;
	background: #fff;
	padding: 0;
	width: 100%;
	display: inline-block;
	@include breakpoint($tab) {
		width: 75%;
		padding-left: 40px;
	}
}

.front .node--page--full {
	display: none;
}

.term-block {
	margin: 0;
	background: #fff;
	padding: 0;
	width: 75%;
	display: inline-block;
	padding-left: 40px;
	.views-row {
		line-height: 1;
		margin: 0;
		padding: 5px 0px;
		padding-left: 1em;
		a {
			color: #4F4F4F;
		}
	}
}

// --------------- Breadcrumb ----------------
.l-breadcrumb {
	line-height: 30px;
	padding: 0;
	font-family: "futura-plus";
	margin: 0;
	a {
		color: #414141;
		font-size: 12px;
	}
	li {
		display: inline-block;
		position: relative;
		line-height: 17px;
		margin-right: 0;
		padding-right: 0;
		&:after {
			content: '/';
			position: absolute;
			top: 0;
			right: 0;
			position: relative;
			padding: 0 5px;
			font-size: 10px;
		}
		&:first-child:before {
			@extend .font-icon;
			@extend .mc-icon-house;
			margin-right: 5px;
			top: 3px;
			position: relative;
		}
		&:last-child:after {
			display: none;
		}
		&:last-child {
			font-weight: 400;
			color: $main;
		}
	}
}

.mfp-bg {
	background-color: rgba(0, 0, 0, .5);
}

.product-quick-view-popup {
	max-width: 920px;
	padding: 21px 30px;
	border: 1px solid #d6d6d6;
	.mfp-close {
		background: none;
		top: 16px;
		right: 23px;
		line-height: 20px;
		opacity: 1;
		padding: 0;
		&:before {
			@extend .font-icon;
			@extend .mc-icon-close;
			color: $main;
			font-size: 15px;
		}
	}
	.mfp-close:hover {
		&:before {
			color: #0085ca;
		}
	}
	&.size-guide-open {
		.mfp-close {
			display: none;
		}
		.product-top-session {
			display: none;
		}
	}
}

#block-lang-dropdown-language,
.block--lang-dropdown-language-content {
	@include breakpoint($desk) {
		position: absolute;
		right: 20px;
		top: -3px;
	}
	.selector {
		background: #fff;
		font-size: 11px;
		padding: 3px 32px 3px 17px;
		width: 100%;
		resize: none;
		text-align: left;
		position: relative;
		cursor: pointer;
		max-width: 100%;
		border: 1px solid #999;
		line-height: 15px;
		&:before {
			font-size: 12px;
			top: 6px;
		}
		select {
			width: 100% !important;
		}
	}
}

// Store Locator
.address-search-input.form-wrapper {
	text-align: center;
	margin-bottom: 40px;
}

#stores-map {
	height: 500px;
	width: 100%;
	@include breakpoint($tab) {
		float: right;
		width: 65%;
		height: 700px;
	}
}

.stores-list-wrapper {
	// clear: both;
	width: 100%;
	@include breakpoint($tab) {
		width: 35%;
		float: left;
		padding-right: 80px;
	}
	h3 {
		margin-top: 0;
	}
	.stores-list {
		max-height: 400px;
		overflow-y: scroll;
		padding: 0;
		@include breakpoint($tab) {
			max-height: 650px;
		}
		li {
			list-style: none;
			list-style-image: none;
			margin-bottom: 20px;
			.store-title {
				@include pie-clearfix;
				margin: 0 0 5px;
				h3 {
					float: left;
					margin: 0;
					margin-right: 9px;
					display: block;
				}
				img {
					width: 35px;
					height: auto;
					float: left;
					display: inline-block;
					margin-top: 1px;
				}
			}
			.address {
				line-height: 20px;
				margin-bottom: 5px;
				@extend .thin-font;
			}
			.phone a {
				color: initial;
				@extend .thin-font;
			}
		}
	}
}

.results-area-wrapper {
	.stores-area > .form-item > label {
		margin-bottom: 15px;
	}
	@include breakpoint($tab) {
		width: 65%;
		float: right;
	}
}

.mc-error-page {
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	max-width: 1220px;
	margin: 0 auto;
	@include breakpoint($tab) {
		height: 600px;
	}
	&.mc-page-404 {
		@include breakpoint($tab) {
			background-image: url('../images/404.jpg');
		}
	}
	&.mc-page-403 {
		@include breakpoint($tab) {
			background-image: url('../images/403.jpg');
		}
	}
	&.mc-page-maintenance {
		@include breakpoint($tab) {
			background-image: url('../images/500.jpg');
		}
	}
	.mc-inner-content {
		right: 0;
		top: 0;
		bottom: 0;
		text-align: center;
		display: table;
		vertical-align: middle;
		@include breakpoint($tab) {
			position: absolute;
			width: 55%;
			height: 100%;
		}
		.mc-inner-text {
			display: inline-block;
			display: table-cell;
			vertical-align: middle;
		}
		h2 {
			font-size: 45px;
			line-height: 45px;
			color: rgb(37, 64, 143);
		}
		p {
			font-size: 20px;
			line-height: 22px;
			color: rgb(37, 64, 143);
			margin-bottom: 15px;
		}
	}
}

.view-recommended-products {
	font-size: 0;
}

.section-advice table {
	overflow-x: scroll;
	display: block;
	width: auto !important;
}

.section-advice .node--article--full {
	overflow-x: hidden;
	max-width: 100%;
}

.l-region--mobile-language-switcher {
	padding: 0 20px;
}

// Recaptcha

.g-recaptcha {
	& > div {
		width: 100% !important;

		@include breakpoint($tab) {
			width: 304px!important;
		}

		iframe {
			width: 100%;

			.rc-anchor-normal {
		    display: flex;
		    align-items: center;
				width: auto;

				@include breakpoint($tab) {
					width: 300px;
				}

		    .rc-anchor-content {
			    height: auto;
			    width: 100%;
			    display: flex;
			    align-items: center;
				}

				.rc-anchor-normal-footer {
	        display: inline-block;
			    height: auto;
			    vertical-align: top;
			    width: auto;
			    margin: 5px;
				}

				.rc-anchor-pt {
			    margin: 2px 1px 0 0;
			    padding-right: 2px;
			    position: relative;
			    right: 0;
			    text-align: center;
			    width: auto;
				}
			}
		}
	}
}

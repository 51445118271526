.wishilist-block {
	margin: 0 15px;
	display: none;

	@include breakpoint($tab) {
		display: inline-block;

		@include breakpoint($desk) {
		}
	}

	.wishlist-link {
		display: inline-block;
		margin-top: 2px;
		color: $black;

		.heart-icon {
			font-size: 17px;
			@extend .font-icon;
			@extend .mc-icon-heart;
		}

		&:hover {
			.heart-icon {
				@extend .mc-icon-heart;
			}
		}
	}
}


.page-wishlist {
	h1 {
		margin: 30px auto;
	}

	.view-wishlist {
		margin-bottom: 70px;
	}
}
